/* eslint-disable no-case-declarations */
import {
  DELETE_MEDIA_AMAZONS3,
  DELETE_MEDIA_LOCAL,
  SET_MEDIA_UPLOAD_PROGRESS,
  UPALOD_MEDIA_LIST,
  SET_MEDIA_UPLOAD_EMPTY,
  DOWNLOAD_MEDIA_LIST,
  SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS,
  COMPLETE_DOWNLOAD_MEDIA,
} from "../constants/action-types";
import { MediaDatas } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  // progress: 0,
  isLoading: false,
  uploadLoad: false,
  ticketAttachment: [],
  // announcementCommentAttachment: [],
  ticketCommentAttachment: [],
  progressPercentages: {},
  downloadingFiles: [],
  show: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: MediaDatas = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_MEDIA_UPLOAD_EMPTY:
        const keyEmpty: any = action.payload;
        return {
          ...state,
          [keyEmpty]: [],
        };

      case SET_MEDIA_UPLOAD_PROGRESS:
        return {
          ...state,
          progress: action.payload,
        };

      case UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_INITIALIZATION:
        return {
          ...state,
          uploadLoad: true,
        };

      case UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_SUCCESS:
        const media: any = action.payload;
        if (media.isMulti) {
          const { newlyUploaded }: any = media;
          const stateKey: string = media.stateKey;
          const stateData: any = state;
          const arrMedia = [...newlyUploaded, ...stateData[stateKey]];
          return {
            ...state,
            [stateKey]: arrMedia,
            uploadLoad: false,
          };
        } else {
          const { newlyUploaded }: any = media;
          const stateKey: string = media.stateKey;
          return {
            ...state,
            [stateKey]: newlyUploaded,
            uploadLoad: false,
          };
        }

      case UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_ERROR:
        return {
          ...state,
          uploadLoad: false,
        };

      case DELETE_MEDIA_LOCAL.DELETE_MEDIA_LOCAL_INITIALIZATION:
        return {
          ...state,
          uploadLoad: true,
        };

      case DELETE_MEDIA_LOCAL.DELETE_MEDIA_LOCAL_SUCCESS:
        const mediaDelete: any = action.payload;

        const { key, stateKeys }: any = mediaDelete;
        const sta: any = state;

        const mediaState = sta[stateKeys];

        const medias = mediaState.filter((i: any) => i.key !== key);

        return {
          ...state,
          [stateKeys]: medias,
          uploadLoad: false,
        };

      case DELETE_MEDIA_LOCAL.DELETE_MEDIA_LOCAL_ERROR:
        return {
          ...state,
          uploadLoad: false,
        };

      case DELETE_MEDIA_AMAZONS3.DELETE_MEDIA_AMAZONS3_INITIALIZATION:
        return {
          ...state,
          uploadLoad: true,
        };

      case DELETE_MEDIA_AMAZONS3.DELETE_MEDIA_AMAZONS3_SUCCESS:
        return {
          ...state,
          uploadLoad: false,
        };

      case DELETE_MEDIA_AMAZONS3.DELETE_MEDIA_AMAZONS3_ERROR:
        return {
          ...state,
          uploadLoad: false,
        };
      case DOWNLOAD_MEDIA_LIST.DOWNLOAD_MEDIA_LIST_SUCCESS:
        console.log("newFiles", action.payload);
        let files: Array<Object> = state.downloadingFiles;
        const newFiles: any = action.payload;
        files = [...files, newFiles];
        const p: any = state.progressPercentages;
        p[newFiles?.id] = 0;
        return {
          ...state,
          downloadingFiles: files,
          progressPercentages: p,
        };

      case SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS:
        const pro: any = action.payload;
        const progress: any = state.progressPercentages;
        progress[pro.id] = pro.progress;
        console.log("progress inreduucer", progress);
        return {
          ...state,
          progressPercentages: { ...progress },
        };
      case COMPLETE_DOWNLOAD_MEDIA:
        const file: Array<Object> = state.downloadingFiles;
        const prog: any = state.progressPercentages;
        const id: any = action.payload;
        const f = file.filter((i: any) => i.id != id);
        delete prog[id];

        console.log("newFiles", f);
        console.log("prog", prog);

        return {
          ...state,
          progressPercentages: prog,
          downloadingFiles: f,
        };

      case "SET_TOAST_SHOW":
        const show = action.payload;
        return {
          ...state,
          show: show,
        };
      default:
        return state;
    }
  },
);

export default reducer;
