/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_CLASSES,
  REGISTER_STUDENT_IN_CLASS,
  GET_CLASSES_DETAIL,
  GET_ALL_CLASSES,
  GET_USER_REGISTERD_CLASS,
  UPLOAD_CLASS_SLIP,
  UPLOAD_SIGNATURE_IN_CLASS,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_CLASS_CATEGORY_URL,
  EDIT_CLASS_URL,
  GET_USER_REGISTERD_CLASS_URL,
  UPLOAD_CLASS_SLIP_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get classes
export const getMyClasses =
  (
    showToast = true,
    pageNumber = 0,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getMyClassesInit(showToast, pageNumber, sort, filter, pageLimit));
  };

export const getMyClassesInit =
  (
    showToast = true,
    pageNumber: any,
    sort: any,
    filter: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES.GET_CLASSES_INITIALIZATION,
    });

    let url = `students/myclass`;
    if (pageNumber > 0) {
      url = `students/myclass?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`;
    }

    apiCall(
      url,
      (res: Object) => dispatch(getMyClassesSuccess(res)),
      (err: any) => dispatch(getMyClassesError()),
      {},
      METHOD.GET,
      { showToast: false },
    );
  };

export const getMyClassesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES.GET_CLASSES_SUCCESS,
      payload: res,
    });
  };

export const getMyClassesError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASSES.GET_CLASSES_ERROR,
    });
  };

export const getAllClasses =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAllClassesInit(showToast));
  };

export const getAllClassesInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_CLASSES.GET_ALL_CLASSES_INITIALIZATION,
    });
    apiCall(
      `students/class/list?sort=DESC&sortBy=createdAt`,
      (res: Object) => dispatch(getAllClassesSuccess(res)),
      (err: any) => dispatch(getAllClassesError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getAllClassesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_CLASSES.GET_ALL_CLASSES_SUCCESS,
      payload: res.data,
    });
  };

export const getAllClassesError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALL_CLASSES.GET_ALL_CLASSES_ERROR,
    });
  };
//Get classes
export const registerStudentInClass =
  (
    classVal: string,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(registerStudentInClassInit(classVal, showToast));
  };

export const registerStudentInClassInit =
  (
    classVal: string,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER_STUDENT_IN_CLASS.REGISTER_STUDENT_IN_CLASS_INITIALIZATION,
    });
    apiCall(
      `students/class/register`,
      (res: Object) => dispatch(registerStudentInClassSuccess(res)),
      (err: any) => dispatch(registerStudentInClassError()),
      classVal,
      METHOD.POST,
      { showToast },
    );
  };

export const registerStudentInClassSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER_STUDENT_IN_CLASS.REGISTER_STUDENT_IN_CLASS_SUCCESS,
      payload: res,
    });
    dispatch(getAllClasses());
  };

export const registerStudentInClassError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REGISTER_STUDENT_IN_CLASS.REGISTER_STUDENT_IN_CLASS_ERROR,
    });
  };

//Get classes
export const getClassDetail =
  (classId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getClassDetailInit(classId));
  };

export const getClassDetailInit =
  (classId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_INITIALIZATION,
    });
    apiCall(
      `class/${classId}`,
      (res: Object) => dispatch(getClassDetailSuccess(res)),
      (err: any) => dispatch(getClassDetailError()),
      {},
      METHOD.GET,
      {},
    );
  };

export const getClassDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const getClassDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_ERROR,
    });
  };

//Get classes
export const getUserRegisteredClass =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserRegisteredClassInit(showToast));
  };

export const getUserRegisteredClassInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_REGISTERD_CLASS.GET_USER_REGISTERD_CLASS_INITIALIZATION,
    });
    apiCall(
      GET_USER_REGISTERD_CLASS_URL,
      (res: Object) => dispatch(getUserRegisteredClassSuccess(res)),
      (err: any) => dispatch(getUserRegisteredClassError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUserRegisteredClassSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_REGISTERD_CLASS.GET_USER_REGISTERD_CLASS_SUCCESS,
      payload: res.data,
    });
  };

export const getUserRegisteredClassError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_USER_REGISTERD_CLASS.GET_USER_REGISTERD_CLASS_ERROR,
    });
  };

// Edit classes
export const uploadClassSlip =
  (
    slipPath: any,
    studentId: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(uploadClassSlipInit(slipPath, studentId, showToast));
  };

export const uploadClassSlipInit =
  (
    slipPath: any,
    studentId: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPLOAD_CLASS_SLIP.UPLOAD_CLASS_SLIP_INITIALIZATION,
    });
    apiCall(
      UPLOAD_CLASS_SLIP_URL + studentId,
      (res: Object) => dispatch(uploadClassSlipSuccess(res)),
      (err: any) => dispatch(uploadClassSlipError()),
      { slipPath: slipPath },
      METHOD.PUT,
      { showToast },
    );
  };

export const uploadClassSlipSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPLOAD_CLASS_SLIP.UPLOAD_CLASS_SLIP_SUCCESS,
      payload: res,
    });
  };

export const uploadClassSlipError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPLOAD_CLASS_SLIP.UPLOAD_CLASS_SLIP_ERROR,
    });
  };

//upload Signature
export const uploadSignatureInClass =
  (
    studentId: number,
    payload: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(uploadSignatureInClassInit(studentId, payload, showToast));
  };

export const uploadSignatureInClassInit =
  (
    studentId: number,
    payload: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPLOAD_SIGNATURE_IN_CLASS.UPLOAD_SIGNATURE_IN_CLASS_INITIALIZATION,
    });
    apiCall(
      `students/signature/${studentId}`,
      (res: Object) => dispatch(uploadSignatureInClassSuccess(res)),
      (err: any) => dispatch(uploadSignatureInClassError()),
      payload,
      METHOD.PUT,
      { showToast },
    );
  };

export const uploadSignatureInClassSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPLOAD_SIGNATURE_IN_CLASS.UPLOAD_SIGNATURE_IN_CLASS_SUCCESS,
      payload: res,
    });
    dispatch(getMyClasses(true, 0, { sort: "DESC", sortBy: "createdAt" }, ""));
  };

export const uploadSignatureInClassError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPLOAD_SIGNATURE_IN_CLASS.UPLOAD_SIGNATURE_IN_CLASS_ERROR,
    });
  };
