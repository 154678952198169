/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import { GET_ATTENDENCE_LIST } from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { GET_ATTENDENCE_LIST_URL } from "../../service/apiEndPoints";

export const getAttendenceList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAttendenceListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getAttendenceListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ATTENDENCE_LIST.GET_ATTENDENCE_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ATTENDENCE_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getAttendenceListSuccess(res)),
      (err: any) => dispatch(getAttendenceListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getAttendenceListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ATTENDENCE_LIST.GET_ATTENDENCE_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAttendenceListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ATTENDENCE_LIST.GET_ATTENDENCE_LIST_ERROR,
    });
  };
