/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_VPS_MACHINE_LIST,
  GET_VPS_MACHINE_TYPE_LIST,
  CREATE_VPS_MACHINE,
  VPS_MACHINE_CONTACT_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_VPS_MACHINE_LIST_URL,
  GET_VPS_MACHINE_TYPE_LIST_URL,
  CREATE_VPS_MACHINE_URL,
  VPS_MACHINE_CONTACT_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";
import { fabClasses } from "@mui/material";

export const getVPSMachineList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getVPSMachineListInit(showToast));
  };

export const getVPSMachineListInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_LIST.GET_VPS_MACHINE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_VPS_MACHINE_LIST_URL,
      (res: Object) => dispatch(getVPSMachineListSuccess(res)),
      (err: any) => dispatch(getVPSMachineListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getVPSMachineListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_LIST.GET_VPS_MACHINE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getVPSMachineListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_LIST.GET_VPS_MACHINE_LIST_ERROR,
    });
  };

export const getVPSMachineTypeList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getVPSMachineTypeListInit(showToast));
  };

export const getVPSMachineTypeListInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_VPS_MACHINE_TYPE_LIST_URL,
      (res: Object) => dispatch(getVPSMachineTypeListSuccess(res)),
      (err: any) => dispatch(getVPSMachineTypeListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getVPSMachineTypeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getVPSMachineTypeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_ERROR,
    });
  };

export const createVPSMachine =
  (
    showToast = true,
    vpsType: string,
    vpsMonths: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(createVPSMachineInit(showToast, vpsType, vpsMonths));
  };

export const createVPSMachineInit =
  (
    showToast = true,
    vpsType: string,
    vpsMonths: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CREATE_VPS_MACHINE.CREATE_VPS_MACHINE_INITIALIZATION,
    });
    apiCall(
      CREATE_VPS_MACHINE_URL,
      (res: Object) => dispatch(createVPSMachineSuccess(res)),
      (err: any) => dispatch(createVPSMachineError()),
      {
        vpsTypeId: vpsType,
        month: vpsMonths,
      },
      METHOD.POST,
      { showToast },
    );
  };

export const createVPSMachineSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CREATE_VPS_MACHINE.CREATE_VPS_MACHINE_SUCCESS,
      payload: res.data,
    });
  };

export const createVPSMachineError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: CREATE_VPS_MACHINE.CREATE_VPS_MACHINE_ERROR,
    });
  };

export const VPSMachineContactList =
  (
    showToast = true,
    message: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(VPSMachineContactListInit(showToast, message));
  };

export const VPSMachineContactListInit =
  (
    showToast = true,
    message: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS_MACHINE_CONTACT_LIST.VPS_MACHINE_CONTACT_LIST_INITIALIZATION,
    });
    apiCall(
      VPS_MACHINE_CONTACT_LIST_URL,
      (res: Object) => dispatch(VPSMachineContactListSuccess(res)),
      (err: any) => dispatch(VPSMachineContactListError()),
      message,
      METHOD.POST,
      { showToast },
    );
  };

export const VPSMachineContactListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS_MACHINE_CONTACT_LIST.VPS_MACHINE_CONTACT_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const VPSMachineContactListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: VPS_MACHINE_CONTACT_LIST.VPS_MACHINE_CONTACT_LIST_ERROR,
    });
  };
