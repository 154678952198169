import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

interface ManagementRowUpdateDetailsModalProps {
  onClose: () => void;
  onConfirmClick: () => void;
  open: boolean;
  instanceId: string;
  name: string;
  summary: string;
  text: string;
}

const ManagementRowUpdateDetailsModal = (
  props: ManagementRowUpdateDetailsModalProps,
) => {
  const { onClose, onConfirmClick, open, instanceId, name, summary, text } =
    props;

  const updateDetailsForm = useFormik({
    initialValues: {
      instanceId,
      name,
      summary,
    },
    onSubmit: (values) => alert(JSON.stringify(values, null, 2)),
  });

  const { errors, touched, values, resetForm } = updateDetailsForm;

  return (
    <Modal centered size="lg" className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="vps.management.actions.updateDetails"
            defaultMessage="Update Name and Remarks"
          />
        </h4>
        <FaTimesCircle />
      </Modal.Header>
      <Modal.Body></Modal.Body>
    </Modal>
  );
};

export default ManagementRowUpdateDetailsModal;
