import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate as Redirect,
} from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routeConstants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Sidebar } from "../Components/common/Sidebar";
import { Header } from "../Components/common/Header";
import { useSelector } from "react-redux";
import { Store } from "../redux/Actions";
import React, { useEffect, useState } from "react";
import messages_zh from "../translations/zh.json";
import messages_cn from "../translations/cn.json";
import messages_en from "../translations/en.json";
import { IntlProvider } from "react-intl";
import { dispatch } from "../redux";
import { getQR } from "../redux/action";
import NotificationAlert from "../Components/Notification/NotificationAlert";
import IntlGlobalProvider from "../util/intl";
import { ToastDownload } from "../Components/common/ToastDownload";

export default function Routes() {
  const isLoggedout = useSelector(
    (state: Store) => state.userDataReducer.isLoggedout,
  );
  const language: string = useSelector(
    (state: Store) => state.userDataReducer.local,
  );
  const show: any = useSelector(
    (state: Store) => state.mediaUploadReducer.show,
  ) as any;
  const messages = {
    en: messages_en,
    zh: messages_zh,
    cn: messages_cn,
  };
  const [message, setMessage] = useState(messages["en"]);

  const setOpenNotification = () => {
    //temp set function
  };

  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );
  useEffect(() => {
    if (isLoggedin) {
      const localStorageAnnounce = localStorage.getItem("announcement");
      if (!localStorageAnnounce) {
        localStorage.setItem("announcement", "1");
      }
      dispatch(getQR());
    }
  }, [isLoggedin]);

  useEffect(() => {
    if (language === "en") {
      setMessage(messages["en"]);
    }

    if (language === "zh") {
      setMessage(messages["zh"]);
    }

    if (language === "cn") {
      setMessage(messages["cn"]);
    }
  }, [language]);

  return (
    <div>
      <IntlProvider messages={message} locale={language}>
        <IntlGlobalProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={false}
            pauseOnHover
          />
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              {publicRoutes.map(
                ({ path, element: Element, sideBar, navBar }, index) => (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <div id="app">
                        {sideBar && <Sidebar />}
                        <div className="content-wrapper">
                          {navBar && <Header />}
                          <Element />
                        </div>
                      </div>
                    }
                  />
                ),
              )}

              {isLoggedout ? (
                <Route path="/*" element={<Redirect to="/" />} />
              ) : (
                <>
                  {privateRoutes.map(
                    (
                      {
                        path,
                        protection: Protection,
                        element: Element,
                        sideBar,
                        navBar,
                      },
                      index,
                    ) => (
                      <Route
                        key={index}
                        path={path}
                        element={
                          <div id="app">
                            {sideBar && <Sidebar />}
                            {navBar && <Header />}
                            {Protection !== undefined ? (
                              <Protection>
                                <Element />
                              </Protection>
                            ) : (
                              <Element />
                            )}
                            {show && <ToastDownload />}
                            {
                              <NotificationAlert
                                setOpenNotification={setOpenNotification}
                              />
                            }
                          </div>
                        }
                      />
                    ),
                  )}
                </>
              )}
            </Switch>
          </Router>
        </IntlGlobalProvider>
      </IntlProvider>
    </div>
  );
}
