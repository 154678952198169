import Routes from "./routes";
import { Provider } from "react-redux";
import store from "./redux";
import "./App.scss";

function App() {
  return (
    <>
      <Provider store={store}>
        {/* <div className="app d-flex flex-column"> */}
        <div className="app">
          <Routes />
        </div>
      </Provider>
    </>
  );
}

export default App;
