import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Store } from "../../redux/Actions";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import GainedPoints from "./GainedPoints";
import RedemptionPoints from "./RedemptionPoints";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getQR, redeemAccount } from "../../redux/action";
import { FormattedMessage } from "react-intl";
import DeletePopup from "../../Components/common/Popups/DeletePopup";

const Point = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [show, setShow] = useState({ type: "", modal: false, value: "" });
  const classes = useStyles();
  const userInfo = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.pointReducer.isLoading,
  ) as any;
  const [page, setPage] = useState(1);
  const [buttonClick, setButtonClick] = React.useState(false);

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      localStorage.setItem("point_ActiveTab", "5");
    }
    if (newValue === 1) {
      localStorage.setItem("point_ActiveTab", "2");
    }
    setPage(1);
  };

  useEffect(() => {
    dispatch(getQR());
  }, []);

  useEffect(() => {
    if (buttonClick && !isLoading) {
      setShow((prv: any) => ({ ...prv, modal: false, type: "", value: "" }));
      setButtonClick(false);
    }
  }, [isLoading, buttonClick]);

  const redeem = (type: string) => {
    setButtonClick(true);
    dispatch(redeemAccount(true, type, page));
    setAciveTab(1, 1);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header d-flex">
          <div className="ml-auto">
            <Button
              variant="success"
              onClick={() => setShow({ modal: true, type: "L", value: "live" })}
              size="lg"
              className="mr-2 mb-2 mb-sm-0"
              style={{ width: "220px" }}
            >
              {" "}
              <FormattedMessage
                id="ponts.Redeem.live"
                defaultMessage="Redeem Live Account"
              />{" "}
            </Button>
            <Button
              onClick={() => setShow({ modal: true, type: "D", value: "demo" })}
              size="lg"
              className="mb-2 mb-sm-0"
              style={{ width: "220px" }}
            >
              {" "}
              <FormattedMessage
                id="ponts.Redeem.demo"
                defaultMessage="Redeem Demo Account "
              />
            </Button>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Row>
            <Col md={6} sm={6} lg={6}>
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <h2>
                    {userInfo.currentPoint}/{userInfo.totalPoints}
                  </h2>

                  <h4>
                    <FormattedMessage
                      id="menu.points"
                      defaultMessage="Current Points "
                    />
                  </h4>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col>
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <h2> {userInfo.totalPoints}</h2>
                  <h4>
                    {" "}
                    <FormattedMessage
                      id="ponts.Total.Points"
                      defaultMessage="Total Points"
                    />
                  </h4>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>

          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}
                style={{ padding: "0px" }}
                className={classes.tabs}
              >
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.GainedPoints"
                        defaultMessage="Gained Points"
                      />
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.RedemptionPoints"
                        defaultMessage="Redemption Points"
                      />
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={activeTab} index={0}>
                {/* <PendingList /> */}
                <GainedPoints page={page} setPage={(p: any) => setPage(p)} />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                {/* <RecordsList /> */}
                <RedemptionPoints
                  page={page}
                  setPage={(p: any) => setPage(p)}
                />
              </TabPanel>
            </Card.Body>
          </Card>
          {show.modal && (
            <DeletePopup
              message={
                <FormattedMessage
                  id={
                    show.type === "D"
                      ? "msg.reddemAccountD"
                      : "msg.reddemAccountL"
                  }
                  values={{ type: show.value }}
                  defaultMessage="Are you sure want to delete demo account ?"
                />
              }
              onClose={() =>
                setShow((prv: any) => ({
                  ...prv,
                  modal: false,
                  type: "",
                  value: "",
                }))
              }
              open={show.modal}
              onConfirm={() => redeem(show.type)}
              isLoading={isLoading}
              isSubText={true}
              subText={
                <FormattedMessage
                  id="msg.DeductPoint"
                  values={{ point: show.type === "D" ? "120" : "300" }}
                  defaultMessage="This will deduct 100 point"
                />
              }
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default Point;

const useStyles = makeStyles({
  tabs: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f57c51",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f57c51",
      BsBorderBottom: "1px solid",
    },
    "& .MuiTypography-root": {
      lineHeight: "1.5",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
