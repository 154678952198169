export const LOGIN_TYPE: any = {
  ADMIN: 0,
  USER: 1,
};

export const UserGroup: any = {
  STUDENT: "0",
  REFFRER: "1",
  COACHER: "2",
  EMPLOYEE: "3",
};

export const UserGroupOption: any = [
  {
    id: UserGroup.STUDENT,
    group: "Student",
  },
  {
    id: UserGroup.REFFRER,
    group: "Student",
  },
  {
    id: UserGroup.COACHER,
    group: "Student",
  },
  {
    id: UserGroup.EMPLOYEE,
    group: "Student",
  },
];

export const TOOLS = {
  EXPERT_ADVISOR: 0,
  UTILITIES: 1,
  SCRIPTS: 2,
  INDICATOR: 3,
  LIBRARIES: 4,
};

export const TOOLSMAP: any = {
  0: "ea",
  1: "Utility",
  2: "Script",
  3: "Indicator",
  4: "Libraries",
};

export const ToolTypeOption: any = [
  {
    id: TOOLS.EXPERT_ADVISOR,
    group: "Expert Advisor",
  },
  {
    id: TOOLS.UTILITIES,
    group: "Utilities",
  },
  {
    id: TOOLS.SCRIPTS,
    group: "Script",
  },
  {
    id: TOOLS.INDICATOR,
    group: "Indicator",
  },
  {
    id: TOOLS.LIBRARIES,
    group: "Library",
  },
];

export const TICKET_STATUS = {
  OPEN: "open",
  CLOSE: "closed",
  INPROGRESS: "inprogress",
  REOPEN: "reopen",
  RESOLVED: "resolved",
};

export const TicketOption: any = [
  {
    id: TICKET_STATUS.OPEN,
    label: "Open",
  },
  {
    id: TICKET_STATUS.CLOSE,
    label: "Close",
  },
  {
    id: TICKET_STATUS.INPROGRESS,
    label: "In Progress",
  },
  {
    id: TICKET_STATUS.REOPEN,
    label: "Reopen",
  },
  {
    id: TICKET_STATUS.RESOLVED,
    label: "Resolved",
  },
];
