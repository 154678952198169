/* eslint-disable no-case-declarations */
import {
  GET_TEMPOARARY_ACCOUNT_LIST,
  GET_LIVE_ACCOUNT_LIST,
  GET_DEMO_ACCOUNT_LIST,
  GET_ALL_ACCOUNT_LIST,
  ADD_ACCOUNT_LIST,
  DELETE_ACCOUNT_LIST,
  GET_ALTERNATIVE_NAME_LIST,
  GET_TRADING_ACCOUNT_LIST,
  GET_BROKER_LIST,
  ADD_TRADING_ACCOUNT,
  DELETE_TRADING_ACCOUNT,
} from "../constants/action-types";
import { AccountData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  tmpAccount: [],
  onSaveLoading: false,
  demoAccount: [],
  liveAccount: [],
  allAccount: [],
  tradingAccount: [],
  brokerList: [],
  totalRecord: 0,
  alterNativeNames: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: AccountData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_TEMPOARARY_ACCOUNT_LIST.GET_TEMPOARARY_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_TEMPOARARY_ACCOUNT_LIST.GET_TEMPOARARY_ACCOUNT_LIST_SUCCESS:
        const tmpAc: any = action.payload;
        return {
          ...state,
          tmpAccount: tmpAc.data,
          totalRecord: tmpAc.totalCount,
          isLoading: false,
        };

      case GET_TEMPOARARY_ACCOUNT_LIST.GET_TEMPOARARY_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case GET_LIVE_ACCOUNT_LIST.GET_LIVE_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_LIVE_ACCOUNT_LIST.GET_LIVE_ACCOUNT_LIST_SUCCESS:
        const lvAc: any = action.payload;
        return {
          ...state,
          liveAccount: lvAc.data,
          totalRecord: lvAc.totalCount,
          isLoading: false,
        };

      case GET_LIVE_ACCOUNT_LIST.GET_LIVE_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case GET_ALL_ACCOUNT_LIST.GET_ALL_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_ALL_ACCOUNT_LIST.GET_ALL_ACCOUNT_LIST_SUCCESS:
        const alAc: any = action.payload;
        return {
          ...state,
          allAccount: alAc.data,
          totalRecord: alAc.totalCount,
          isLoading: false,
        };

      case GET_ALL_ACCOUNT_LIST.GET_ALL_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case GET_DEMO_ACCOUNT_LIST.GET_DEMO_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_DEMO_ACCOUNT_LIST.GET_DEMO_ACCOUNT_LIST_SUCCESS:
        const demoAc: any = action.payload;
        return {
          ...state,
          demoAccount: demoAc.data,
          totalRecord: demoAc.totalCount,
          isLoading: false,
        };

      case GET_DEMO_ACCOUNT_LIST.GET_DEMO_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case GET_BROKER_LIST.GET_BROKER_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_BROKER_LIST.GET_BROKER_LIST_SUCCESS:
        const broker: any = action.payload;
        return {
          ...state,
          brokerList: broker.data,
          // totalRecord: demoAc.totalCount,
          isLoading: false,
        };

      case GET_BROKER_LIST.GET_BROKER_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_SUCCESS:
        return {
          ...state,
          demoAccount: action.payload,
          isLoading: false,
        };

      case DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ALTERNATIVE_NAME_LIST.GET_ALTERNATIVE_NAME_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ALTERNATIVE_NAME_LIST.GET_ALTERNATIVE_NAME_LIST_SUCCESS:
        return {
          ...state,
          alterNativeNames: action.payload,
          isLoading: false,
        };

      case GET_ALTERNATIVE_NAME_LIST.GET_ALTERNATIVE_NAME_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case GET_TRADING_ACCOUNT_LIST.GET_TRADING_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_TRADING_ACCOUNT_LIST.GET_TRADING_ACCOUNT_LIST_SUCCESS:
        const tAc: any = action.payload;
        return {
          ...state,
          tradingAccount: tAc.data,
          totalRecord: tAc.totalCount,
          isLoading: false,
        };

      case GET_TRADING_ACCOUNT_LIST.GET_TRADING_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };
      case ADD_TRADING_ACCOUNT.ADD_TRADING_ACCOUNT_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_TRADING_ACCOUNT.ADD_TRADING_ACCOUNT_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_TRADING_ACCOUNT.ADD_TRADING_ACCOUNT_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_TRADING_ACCOUNT.DELETE_TRADING_ACCOUNT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_TRADING_ACCOUNT.DELETE_TRADING_ACCOUNT_SUCCESS:
        return {
          ...state,
          // demoAccount: action.payload,
          isLoading: false,
        };

      case DELETE_TRADING_ACCOUNT.DELETE_TRADING_ACCOUNT_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
);

export default reducer;
