import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Table } from "react-bootstrap";
import { Button } from "@mui/material";
import { dispatch } from "../../redux";
import { redeemGiftList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { useSelector } from "react-redux";
import Loader from "../common/Loader";
import { FormattedMessage } from "react-intl";
import { dateCompare } from "../../util/utils";
import { ProgressDownload } from "../common/ProgressDownload";
import { DownloadsIcon } from "../../util/iconStyle";

const RedeemModal = ({ details, onClose, open }: any) => {
  const onSaveLoading = useSelector(
    (state: Store) => state.giftReducer.onSaveLoading,
  );

  const vpsTypeList = useSelector(
    (state: Store) => state.VPSmachineReducer.VPSMachineTypeList,
  );

  const handleClose = () => {
    onClose(false);
  };
  const redeemClick = () => {
    dispatch(redeemGiftList(true, details.id));
    onClose(false);
  };
  const [Load, setLoad] = useState<any>([]);
  const btnRef = useRef<any>();

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      console.log("btnRef.current", btnRef);
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      {details && Object.keys(details).length > 0 ? (
        <>
          {" "}
          <DialogTitle>{details?.giftCategory?.name || "Gift"}</DialogTitle>
          <Table style={{ textAlign: "center" }}>
            <tr>
              <th>
                <FormattedMessage
                  id="gift.redeem.modal.head"
                  defaultMessage="Details"
                />
              </th>
              <td>{details.details}</td>
            </tr>

            <tr>
              <th>
                <FormattedMessage
                  id="gift.redeem.modal.Reason"
                  defaultMessage="Reason"
                />
              </th>
              <td>{details.reason}</td>
            </tr>
            {details?.code?.length > 0 &&
              !details.exp &&
              details.redeemed &&
              [1, 2].includes(details?.giftCategory?.id) && (
                <tr>
                  <th>
                    <FormattedMessage
                      id="gift.redeem.modal.GiftCode"
                      defaultMessage="Gift Code"
                    />
                  </th>
                  <td>{details.code}</td>
                </tr>
              )}
            {details?.code?.length > 0 &&
              [12, 13, 14].includes(details?.giftCategory?.id) && (
                <tr>
                  <th>
                    <FormattedMessage
                      id="gift.redeem.modal.vpsType"
                      defaultMessage="VPS Type"
                    />
                  </th>
                  <td>
                    {vpsTypeList[Number(details.code) - 1]?.typeName ||
                      "unknown"}
                  </td>
                </tr>
              )}
            {details?.giftAttachmentPath?.length > 0 &&
              !details.exp &&
              details.redeemed &&
              [11].includes(details?.giftCategory?.id) && (
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.DownloadAttachment"
                      defaultMessage="Download"
                    />
                  </th>
                  <td>
                    <div style={{ textAlign: "center" }}>
                      <ProgressDownload
                        setLoad={setLoad}
                        Load={Load}
                        elId={`p${details?.id}1`}
                        ref={btnRef}
                      >
                        <button
                          className="btn btn-secondary btn-sm"
                          id={`p${details?.id}`}
                          onClick={() =>
                            onDonwloadButtonClick(
                              `p${details?.id}`,
                              details?.giftAttachmentPath,
                            )
                          }
                        >
                          <FormattedMessage
                            id="table.head.Download"
                            defaultMessage="Download"
                          />{" "}
                          <DownloadsIcon />
                        </button>
                      </ProgressDownload>
                    </div>
                  </td>
                </tr>
              )}
            {console.log("load", Load)}
          </Table>
          <div style={{ textAlign: "end" }} className="mb-3">
            <Button
              size="small"
              variant="text"
              onClick={onClose}
              style={{ color: "#68439a" }}
              className="mr-3 ml-3"
            >
              <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
            </Button>
          </div>
        </>
      ) : null}
    </Dialog>
  );
};

RedeemModal.propTypes = {
  details: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onRedeem: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RedeemModal;
