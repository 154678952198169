import React, { useRef, useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { formatDate, saveFile } from "../../util/utils";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { getHtml } from "../ExpertAvisors/CurrentVersion";
import { ProgressDownload } from "../../Components/common/ProgressDownload";

const CurrentVersion = ({ versionLst, name }: any) => {
  const btnRef = useRef<any>();
  const [Load, setLoad] = useState<any>([]);

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      console.log("btnRef.current", btnRef);
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };
  // }

  return (
    <div>
      {versionLst && versionLst.length ? (
        versionLst.map((v: any, index: any) => (
          <Card
            style={{
              width: "100%",
              boxShadow: "none",
              border: "1px solid #f3f3f3",
            }}
          >
            <Card.Body>
              <h4 style={{ fontWeight: "500" }}>
                {v.version}
                {"   "}({formatDate(v.createdAt, "DD/MM/YYYY")})
              </h4>
              <Row className="mt-1 mb-3">
                <Col>
                  <Typography component="div">{getHtml(v.info)}</Typography>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  {name === "GEMS.DLL" && index === 0 ? (
                    <>
                      <p style={{ lineHeight: "1.5" }}>
                        <FormattedMessage
                          id="library.subHead.archieved1"
                          defaultMessage="If you error on security protocol (mainly on Win 7 or Server 2008R2), please update your computer or your VPS "
                        />
                        :{" "}
                      </p>
                      <Button
                        href="https://support.microsoft.com/en-us/topic/update-to-enable-tls-1-1-and-tls-1-2-as-default-secure-protocols-in-winhttp-in-windows-c4bd73d2-31d7-761e-0178-11268bb10392"
                        target="_blank"
                        size="sm"
                        style={{ backgroundColor: "#00b69b" }}
                      >
                        <FormattedMessage
                          id="head.DownloadPatch"
                          defaultMessage="Download Patch"
                        />
                      </Button>{" "}
                    </>
                  ) : (
                    <>{/* {getHtml(v.info)} */}</>
                  )}{" "}
                  <br />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  {name === "GEMS.DLL" && index === 0 ? (
                    <p style={{ lineHeight: "1.5" }}>
                      <FormattedMessage
                        id="library.subHead.archieved2"
                        defaultMessage="Guidance for updating security protocol"
                      />
                      :<br />
                      <Button
                        href="https://gemsfx-assets.s3.amazonaws.com/patch/Server+2008+R2_+Windows7+%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A0%85.pdf"
                        target="_blank"
                        size="sm"
                        style={{ backgroundColor: "#00b69b" }}
                      >
                        <FormattedMessage
                          id="head.DownloadGuidence"
                          defaultMessage="Download Patch"
                        />
                      </Button>
                    </p>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {v.download_path ? (
                    <ProgressDownload
                      Load={Load}
                      setLoad={setLoad}
                      elId={`ct${index}1`}
                      ref={btnRef}
                    >
                      <Button
                        id={`ct${index}`}
                        onClick={() =>
                          onDonwloadButtonClick(`ct${index}`, v.download_path)
                        }
                        style={{ width: "100%" }}
                        size="sm"
                      >
                        <FormattedMessage
                          id="expert.Current.DownloadTool"
                          defaultMessage="Download Tool "
                        />
                      </Button>
                    </ProgressDownload>
                  ) : (
                    <Button style={{ width: "100%" }} size="sm" disabled>
                      <FormattedMessage
                        id="expert.Current.DownloadTool"
                        defaultMessage="Download Latest Tool"
                      />
                    </Button>
                  )}
                </Col>
                <Col>
                  {v.manual_path ? (
                    <ProgressDownload
                      Load={Load}
                      setLoad={setLoad}
                      elId={`cm${index}1`}
                      ref={btnRef}
                    >
                      <Button
                        color="danger"
                        id={`cm${index}`}
                        // onClick={() => saveFile(v.manual_path)}
                        onClick={() =>
                          onDonwloadButtonClick(`cm${index}`, v.manual_path)
                        }
                        style={{ width: "100%", backgroundColor: "" }}
                        size="sm"
                      >
                        <FormattedMessage
                          id="expert.Current.DownloadManual"
                          defaultMessage="Download Manual"
                        />
                      </Button>
                    </ProgressDownload>
                  ) : (
                    <Button
                      color="danger"
                      style={{
                        width: "100%",
                        backgroundColor: "",
                        visibility: "hidden",
                      }}
                      size="sm"
                      disabled
                    >
                      <FormattedMessage
                        id="expert.Current.DownloadManual"
                        defaultMessage="Download Manual"
                      />
                    </Button>
                  )}
                </Col>{" "}
              </Row>
            </Card.Body>
          </Card>
        ))
      ) : (
        <NoDatFound />
      )}
    </div>
  );
};

export default CurrentVersion;
