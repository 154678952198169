import { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Col, Breadcrumb } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { dispatch } from "../../redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { getEAVersionList } from "../../redux/action/expertAdvisorAction";
import { Store } from "../../redux/Actions";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CurrentVersion from "./CurrentVersion";
import ArchivedVersion from "./ArchivedVersion";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { saveFile } from "../../util/utils";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import EA from "../../assets/img/EA.svg";
import { ProgressDownload } from "../../Components/common/ProgressDownload";
import { findLatestVersionDownloadPath } from "../../util/utils";

export const EAVersionDetail = () => {
  const EADetail: any = useSelector(
    (state: Store) => state.expertAdvisorReducer.EADetail,
  ) as Array<any>;
  const EACurrentData = useSelector(
    (state: Store) => state.expertAdvisorReducer.EACurrentData,
  ) as Array<any>;
  const EAarchiveData = useSelector(
    (state: Store) => state.expertAdvisorReducer.EAarchiveData,
  ) as Array<any>;
  const Advertisement = useSelector(
    (state: Store) => state.expertAdvisorReducer.Advertisement,
  ) as Array<any>;

  const isLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.isLoading,
  ) as any;

  const EAAllData = [...EACurrentData, ...EAarchiveData].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  const useParam = useParams();
  const location = useLocation();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const btnRef = useRef<any>();
  const [Load, setLoad] = useState<any>([]);
  const latestToolDownloadIndex = findLatestVersionDownloadPath(
    EAAllData,
    true,
  );
  const latestManualDownloadIndex = findLatestVersionDownloadPath(
    EAAllData,
    false,
  );

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      console.log("btnRef.current", btnRef);
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };
  useEffect(() => {
    const { id } = useParam;
    const { state }: any = location;
    const isGranted = state?.isGranted || false;
    if (id && parseInt(id) > 0) {
      dispatch(getEAVersionList(false, id, isGranted));
    }
  }, []);

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}

        <section className="page-content container-fluid">
          <Breadcrumb>
            <Breadcrumb.Item
              style={{ background: "transparent", verticalAlign: "middle" }}
            >
              <Link to="/expert-advisors">
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <BsChevronLeft />{" "}
                  <FormattedMessage
                    id="expert.Eversion.Advisor"
                    defaultMessage="Expert Advisor"
                  />
                </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          {EADetail && Object.keys(EADetail).length > 0 ? (
            <>
              <Row>
                <Col sm={12} md={12} lg={12} xl={3}>
                  <Card style={{ width: "100%" }}>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col style={{ textAlign: "center" }}>
                          <h4>{EADetail.name}</h4>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <img
                              src={EADetail?.image_path || EA}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "center" }}>
                          <h4 style={{ fontWeight: "500" }}>
                            <FormattedMessage
                              id="expert.Eversion.USD"
                              defaultMessage="USD$ "
                            />{" "}
                            {EADetail.sellingPrice}
                          </h4>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col style={{ textAlign: "left" }}>
                          <p style={{ lineHeight: "2" }}>{EADetail.info}</p>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col style={{ textAlign: "left" }}>
                          {EAAllData[latestToolDownloadIndex]?.download_path ? (
                            <ProgressDownload
                              setLoad={setLoad}
                              Load={Load}
                              elId={`t${EAAllData[latestToolDownloadIndex]?.id}1`}
                              ref={btnRef}
                            >
                              <Button
                                style={{ width: "100%", whiteSpace: "normal" }}
                                size="sm"
                                id={`t${EAAllData[latestToolDownloadIndex]?.id}`}
                                onClick={() =>
                                  onDonwloadButtonClick(
                                    `t${EAAllData[latestToolDownloadIndex]?.id}`,
                                    EAAllData[latestToolDownloadIndex]
                                      ?.download_path,
                                  )
                                }
                              >
                                <FormattedMessage
                                  id="head.DownloadLatestTool"
                                  defaultMessage="Download Latest Tool"
                                />
                              </Button>
                            </ProgressDownload>
                          ) : (
                            <Button
                              style={{ width: "100%", whiteSpace: "normal" }}
                              size="sm"
                              disabled
                            >
                              <FormattedMessage
                                id="head.DownloadLatestTool"
                                defaultMessage="Download Latest Tool"
                              />
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          {EAAllData[latestManualDownloadIndex]?.manual_path ? (
                            <ProgressDownload
                              Load={Load}
                              setLoad={setLoad}
                              elId={`m${EAAllData[latestManualDownloadIndex]?.id}1`}
                              ref={btnRef}
                            >
                              <Button
                                color="danger"
                                style={{
                                  width: "100%",
                                  whiteSpace: "normal",
                                  backgroundColor: "",
                                }}
                                id={`m${EAAllData[latestManualDownloadIndex]?.id}`}
                                onClick={() =>
                                  onDonwloadButtonClick(
                                    `m${EAAllData[latestManualDownloadIndex]?.id}`,
                                    EAAllData[latestManualDownloadIndex]
                                      ?.manual_path,
                                  )
                                }
                                size="sm"
                              >
                                <FormattedMessage
                                  id="head.DownloadLatestManual"
                                  defaultMessage="Download Latest Manual"
                                />
                              </Button>
                            </ProgressDownload>
                          ) : (
                            <Button
                              color="danger"
                              style={{
                                width: "100%",
                                whiteSpace: "normal",
                                backgroundColor: "",
                              }}
                              size="sm"
                              hidden
                            >
                              <FormattedMessage
                                id="head.DownloadLatestManual"
                                defaultMessage="Download Latest Manual"
                              />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={12} xl={9}>
                  <Card style={{ width: "100%" }}>
                    <Card.Body>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          padding: "0px",
                        }}
                        style={{ padding: "0px" }}
                        className={classes.tabs}
                      >
                        <Tabs
                          value={activeTab}
                          onChange={setAciveTab}
                          className={classes.tabs}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={
                              <FormattedMessage
                                id="tab.CURRENT"
                                defaultMessage="Current"
                              />
                            }
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={
                              <FormattedMessage
                                id="tab.ARCHIVE"
                                defaultMessage="Archived"
                              />
                            }
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={activeTab} index={0}>
                        <CurrentVersion versionLst={EACurrentData} />
                      </TabPanel>
                      <TabPanel value={activeTab} index={1}>
                        <ArchivedVersion versionLst={EAarchiveData} />
                      </TabPanel>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* <div>
                  <h2 className="mb-4">Advertisement</h2>
                  {Advertisement && Advertisement.length > 0
                    ? Advertisement.map((i: any) => (
                      <Row>
                        <Card style={{ width: "100%" }}>
                          <Card.Body>
                            <h4>{i?.name}</h4>
                            {i?.advertisementImage && (
                              <img src={i?.advertisementImage} />
                            )}
                          </Card.Body>
                        </Card>
                      </Row>
                    ))
                  : null}
              </div> */}
              {/* <div>
                <h2 className="mb-4">                  <FormattedMessage
                  id="advertisement"
                  defaultMessage="Advertisement" /></h2>
                <Row>
                  {Advertisement && Advertisement.length > 0
                    ? Advertisement.map((i: any) => (
                      <Col sm={12} md={5} lg={4} className="mb-3 mb-lg-0">
                        <Card className="equal-height">
                          <Card.Header><h4 className="m-0">{i?.name}</h4></Card.Header>
                          <Card.Body className="advertisement-body">
                            {i?.advertisementImage ? (
                              <img src={i?.advertisementImage} className="advertisement-images" />
                            ) : (
                              <img src={require('../../assets/img/noImage.jpeg')} className="advertisement-images" />
                            )}
                            <FormattedMessage
                              id="table.head.Description"
                              defaultMessage="Description"
                            /><br />
                            {i?.details ? <div dangerouslySetInnerHTML={{ __html: i?.details }} /> : null}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                    : null}
                </Row>
              </div> */}
            </>
          ) : (
            <p>{!isLoading && <NoDatFound />}</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default EAVersionDetail;

const useStyles = makeStyles({
  tabs: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f57c51",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f57c51",
      BsBorderBottom: "1px solid",
    },
    "& .MuiTypography-root": {
      lineHeight: "1.5",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
