import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        className="content-wrapper d-flex align-items-center justify-content-center"
        style={{ backgroundColor: "#f6f8fa" }}
      >
        <div className="text-center mx-auto" style={{ maxWidth: "800px" }}>
          <h1
            className="m-0 text-big font-weight-bold"
            style={{ fontSize: "150px", lineHeight: "150px" }}
          >
            404
          </h1>
          <h2 className="m-t-20 font-size-30">Not Found</h2>
          <p>The resource requested could not be found on this server!</p>
          <Button className="mt-3" onClick={() => navigate("/home")}>
            Dashboard
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
