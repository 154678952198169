import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/img/gemsFX_normal.svg";
import { resetPasswordSchema } from "../util/validationSchema";
import { forgotPassword } from "../redux/action";
import { Store } from "../redux/Actions";
import { FormattedMessage, useIntl } from "react-intl";
import { Translation } from "../Components/common/Translation";
import MyPasswordInputWrapper from "../Components/MyPasswordInput";

const SetPasswod = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonClick, setButtonClick] = useState(false);

  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );

  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const [searchParams] = useSearchParams();
  const isForgotpasswordSuccess = useSelector(
    (state: Store) => state.userDataReducer.isForgotpasswordSuccess,
  );

  useEffect(() => {
    if (isForgotpasswordSuccess && buttonClick) {
      navigate("/login");
    }
  }, [isForgotpasswordSuccess]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      const a = new URLSearchParams(location.search);
      const email = searchParams
        .getAll("email")
        .toString()
        .trim()
        .replace(" ", "+");
      dispatch(
        forgotPassword({
          email: email,
          token: a.get("token"),
          newPassword: values.password,
        }),
      );
      setButtonClick(true);
    },
  });

  useEffect(() => {
    if (isLoggedin) {
      navigate("/home");
    }
  }, [isLoggedin]);

  const { touched, errors } = formik;

  return (
    <React.Fragment>
      <div className="main-container d-flex align-items-center justify-content-center bg-login-image">
        <div className="login-box">
          <Translation />
          <div className="login-logo">
            <img style={{ height: "50px" }} src={Logo} alt="Logo" />
          </div>
          <div className="login-box-body">
            <h1 className="text-center mb-3 font-weight-500">
              <FormattedMessage
                id="Recover Password"
                defaultMessage="Recover Password"
              />
            </h1>
            <p className="text-center mb-4">
              {" "}
              <FormattedMessage
                id="Enter your new password"
                defaultMessage="Enter your new password"
              />
            </p>
            <form>
              <div className="form-group">
                <MyPasswordInputWrapper
                  value={formik.values.password}
                  className="form-group"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={errors}
                  touched={touched}
                  inputDefaultMessage={"Password"}
                  inputDefaultMessageId={"login.Password"}
                />
              </div>

              <div className="form-group">
                <MyPasswordInputWrapper
                  value={formik.values.confirmPassword}
                  className="form-group"
                  name="confirmPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errors={errors}
                  touched={touched}
                  inputDefaultMessage={"Confirm Password"}
                  inputDefaultMessageId={"profile.confirmPassword"}
                />
              </div>

              <button
                disabled={isLoading}
                type="button"
                className="btn btn-primary btn-block btn-lg"
                onClick={() => formik.handleSubmit()}
              >
                <FormattedMessage
                  id="Set Password"
                  defaultMessage="Set Password"
                />
              </button>

              <div className="d-flex align-items-center justify-content-between m-t-20">
                {/* <div className="form-group m-0">
                  <label
                    className="control control-outline control-primary control--checkbox m-0"
                    htmlFor="as"
                  >
                    Remember Me
                    <input type="checkbox" id="as" />
                    <div className="control__indicator"></div>
                  </label>
                </div> */}
                <div className="password-reset-link">
                  <Link className="text-accent-custom font-size-14" to="/login">
                    {" "}
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="login-box-footer border-top">
            <div className="password-reset-link text-center">
              {/* <Link className="text-accent-custom" to="/forgot-password"> Login</Link> */}
              <FormattedMessage
                id="Don't have an account ?"
                defaultMessage="Don't have an account ?"
              />
              <Link className="text-accent-custom" to="/register">
                {" "}
                <FormattedMessage
                  id="Register here"
                  defaultMessage="Register here"
                />
              </Link>
            </div>
          </div>
          {/* <button type="button" className="btn btn-primary btn-block btn-lg" style={{ borderRadius: '0px' }} onClick={() => formik.handleSubmit()} >Register</button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SetPasswod;
