/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_INDICATORS_LIST,
  GET_INDICATOR_VERSION_LIST,
  GET_UNAUTHORIZED_INDICATORS_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_INDICATOR_LIST_URL,
  GET_INDICATOR_VERSION_LIST_URL,
  GET_UNAUTHORIZED_INDICATORS_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getIndicatorList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getIndicatorListInit(showToast, sort));
  };

export const getIndicatorListInit =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_LIST.GET_INDICATORS_LIST_INITIALIZATION,
    });
    apiCall(
      GET_INDICATOR_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
      (res: Object) => dispatch(getIndicatorListSuccess(res)),
      (err: any) => dispatch(getIndicatorListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getIndicatorListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_LIST.GET_INDICATORS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getIndicatorListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDICATORS_LIST.GET_INDICATORS_LIST_ERROR,
    });
  };

export const getIndicatorVersionList =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getIndicatorVersionListInit(showToast, eaId, isGranted));
  };

export const getIndicatorVersionListInit =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATOR_VERSION_LIST.GET_INDICATOR_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      GET_INDICATOR_VERSION_LIST_URL + eaId, // + `?isGranted=${isGranted}`,
      (res: Object) => dispatch(getIndicatorVersionListSuccess(res)),
      (err: any) => dispatch(getIndicatorVersionListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getIndicatorVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATOR_VERSION_LIST.GET_INDICATOR_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getIndicatorVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDICATOR_VERSION_LIST.GET_INDICATOR_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getUnauthorizedIndicatorList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUnauthorizedIndicatorListInit(showToast, sort));
  };

export const getUnauthorizedIndicatorListInit =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_INDICATORS_LIST.GET_UNAUTHORIZED_INDICATORS_LIST_INITIALIZATION,
    });
    apiCall(
      GET_UNAUTHORIZED_INDICATORS_LIST_URL +
        `?sort=${sort.order}&sortBy=${sort.by}`,
      (res: Object) => dispatch(getUnauthorizedIndicatorListSuccess(res)),
      (err: any) => dispatch(getUnauthorizedIndicatorListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUnauthorizedIndicatorListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_INDICATORS_LIST.GET_UNAUTHORIZED_INDICATORS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getUnauthorizedIndicatorListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_INDICATORS_LIST.GET_UNAUTHORIZED_INDICATORS_LIST_ERROR,
    });
  };
