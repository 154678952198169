/* eslint-disable no-case-declarations */
import {
  GET_MY_SIGNAL_LIST,
  GET_CLASS_SIGNAL_LIST,
  ADD_CLASS_SIGNAL,
  EDIT_CLASS_SIGNAL,
  DELETE_CLASS_SIGNAL,
} from "../constants/action-types";
import { SignalData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  onSaveLoading: false,
  isLoading: false,
  MySignalLst: [],
  ClassSignalLst: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: SignalData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_MY_SIGNAL_LIST.GET_MY_SIGNAL_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_MY_SIGNAL_LIST.GET_MY_SIGNAL_LIST_SUCCESS:
        const mySig: any = action.payload;
        return {
          ...state,
          totalRecord: mySig.count,
          MySignalLst: mySig.data,
          isLoading: false,
        };

      case GET_MY_SIGNAL_LIST.GET_MY_SIGNAL_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case GET_CLASS_SIGNAL_LIST.GET_CLASS_SIGNAL_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_CLASS_SIGNAL_LIST.GET_CLASS_SIGNAL_LIST_SUCCESS:
        const classSig: any = action.payload;
        return {
          ...state,
          ClassSignalLst: classSig.data,
          totalRecord: classSig.totalCount,
          isLoading: false,
        };

      case GET_CLASS_SIGNAL_LIST.GET_CLASS_SIGNAL_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_CLASS_SIGNAL.ADD_CLASS_SIGNAL_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_CLASS_SIGNAL.ADD_CLASS_SIGNAL_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_CLASS_SIGNAL.ADD_CLASS_SIGNAL_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_CLASS_SIGNAL.EDIT_CLASS_SIGNAL_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_CLASS_SIGNAL.EDIT_CLASS_SIGNAL_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_CLASS_SIGNAL.EDIT_CLASS_SIGNAL_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_CLASS_SIGNAL.DELETE_CLASS_SIGNAL_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case DELETE_CLASS_SIGNAL.DELETE_CLASS_SIGNAL_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_CLASS_SIGNAL.DELETE_CLASS_SIGNAL_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
