/* eslint-disable no-case-declarations */
import {
  GET_VPS_MACHINE_LIST,
  GET_VPS_MACHINE_TYPE_LIST,
  CREATE_VPS_MACHINE,
} from "../constants/action-types";
import { VPSMachineData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  VPSMachineList: [],
  VPSMachineTypeList: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: VPSMachineData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_VPS_MACHINE_LIST.GET_VPS_MACHINE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_VPS_MACHINE_LIST.GET_VPS_MACHINE_LIST_SUCCESS:
        const allPost = action.payload;
        return {
          ...state,
          VPSMachineList: allPost,
          isLoading: false,
        };

      case GET_VPS_MACHINE_LIST.GET_VPS_MACHINE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_SUCCESS:
        return {
          ...state,
          VPSMachineTypeList: action.payload,
          isLoading: false,
        };

      case GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case CREATE_VPS_MACHINE.CREATE_VPS_MACHINE_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case CREATE_VPS_MACHINE.CREATE_VPS_MACHINE_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case CREATE_VPS_MACHINE.CREATE_VPS_MACHINE_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
