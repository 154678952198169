import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../redux";
import { getQR, getUnreadAnnouncement } from "../redux/action";
import { Store } from "../redux/Actions";
import QRCode from "react-qr-code";
import Announcements from "./Annoucements";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import NotificationAlert from "../Components/Notification/NotificationAlert";
import ClassImage from "../assets/img/user.jpg";
import { margin } from "@mui/system";
import { Link } from "react-router-dom";
import NewGift from "../Components/Dashboard/NewGift";
import NewClass from "../Components/Dashboard/NewClass";

const Dashboard = () => {
  const useInfo = useSelector((state: Store) => state.userDataReducer.myInfo);

  const [QR, setQR] = useState("");
  useEffect(() => {
    dispatch(getQR());
  }, []);

  useEffect(() => {
    const qr = { QR: useInfo.QR, id: useInfo.id };
    setQR(JSON.stringify(qr));
  }, [useInfo]);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="content">
          <header className="page-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h1>
                  <FormattedMessage id="dashboard" defaultMessage="Dashboard" />{" "}
                </h1>
              </div>
            </div>
          </header>
          <section className="page-content container-fluid client-dashboard">
            <Row className="g-5" xs={1} sm={1} md={1} lg={2} xl={3}>
              <Col className="mb-4">
                <Card className="equal-height mb-0">
                  <Card.Header style={{ position: "relative" }}>
                    <h5 style={{ position: "absolute" }}>
                      <FormattedMessage
                        id="dashboard.QRcode"
                        defaultMessage="QR Code"
                      />
                    </h5>
                  </Card.Header>
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    {Object.keys(useInfo).length > 0 && QR && (
                      <div style={{ background: "white", padding: "20px" }}>
                        <div className="qr-code-image" style={{}}>
                          <QRCode
                            style={{ height: "auto" }}
                            viewBox={`0 0 256 256`}
                            value={QR}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4">
                <Card className="equal-height qr-code-box mb-0">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    {/* {Object.keys(useInfo).length > 0 && useInfo.QR && ( */}
                    <a target="_blank" href="https://wiki.gemsfx.com/v/Home">
                      {" "}
                      <div
                        className="qr-code-box-image-container"
                        style={{
                          border: "none",
                          width: "250px",
                          margin: `0 auto`,
                        }}
                      >
                        <svg fill="#000000" viewBox="0 0 50 50">
                          <path d="M5,37.85,1,22.09H3.15l1.87,8c.47,2,.89,3.93,1.17,5.45h0C6.49,33.94,7,32.07,7.52,30l2.11-7.95h2.12l1.92,8c.44,1.87.86,3.74,1.1,5.4h.05c.32-1.73.77-3.49,1.26-5.45l2.08-7.95h2.08L15.77,37.85H13.65l-2-8.21a47.53,47.53,0,0,1-1-5.14h-.05a49.57,49.57,0,0,1-1.21,5.14L7.12,37.85Z"></path>
                          <path d="M24.05,23.35a1.28,1.28,0,0,1-2.55,0,1.25,1.25,0,0,1,1.28-1.28A1.22,1.22,0,0,1,24.05,23.35Zm-2.29,14.5V26.53h2V37.85Z"></path>
                          <path d="M29.26,31.72h0c.28-.39.67-.89,1-1.28l3.32-3.91h2.48l-4.37,4.65,5,6.67H34.21l-3.9-5.43-1,1.17v4.26h-2V21.25h2Z"></path>
                          <path d="M48.74,18.33l-1.08-4.86a1.69,1.69,0,0,0-3.33.37v.26a.38.38,0,0,0,.39.39.39.39,0,0,0,.39-.39v-.26a.91.91,0,0,1,1.79-.2L48,18.5a10.29,10.29,0,0,1,.23,1.83h0a3.87,3.87,0,0,0-7.57-.71,1.28,1.28,0,0,0-1-.46,1.3,1.3,0,0,0-1,.46,3.87,3.87,0,0,0-7.57.71h0a10.31,10.31,0,0,1,.24-1.83l1.08-4.86a.89.89,0,0,1,.88-.71.91.91,0,0,1,.91.91v.26a.38.38,0,0,0,.39.39A.39.39,0,0,0,35,14.1v-.26a1.69,1.69,0,0,0-3.33-.37l-1.08,4.86a10.67,10.67,0,0,0-.26,2.39.38.38,0,0,0,.38.39h.43a3.88,3.88,0,0,0,7.74-.29c.08-.22.39-.88.79-.88s.7.62.79.88a3.88,3.88,0,0,0,7.74.29h.43a.38.38,0,0,0,.39-.39A11.29,11.29,0,0,0,48.74,18.33ZM35,23.84a3.12,3.12,0,1,1,3.11-3.12A3.12,3.12,0,0,1,35,23.84Zm9.35,0a3.12,3.12,0,1,1,3.11-3.12A3.12,3.12,0,0,1,44.33,23.84Z"></path>
                          <path d="M35,18.38a2.34,2.34,0,0,0-2.34,2.34.39.39,0,0,0,.39.39.38.38,0,0,0,.39-.39A1.56,1.56,0,0,1,35,19.16a.39.39,0,0,0,.39-.39A.4.4,0,0,0,35,18.38Z"></path>
                          <path d="M44.33,18.38A2.34,2.34,0,0,0,42,20.72a.38.38,0,0,0,.39.39.39.39,0,0,0,.39-.39,1.56,1.56,0,0,1,1.56-1.56.39.39,0,0,0,.39-.39A.4.4,0,0,0,44.33,18.38Z"></path>
                          <path d="M40.93,23.35a1.28,1.28,0,0,1-2.55,0,1.25,1.25,0,0,1,1.28-1.28A1.22,1.22,0,0,1,40.93,23.35Z"></path>
                          <rect
                            x="38.62"
                            y="26.53"
                            width="2.06"
                            height="11.31"
                          ></rect>
                        </svg>
                      </div>
                    </a>
                    {/* )} */}
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4">
                <div className="card dashboard-class-card mb-4">
                  <div className="card-body">
                    <h5 className="card-title border-none">
                      <FormattedMessage
                        id="menu.classes.allClass"
                        defaultMessage="All Classes"
                      />
                    </h5>
                    <div className="card-box" style={{ height: "150px" }}>
                      <NewClass />
                    </div>
                    <div className="mt-3 text-right">
                      <Link to="/allClass" className="read-more">
                        <FormattedMessage
                          id="dashboard.readMore"
                          defaultMessage="Read More"
                        />{" "}
                        <i className="zmdi zmdi-long-arrow-right ml-1"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <NewGift />
              </Col>
            </Row>
            <Row className="g-4 mb-5">
              <Col sm={12} md={6} lg={4} className="mb-3 mb-lg-0">
                <Card className="equal-height">
                  <Card.Body>
                    <h2>
                      {useInfo?.vps_count || 0}
                      {/* /{useInfo.liveLimit} */}
                    </h2>
                    <small></small>
                    <h4>
                      {" "}
                      <FormattedMessage
                        id="RunningVps"
                        defaultMessage="Running Vps"
                      />
                    </h4>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={12} md={6} lg={4} className="mb-3 mb-lg-0">
                <Card className="equal-height">
                  <Card.Body>
                    <h2>
                      {useInfo.demoAccountCreated}/{useInfo.demoLimit}
                    </h2>
                    <small>
                      <FormattedMessage
                        id="accounts.Smalltag"
                        defaultMessage="(No. of Temporary Accounts: 0)"
                      />
                    </small>
                    <h4>
                      {" "}
                      <FormattedMessage
                        id="accounts.Demo"
                        defaultMessage="Demo Account"
                      />
                    </h4>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} className="mb-3 mb-lg-0">
                <Card className="equal-height">
                  <Card.Body>
                    <h2>
                      {useInfo.liveAccountCreated}/{useInfo.liveLimit}
                    </h2>
                    <small>
                      <FormattedMessage
                        id="accounts.Smalltag"
                        defaultMessage="(No. of Temporary Accounts: 0)"
                      />
                    </small>
                    <h4>
                      {" "}
                      <FormattedMessage
                        id="accounts.Live"
                        defaultMessage="Live Account"
                      />
                    </h4>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>
        </div>
        <Announcements notice={true} lastPage="Dashboard" />
      </div>

      {/* </div> */}
    </React.Fragment>
  );
};

export default Dashboard;
