import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFAQList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { FormattedMessage } from "react-intl";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DOMPurify from "dompurify";

export const Faqs = () => {
  const dispatch = useDispatch();
  const [faqListing, setFaqListing] = useState<Array<any>>([]);
  const faqList = useSelector((state: Store) => state.faqReducer.FAQList);

  const isLoading = useSelector(
    (state: Store) => state.faqReducer.isLoading,
  ) as any;

  useEffect(() => {
    dispatch(getFAQList(false));
  }, []);

  useEffect(() => {
    if (faqList) {
      const faqListingData = faqList.map((category) => ({
        ...category,
        questions: category.questions.map((question) => ({
          ...question,
          expanded: false,
        })),
      }));
      setFaqListing(faqListingData);
    }
  }, [faqList]);

  const handleChange = (categoryIndex: number, questionIndex: number) => () => {
    const updatedFaqListingData = faqListing.map((category, index) => ({
      ...category,
      questions: category.questions.map((question, index1) => ({
        ...question,
        expanded:
          index === categoryIndex && index1 === questionIndex
            ? !question.expanded
            : question.expanded,
      })),
    }));
    setFaqListing(updatedFaqListingData);
  };

  return (
    <div className="content-wrapper">
      {isLoading && <CenterLoader />}

      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="faqs" defaultMessage="Faqs" />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {faqListing.length ? (
            <>
              {faqListing &&
                faqListing.length &&
                faqListing.map((category: any, index: any) => (
                  <>
                    <h4 className="category-spacing">{category?.name}</h4>
                    {category.questions.map((item: any, index1: any) => {
                      return (
                        <Accordion
                          key={index1}
                          expanded={item.expanded}
                          onChange={handleChange(index, index1)}
                        >
                          <AccordionSummary
                            expandIcon={
                              item.expanded ? (
                                <RemoveCircleOutlineIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )
                            }
                            aria-controls={`panel${index1}bh-content`}
                            id={`panel${index1}bh-header`}
                            className="accordian-summary"
                          >
                            <Box className="accordian-question">
                              <Typography className="question-size">
                                {item.question}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="accordian-answer">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(item?.answer),
                                }}
                              ></div>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                    <div className="faq-spacing" />
                  </>
                ))}
            </>
          ) : (
            <p>{!isLoading && <NoDatFound />}</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default Faqs;
