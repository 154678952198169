export const TBL_HEAD: any = {
  OS: "Operating System",
  processor_cpu: "Processor - CPU",
  RAM: "Memory - RAM",
  storage: "Disk Storage",
  VPS_Location: "VPS Location",
  isMT4Installed: "Pre-installed Gems Panel",
  numberOfMt4: "Number of MT4",
  broker: "Broker",
  EA: "EA (Expert Advisor)",
  ExpertAdvisorVersionList: "Version of EA",
  DefaultEASet: "EA Default Set",
  indicator: "Indicator",
  DefaultIndicatorSet: "Indicator Default Set",
  utilities: "Utilities",
  DefaultUtilitiesSet: "Utilities Default Set",
  GEMS_server_connection: "Gems Server Connection",
  minimum_requirement: "Minimum Requirement",
  subscription_period: "Redemption Period",
  price: "More",
};
