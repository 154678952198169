import { GET_FAQ_LIST } from "../constants/action-types";
import { FAQData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  FAQList: [],
  FAQDetails: {},
  FAQCategoryList: [],
  FAQCategoryDetails: {},
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: FAQData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_FAQ_LIST.GET_FAQ_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_FAQ_LIST.GET_FAQ_LIST_SUCCESS:
        return {
          ...state,
          FAQList: action.payload,
          isLoading: false,
        };

      case GET_FAQ_LIST.GET_FAQ_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
);

export default reducer;
