import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import {
  getIndustryTypeList,
  getJobTitleList,
  register,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Logo from "../../assets/img/gemsFX_black.svg";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import GemsfxChinese from "../../assets/img/Gemsfx-Version4-Logo - Chinese-B.png";
import GemsfxEnglish from "../../assets/img/Gemsfx-Version4-Logo - English-B.png";
import GemsfxChineseW from "../../assets/img/Gemsfx-Version4-Logo - Chinese.png";
import GemsfxEnglishW from "../../assets/img/Gemsfx-Version4-Logo - English.png";
import TermsAndConditions from "./TermsAndConditions";

const RegisterFinalStep = ({ onBack }: any) => {
  const dispatch = useDispatch();
  const [userRegister, setUserRegister] = useState<any>({});
  const userInfos = useSelector(
    (state: Store) => state.userDataReducer.userInfo,
  );

  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const industry = useSelector(
    (state: Store) => state.registerReducer.industry,
  ) as Array<Object>;
  const jobTitles = useSelector(
    (state: Store) => state.registerReducer.jobTitles,
  ) as Array<Object>;

  const districtLst = useSelector(
    (state: Store) => state.registerReducer.districtLst,
  ) as Array<Object>;
  const [dis, setDis] = useState<any>({});

  const local = useSelector((state: Store) => state.userDataReducer.local);
  const darkTheme = useSelector(
    (state: Store) => state.userDataReducer.darkTheme,
  );

  useEffect(() => {
    if (Object.keys(userRegister).length > 0) {
      dispatch(getIndustryTypeList(false, local));
      dispatch(getJobTitleList(false, local, userRegister.industry));
    }
  }, []);

  const onRegisterButtonClick = () => {
    const usrReg = { ...userRegister };

    delete usrReg.accepted;
    delete usrReg.course;
    delete usrReg.className;
    delete usrReg.englishNickName;
    usrReg.address = "";
    delete usrReg.address2;
    delete usrReg.address3;
    usrReg.signature = usrReg.signatureFile;
    delete usrReg.signatureFile;
    const dialCode = usrReg.dialCode;
    const phoneNumber = usrReg.phoneNumber;
    const number = phoneNumber.slice(dialCode.length);
    usrReg.phoneNumber = number;
    sessionStorage.setItem("verify_email", usrReg.email);

    const registerFormData = new FormData();
    for (const [key, value] of Object.entries(usrReg)) {
      const k: any = key;
      const v: any = value;
      registerFormData.append(k, v);
    }

    dispatch(register(registerFormData));
  };

  const curtsyTitle = [
    { title: "Mr.", value: 1 },
    { title: "Mrs.", value: 2 },
    { title: "Miss", value: 3 },
  ];

  useEffect(() => {
    let userInfo = {};
    (Object.values(userInfos) || []).map((i: any) => {
      userInfo = { ...userInfo, ...i };
    });
    const u: any = userInfo;
    (districtLst || []).map((i: any) => {
      if (i.districtId == u?.district) {
        setDis(i.name);
      }
    });
    setUserRegister({ ...userInfo });
  }, []);

  return (
    <>
      {isLoading && <CenterLoader />}
      <div className="mb-4">
        <div className="d-flex flex-wrap justify-content-between border-bottom pb-4 mb-4">
          <h1 className="h5 font-weight-600 m-0">
            <FormattedMessage
              id="register.setp8"
              defaultMessage="Step 8: Confirm Information "
            />{" "}
          </h1>
          <button
            type="button"
            onClick={onBack}
            className="btn btn-primary btn btn-primary"
          >
            <BsChevronLeft />{" "}
            <FormattedMessage id="register.step8.Back" defaultMessage="Back" />{" "}
          </button>
        </div>
        <div className="register-view-form m-t-35">
          <div className="register-view-header mb-3">
            <div className="form-row">
              <div className="col-md-6">
                <div className="mb-3">
                  {/* <img style={{ height: "35px" }} src={Logo} alt="Logo" /> */}
                  {!darkTheme ? (
                    <div className="logo-custom">
                      {local == "en" ? (
                        <img src={GemsfxEnglish} style={{ width: "140px" }} />
                      ) : (
                        <img src={GemsfxChinese} style={{ width: "140px" }} />
                      )}
                    </div>
                  ) : (
                    <div className="logo-custom">
                      {local == "en" ? (
                        <img src={GemsfxEnglishW} style={{ width: "140px" }} />
                      ) : (
                        <img src={GemsfxChineseW} style={{ width: "140px" }} />
                      )}
                    </div>
                  )}
                </div>
                <p className="m-0 text-uppercase font-weight-700">
                  <FormattedMessage
                    id="register.step8.GEMSFX.form"
                    defaultMessage="GEMSFX Application Form"
                  />{" "}
                </p>
                <p className="m-0 font-weight-700">
                  <FormattedMessage
                    id="register.step8.GemsFX"
                    defaultMessage="GemsFX "
                  />{" "}
                </p>
              </div>
              <div className="col-md-6">
                <div className="top-box ml-auto">
                  <div className="form-group mb-2">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      value={userRegister?.className || ""}
                    />
                    <label>
                      <FormattedMessage
                        id="register.step8.Courses"
                        defaultMessage="Course No."
                      />{" "}
                    </label>
                  </div>
                  <div className="row no-gutters">
                    <div className="col">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control-plaintext"
                          value={moment(new Date()).toDate().getDate()}
                        />
                        <label>
                          <FormattedMessage
                            id="register.step8.Day"
                            defaultMessage="Day"
                          />{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control-plaintext"
                          value={moment(new Date()).toDate().getMonth() + 1}
                        />
                        <label>
                          {" "}
                          <FormattedMessage
                            id="register.step8.Month"
                            defaultMessage="Month"
                          />{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control-plaintext"
                          value={moment(new Date()).toDate().getFullYear()}
                        />
                        <label>
                          {" "}
                          <FormattedMessage
                            id="register.step8.Year"
                            defaultMessage="Year"
                          />{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="register-view-body">
            <h6 className="font-weight-700">
              {" "}
              <FormattedMessage
                id="register.step8.Detail.Applicant"
                defaultMessage="A. Details of Applicant"
              />{" "}
            </h6>
            <div className="card">
              <div className="card-body">
                <p className="mb-2">
                  <FormattedMessage
                    id="register.step8.Detail.Applicant1"
                    defaultMessage="1. Full Name (in English*) (same as that shown on your HKID Card/Passport.)"
                  />{" "}
                </p>
                <div className="m-l-15">
                  <div className="form-row">
                    <div className="col-md-3">
                      <div className="form-row">
                        {Object.keys(userRegister).length > 0 &&
                          curtsyTitle.map((i: any) => (
                            <div className="col-6">
                              <label
                                className="control control-outline control-primary control--checkbox m-0"
                                htmlFor="full-name-1"
                              >
                                {i.title}
                                <input
                                  type="checkbox"
                                  id="full-name-1"
                                  checked={i.value === userRegister?.title}
                                  disabled
                                />
                                <div className="control__indicator"></div>
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row no-gutters">
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control-plaintext"
                              value={userRegister?.englishLastName}
                            />
                            <label>
                              {" "}
                              <FormattedMessage
                                id="register.step8.Surname"
                                defaultMessage="Surname"
                              />{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control-plaintext"
                              value={userRegister?.englishFirstName}
                            />
                            <label>
                              <FormattedMessage
                                id="register.step8.GivenName"
                                defaultMessage="Given Name"
                              />{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="mb-2">
                  .{" "}
                  <FormattedMessage
                    id="register.step8.OtherName"
                    defaultMessage="2. Other Name (include Chinese name and Nickname)"
                  />{" "}
                </p>
                <div className="m-l-15">
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="row no-gutters">
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control-plaintext"
                              value={userRegister?.chineseLastName}
                            />
                            <label>
                              {" "}
                              <FormattedMessage
                                id="register.step8.ChineseSurname"
                                defaultMessage="Chinese Surname"
                              />{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control-plaintext"
                              value={userRegister?.chineseFirstName}
                            />
                            <label>
                              <FormattedMessage
                                id="register.step8.ChineseGivenName"
                                defaultMessage="Chinese Given Name"
                              />{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="mb-2">
                  <FormattedMessage
                    id="register.step8.IdentificationNumber"
                    defaultMessage="3. Identification Number* (Please provide a document copy) "
                  />{" "}
                </p>
                {/* {indentityProof.map((i) => ( */}

                <div className="m-l-15">
                  <div className="form-row mb-2">
                    <div className="col-3">
                      <label
                        className="control control-outline control-primary control--checkbox m-0"
                        htmlFor="full-name-4"
                      >
                        <FormattedMessage
                          id="register.step8.CardNo"
                          defaultMessage=" HKID Card No."
                        />
                        <input
                          type="checkbox"
                          id="full-name-4"
                          checked={
                            userRegister.identityType === "Hong kong Id Card"
                          }
                          disabled
                        />
                        <div className="control__indicator"></div>
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="form-group">
                        {userRegister.identityType === "Hong kong Id Card" ? (
                          <input
                            type="text"
                            className="form-control-plaintext side-border-0"
                            value={`${userRegister.identityId}***(*)`}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control-plaintext side-border-0"
                            // value={userRegister.identityId}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <label
                    className="control control-outline control-primary control--checkbox m-0"
                    htmlFor="full-name-4"
                  >
                    <FormattedMessage
                      id="register.step8.PassportNo"
                      defaultMessage=" Passport No. (ONLY applicable for person without HKID card)"
                    />
                    <input
                      type="checkbox"
                      id="full-name-4"
                      checked={userRegister.identityType === "Passport"}
                      disabled
                    />
                    <div className="control__indicator"></div>
                  </label>
                  <div className="form-group mb-3">
                    {userRegister.identityType === "Passport" ? (
                      <input
                        type="text"
                        className="form-control-plaintext side-border-0"
                        value={userRegister.identityId}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control-plaintext side-border-0"
                        value=""
                      />
                    )}
                  </div>
                  <p className="m-0">
                    <FormattedMessage
                      id="register.step8.FirstSIXNumbers"
                      defaultMessage="ONLY first FOUR numbers of Hong Kong Identity Card of the first SIX Numbers of passport are required. Before capture and upload your Identity Documents. Please make sure that you have masked the rest of the Identity Number."
                    />{" "}
                  </p>
                </div>
                {/* ))} */}
              </div>
              <div className="card-body">
                <p className="mb-2">
                  {" "}
                  <FormattedMessage
                    id="register.step8.Dob"
                    defaultMessage="4. Date of Birth*"
                  />{" "}
                </p>
                <div className="m-l-15">
                  <div className="w-75p mx-auto">
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control-plaintext"
                            value={moment(userRegister.DOB, "YYYY-MM-DD")
                              .toDate()
                              .getDate()}
                          />
                          <label>
                            <FormattedMessage
                              id="register.step8.Day"
                              defaultMessage="Day"
                            />{" "}
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control-plaintext"
                            value={
                              moment(userRegister.DOB, "YYYY-MM-DD")
                                .toDate()
                                .getMonth() + 1
                            }
                          />
                          <label>
                            <FormattedMessage
                              id="register.step8.Month"
                              defaultMessage="Month"
                            />{" "}
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control-plaintext"
                            value={moment(userRegister.DOB, "YYYY-MM-DD")
                              .toDate()
                              .getFullYear()}
                          />
                          <label>
                            <FormattedMessage
                              id="register.step8.Year"
                              defaultMessage="Year"
                            />{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="mb-3">
                  {" "}
                  <FormattedMessage
                    id="register.step8.ResidentialAddress"
                    defaultMessage="5. District (in English)"
                  />{" "}
                </p>
                <div className="m-l-15">
                  <div className="form-group mb-1">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      value={dis}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="mb-2">
                  <FormattedMessage
                    id="register.step8.Industry"
                    defaultMessage="6. Industry*"
                  />{" "}
                </p>
                <div className="m-l-15">
                  <div className="form-row">
                    {industry.map((i: any) => (
                      <div className="col-6">
                        <label
                          className="control control-outline control-primary control--checkbox mb-1"
                          htmlFor="industry-1"
                        >
                          {i.name}
                          <input
                            type="checkbox"
                            id="industry-1"
                            checked={i.IndustryTypeId === userRegister.industry}
                            disabled
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="form-group mt-2 mb-1">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      value=""
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="mb-2">
                  <FormattedMessage
                    id="register.step8.JobTitle"
                    defaultMessage="7. Job Title "
                  />{" "}
                </p>
                <div className="m-l-15">
                  <p className="m-0">
                    {" "}
                    {jobTitles.map((j: any) => {
                      if (j.JobTitleId === userRegister.jobTitle) {
                        return j.name;
                      }
                    })}{" "}
                  </p>
                </div>
              </div>
            </div>
            <h6 className="font-weight-700">
              <FormattedMessage
                id="register.step8.ContactInfo"
                defaultMessage="B. Contact Information"
              />{" "}
            </h6>
            <div className="card">
              <div className="row no-gutters">
                <div className="card-body border-bottom">
                  <p className="mb-2">
                    <FormattedMessage
                      id="register.step8.Mobile"
                      defaultMessage="8. Mobile Phone No."
                    />{" "}
                  </p>
                  <div className="m-l-15">
                    {/* (852) 9874563210 */}+{userRegister?.dialCode}{" "}
                    {userRegister?.phoneNumber?.slice(
                      userRegister?.dialCode?.length,
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="mb-2">
                  <FormattedMessage
                    id="register.step8.Email"
                    defaultMessage="10. Email Address*"
                  />{" "}
                </p>
                <div className="m-l-15">
                  <p className="mb-2">{userRegister.email}</p>
                  <FormattedMessage
                    id="register.step8.Emailuses"
                    defaultMessage="This email address will also be used as the sign in account for the system."
                  />
                </div>
              </div>
            </div>
            <h6 className="font-weight-700">
              <FormattedMessage
                id="register.step8.Declaration"
                defaultMessage="C. Declaration"
              />{" "}
            </h6>
            <div className="card">
              <div className="card-body">
                <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                  <TermsAndConditions isCheckbox={false} />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body declaration-container">
                <div className="declaration-item declaration-signature-container">
                  <img
                    src={`data:image/png;base64,${userRegister.signature}`}
                    style={{ border: "1px solid #000" }}
                  />
                  <label>
                    <FormattedMessage
                      id="register.step8.Signature"
                      defaultMessage="Signature"
                    />{" "}
                  </label>
                </div>
                <div className="declaration-item declaration-fullName-container">
                  <div className="side-border-0 declaration-fullName-text">
                    {`${userRegister.englishFirstName} ${userRegister.englishLastName}`}
                  </div>
                  <label>
                    <FormattedMessage
                      id="register.step8.FullName"
                      defaultMessage="Full Name"
                    />{" "}
                  </label>
                </div>
                <div className="declaration-item declaration-date-container">
                  <input
                    type="text"
                    className="form-control-plaintext side-border-0"
                    value={moment(new Date()).format("DD-MM-YYYY")}
                  />
                  <label>
                    <FormattedMessage
                      id="register.step8.Date"
                      defaultMessage="Date"
                    />{" "}
                  </label>
                </div>
                <div className="declaration-text-container">
                  <p>
                    <FormattedMessage
                      id="register.step8.Signature.verify"
                      defaultMessage="(This signature will be used to verify your future correspondence to us.)"
                    />
                  </p>
                </div>
              </div>
            </div>
            <h6 className="font-weight-700">
              <FormattedMessage
                id="register.step8.AdditionalDoc"
                defaultMessage="D. Additional Documents"
              />{" "}
            </h6>
            <div className="card">
              <div className="card-body">
                <div className="m-l-15">
                  <p className="mb-4">
                    <FormattedMessage
                      id="register.step8.Profile"
                      defaultMessage="1. Profile Picture"
                    />{" "}
                  </p>
                  <div className="mb-3">
                    {userRegister.avatar && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          maxWidth: "400px",
                          border: "1px solid #e3e3e3",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(userRegister.avatar)}
                          alt="User Profile Picture"
                        />
                      </div>
                    )}
                  </div>
                  <p>
                    <FormattedMessage
                      id="register.step8.Frontprofile"
                      defaultMessage="Please capture a clear view FRONT profile portrait."
                    />{" "}
                  </p>
                  <p className="mb-4">
                    <FormattedMessage
                      id="register.step8.Copy.Id"
                      defaultMessage="2. Copy of Identity Document"
                    />{" "}
                  </p>
                  <div className="mb-3">
                    {userRegister.idImage && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          maxWidth: "400px",
                          border: "1px solid #e3e3e3",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(userRegister.idImage)}
                          alt="User Identity Document"
                        />
                      </div>
                    )}
                  </div>
                  <p className="mb-4">
                    {" "}
                    <FormattedMessage
                      id="register.step8.DepositRecord"
                      defaultMessage="3. Deposit Record"
                    />{" "}
                  </p>
                  <div className="mb-3">
                    {userRegister.slipPath && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          maxWidth: "400px",
                          border: "1px solid #e3e3e3",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={userRegister.slipPath}
                          alt="User Deposit Record"
                        />
                      </div>
                    )}
                  </div>
                  <p>
                    <FormattedMessage
                      id="register.step8.Clearlyvisible"
                      defaultMessage="(Please make sure that the document is clearly visible and clearly shows the name of the company.)"
                    />{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right mt-auto">
        <Button
          className="btn btn-primary"
          onClick={() => onRegisterButtonClick()}
        >
          {" "}
          <FormattedMessage
            id="register.step8.Btn"
            defaultMessage="Confirm"
          />{" "}
          <BsChevronRight />{" "}
        </Button>
      </div>
    </>
  );
};

export default RegisterFinalStep;
