/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_GIFT_LIST,
  ADD_GIFT_LIST,
  REDEEM_GIFT_LIST,
  GET_GIFT_VPS_LIST,
  GET_GIFT_DETAILS,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_GIFT_LIST_URL,
  ADD_GIFT_LIST_URL,
  EIDT_GIFT_LIST_URL,
  VPS_GIFT_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getGiftList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getGiftListInit(showToast));
  };

export const getGiftListInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_LIST.GET_GIFT_LIST_INITIALIZATION,
    });
    apiCall(
      GET_GIFT_LIST_URL + "?sort=DESC&sortBy=createdAt",
      (res: Object) => dispatch(getGiftListSuccess(res)),
      (err: any) => dispatch(getGiftListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getGiftListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_LIST.GET_GIFT_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getGiftListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_GIFT_LIST.GET_GIFT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addGiftList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addGiftListInit(showToast, indicatorData));
  };

export const addGiftListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_GIFT_LIST.ADD_GIFT_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_GIFT_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addGiftListSuccess(res)),
      (err: any) => dispatch(addGiftListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addGiftListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_GIFT_LIST.ADD_GIFT_LIST_SUCCESS,
    });
    dispatch(getGiftList(false));
  };

export const addGiftListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_GIFT_LIST.ADD_GIFT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const redeemGiftList =
  (
    showToast = true,
    id: any,
    param = {},
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(redeemGiftListInit(showToast, id, param));
  };

export const redeemGiftListInit =
  (
    showToast = true,
    id: any,
    param: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REDEEM_GIFT_LIST.REDEEM_GIFT_LIST_INITIALIZATION,
    });
    apiCall(
      EIDT_GIFT_LIST_URL + id,
      (res: Object) => dispatch(redeemGiftListSuccess(res)),
      (err: any) => dispatch(redeemGiftListError()),
      param,
      METHOD.PUT,
      { showToast },
    );
  };

export const redeemGiftListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REDEEM_GIFT_LIST.REDEEM_GIFT_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(getGiftList(false));
  };

export const redeemGiftListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REDEEM_GIFT_LIST.REDEEM_GIFT_LIST_ERROR,
    });
  };

//Get User VPS List
export const getGiftVPSList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getGiftVPSListInit(showToast));
  };

export const getGiftVPSListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_VPS_LIST.GET_GIFT_VPS_LIST_INITIALIZATION,
    });
    apiCall(
      VPS_GIFT_LIST_URL,
      (res: Object) => dispatch(getGiftVPSListSuccess(res)),
      (err: any) => dispatch(getGiftVPSListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getGiftVPSListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_VPS_LIST.GET_GIFT_VPS_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(getGiftList(false));
  };

export const getGiftVPSListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_GIFT_VPS_LIST.GET_GIFT_VPS_LIST_ERROR,
    });
  };
