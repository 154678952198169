import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";

const Pagination = ({ itemsPerPage, totalPages, onPageChage, page }: any) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalPages / itemsPerPage));
  }, [itemsPerPage, totalPages]);

  const handlePageClick = (event) => {
    setPageCount(Math.ceil(totalPages / itemsPerPage));
    onPageChage(event.selected + 1);
  };

  return (
    <>
      <ReactPaginate
        nextLabel={<FormattedMessage id="page.Next" defaultMessage="Next" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={3}
        pageCount={pageCount}
        forcePage={page}
        previousLabel={
          <FormattedMessage id="page.Previous" defaultMessage="Previous" />
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
    </>
  );
};

export default Pagination;
