/* eslint-disable no-case-declarations */
import {
  GET_UTILITY_LIST,
  GET_UTILITY_UNAUTHORIZED_LIST,
  GET_UTILITY_VERSION_LIST,
} from "../constants/action-types";
import { UtilityData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  utilityList: [],
  UnauthorizedUtilityLst: [],
  utilityDetail: {},
  advertisement: {},
  currentVData: [],
  archiveVData: [],
  unavailableUtilityIsLoading: false,
  availableUtilityIsLoading: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: UtilityData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_UTILITY_LIST.GET_UTILITY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          availableUtilityIsLoading: true,
        };

      case GET_UTILITY_LIST.GET_UTILITY_LIST_SUCCESS:
        const allPost = action.payload;
        return {
          ...state,
          utilityList: allPost,
          isLoading: false,
          availableUtilityIsLoading: false,
        };

      case GET_UTILITY_LIST.GET_UTILITY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          availableUtilityIsLoading: false,
        };

      case GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_SUCCESS:
        const versionDetail: any = action.payload;
        return {
          ...state,
          utilityDetail: versionDetail.UtilityDetail,
          currentVData: versionDetail.data,
          archiveVData: versionDetail.archiveData,
          advertisement: versionDetail.UtilityAdvertisement,
          isLoading: false,
        };

      case GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_UTILITY_UNAUTHORIZED_LIST.GET_UTILITY_UNAUTHORIZED_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          unavailableUtilityIsLoading: true,
        };

      case GET_UTILITY_UNAUTHORIZED_LIST.GET_UTILITY_UNAUTHORIZED_LIST_SUCCESS:
        return {
          ...state,
          UnauthorizedUtilityLst: action.payload,
          isLoading: false,
          unavailableUtilityIsLoading: false,
        };

      case GET_UTILITY_UNAUTHORIZED_LIST.GET_UTILITY_UNAUTHORIZED_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          unavailableUtilityIsLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
