import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import UploadSignature from "../../Components/Class/UploadSignature";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import {
  getMyClasses,
  uploadClassSlip,
  uploadSignatureInClass,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import { toast } from "react-toastify";
import { appIntl } from "../../util/intl";
import { Card, Col, Row } from "react-bootstrap";
import { dateCompare } from "../../util/utils";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const MyClass = () => {
  const dispatch = useDispatch();

  const [lstClasses, setLstClasses] = useState<any>([]);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const lstClass =
    useSelector((state: Store) => state.classReducer.myClasses) || [];
  const isLoading = useSelector((state: Store) => state.classReducer.isLoading);
  const userExpiryDate = useSelector(
    (state: Store) => state.userDataReducer.myInfo.expiryDate,
  );

  const isUserExpired = userExpiryDate < new Date().toISOString();
  const [sId, setSId] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const totalRecord = useSelector(
    (state: Store) => state.classReducer.totalRecord,
  ) as any;
  const attchRef = useRef<any>();
  const [modal, setModal] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const [signaturePath, setSignaturePath] = useState("");
  const [classID, setClassID] = useState(0);

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  useEffect(() => {
    setLstClasses(lstClass);
  }, [lstClass]);

  useEffect(() => {
    dispatch(
      getMyClasses(
        false,
        pageNumber,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const attchmentClick = (id) => {
    if (attchRef.current) {
      attchRef?.current.click();
      setSId(id);
    }
  };

  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;

    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 2097152) {
        const maxSizeInMB = (2097152 / 1024 / 1024).toString();
        toast.error(
          appIntl().formatMessage(
            { id: "errors.uploadFileSizeError" },
            { maxSizeInMB: maxSizeInMB },
          ),
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      const fileUploadResponse: any = await _UploadMedia(
        files[0] as File,
        false,
      );

      dispatch(
        uploadClassSlip(fileUploadResponse.fileUploadResponse, sId, true),
      );
    }
    e.target.value = "";
  };

  const addSignature = (values: any) => {
    dispatch(uploadSignatureInClass(studentId, values, true));
  };
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage id="classes.My" defaultMessage="My Class " />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}
          <div className="card">
            <div className="card-body p-0">
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>
              <div>
                <input
                  type="file"
                  ref={attchRef}
                  accept="image/png,image/jpg,image/jpeg"
                  style={{ display: "none" }}
                  onChangeCapture={(e: any) => handleDrop(e)}
                />
              </div>
              <div className="table-responsive">
                {lstClasses.length ? (
                  <>
                    {" "}
                    <table className="table table-hover table-striped m-0">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage
                              id="classes.My.Upload"
                              defaultMessage="Upload Slip "
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="classes.My.Signature"
                              defaultMessage="Signature"
                            />
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            {" "}
                            <FormattedMessage
                              id="classes.My.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("category")}
                          >
                            {" "}
                            <FormattedMessage
                              id="classes.MyCate"
                              defaultMessage="Category"
                            />
                            {sortBy === "category" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("type")}
                          >
                            {" "}
                            <FormattedMessage
                              id="classes.My.Type"
                              defaultMessage="Type"
                            />
                            {sortBy === "type" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("status")}
                          >
                            {" "}
                            <FormattedMessage
                              id="classes.My.Status"
                              defaultMessage="Status"
                            />
                            {sortBy === "status" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lstClasses.length &&
                          lstClasses.map((i: any) => (
                            <tr>
                              <td className="nowrap">
                                {i.status === "approved" ||
                                i.class.status === "close" ||
                                isUserExpired ? (
                                  <Button disabled className="p-0">
                                    {" "}
                                    <FormattedMessage
                                      id="classes.My.Slip"
                                      defaultMessage="Slip"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ color: "rgb(0 0 0 / 70%)" }}
                                    onClick={() => attchmentClick(i.id)}
                                    className="p-0"
                                  >
                                    {" "}
                                    <FormattedMessage
                                      id="classes.My.Slip"
                                      defaultMessage="Slip"
                                    />
                                  </Button>
                                )}
                              </td>
                              <td>
                                <Button
                                  disabled={
                                    i.status === "approved" ||
                                    i.class.status === "close" ||
                                    isUserExpired
                                  }
                                  onClick={() => {
                                    setSignaturePath(i?.signaturePath);
                                    setClassID(i?.class?.id);
                                    setModal(true);
                                    setStudentId(i?.id);
                                  }}
                                >
                                  <AutoGraphIcon />
                                </Button>
                              </td>
                              <td>{i?.class?.name}</td>
                              <td>{i.class.classCategory.name}</td>
                              <td>
                                {i?.role?.roleName === "Student" && (
                                  <FormattedMessage
                                    id={"Student"}
                                    defaultMessage="Student"
                                  />
                                )}
                                {i?.role?.roleName === "Repeater" && (
                                  <FormattedMessage
                                    id={"Repeater"}
                                    defaultMessage="Repeater"
                                  />
                                )}
                                {i?.role?.roleName === "Coacher" && (
                                  <FormattedMessage
                                    id={"Coacher"}
                                    defaultMessage="Coacher"
                                  />
                                )}
                                {i?.role?.roleName === "WALK-IN" && (
                                  <FormattedMessage
                                    id={"WALK-IN"}
                                    defaultMessage="WALK-IN"
                                  />
                                )}
                                {i?.role?.roleName === "Referral" && (
                                  <FormattedMessage
                                    id={"Referral"}
                                    defaultMessage="Referral"
                                  />
                                )}
                                {i?.role?.roleName === "Customer" && (
                                  <FormattedMessage
                                    id={"Customer"}
                                    defaultMessage="Customer"
                                  />
                                )}
                                {i?.role?.roleName === "Special-Offer" && (
                                  <FormattedMessage
                                    id={"Special-Offer"}
                                    defaultMessage="Special Offer"
                                  />
                                )}
                              </td>
                              <td>
                                {i.status === "pending" && (
                                  <span style={{ fontWeight: "500" }}>
                                    <FormattedMessage
                                      id={"pending"}
                                      defaultMessage="Pending"
                                    />
                                  </span>
                                )}
                                {i.status === "approved" && (
                                  <span
                                    style={{
                                      color: "#00a000",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <FormattedMessage
                                      id={"approved"}
                                      defaultMessage="Approve"
                                    />
                                  </span>
                                )}
                                {i.status === "rejected" && (
                                  <span
                                    style={{
                                      color: "#fa9090",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <FormattedMessage
                                      id={"rejected"}
                                      defaultMessage="Rejected"
                                    />
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {lstClasses.length && totalRecord > 10 ? (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div>
                    <Pagination
                      itemsPerPage={pageLimit}
                      totalPages={totalRecord}
                      onPageChage={onPageChange}
                      page={pageNumber - 1}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {modal && (
            <UploadSignature
              onSignatureAdd={(values: any) => addSignature(values)}
              onCancel={() => setModal(false)}
              showModal={modal}
              signaturePath={signaturePath}
              classID={classID}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default MyClass;
