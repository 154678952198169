import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import { dispatch } from "../../redux";
import {
  getClassExpertAdvisorsList,
  getTicketToolList,
  getTicketToolVersionList,
} from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { RedeemPermanantEASchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../common/Loader";
import { TOOLS } from "../../util/constants/TabConstants";
import { FaTimesCircle } from "react-icons/fa";

const PermanantEARedeemModal = ({
  details,
  onClose,
  open,
  onRedeem,
  isLoading,
}: any) => {
  const tools = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;

  const toolVersionList = useSelector(
    (state: Store) => state.ticketReducer.toolVersionList,
  ) as any;

  const onSaveLoading = useSelector(
    (state: Store) => state.accountReducer.onSaveLoading,
  );

  const brokerList = useSelector(
    (state: Store) => state.accountReducer.brokerList,
  ) as Array<Object>;

  const EAList = useSelector(
    (state: Store) => state.expertAdvisorReducer.EAList,
  ) as Array<Object>;

  const classExpertAdvisors = useSelector(
    (state: Store) => state.expertAdvisorReducer.classExpertAdvisors,
  ) as Array<Object>;

  const myInfo = useSelector((state: Store) => state.userDataReducer.myInfo);

  const [toolList, setToolList] = useState([]);
  const [buttonClick, setbuttonClick] = useState(false);
  const [selectedEAType, setSelectedEAType] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [selectedEAVersion, setSelectedEAVersion] = useState(null);
  const [EAVersion, setEAVersion] = useState(null);

  const formik = useFormik({
    initialValues: {
      EA_version: 0, // [],
      EA: 0,
    },
    validationSchema: RedeemPermanantEASchema,
    onSubmit: (values) => {
      const ac = {
        ea: values.EA,
        eaVersion: values.EA_version,
      };
      // dispatch(addAccountList(true, ac));
      onRedeem(ac);
      setbuttonClick(true);
    },
  });

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      onClose();
      setbuttonClick(false);
    }
  }, [onSaveLoading]);

  useEffect(() => {
    if (toolVersionList?.length > 0) {
      setEAVersion(toolVersionList);
    }
  }, [toolVersionList]);

  useEffect(() => {
    if (EAVersion?.length > 0) {
      setSelectedEAVersion(EAVersion[0]);
      formik.setFieldValue("EA_version", EAVersion[0].id);
    }
  }, [EAVersion]);

  useEffect(() => {
    setEAVersion([]);
    setSelectedEAVersion(null);
    dispatch(getTicketToolList(false, 0));
  }, []);

  useEffect(() => {
    dispatch(getClassExpertAdvisorsList(false, {}));
  }, []);

  useEffect(() => {
    if (classExpertAdvisors?.length > 0) {
      setToolList(classExpertAdvisors);
    }
  }, [classExpertAdvisors]);

  function onToolChange(option: any) {
    setSelectedEAType(option);
    setSelectedEAVersion(null);
    dispatch(getTicketToolVersionList(false, parseInt(option.id), 0));
    formik.setFieldValue("EA", option.id);
  }

  const { errors, touched } = formik;

  return (
    <Modal centered size={"lg"} show={open} className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">
          {/* <FormattedMessage id={headText} defaultMessage="Add Account" />{" "} */}
          {details?.giftCategory?.name || "Gift"}
        </h4>
        <button className="close" onClick={onClose}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="form-group">
            <FormattedMessage
              id="accountmodal.Expert"
              defaultMessage=" Expert Advisor"
            />
            {console.log("errpr", errors)}
            <Select
              classNamePrefix="react-select-dropdown"
              value={selectedEAType}
              onBlur={() => formik.setFieldTouched("EA", true)}
              onChange={onToolChange}
              options={toolList}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              placeholder={useIntl().formatMessage({
                id: "select.ExpertAdvisor",
                defaultMessage: "Expert Advisor",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.EA && touched.EA && (
              <div className="error">
                <FormattedMessage
                  id={errors.EA.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onClose} type="button">
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          className="btn btn-primary"
          onClick={(values: any) => formik.handleSubmit(values)}
        >
          <FormattedMessage id="page.Submit" defaultMessage="Add" />
          <span style={{ flexDirection: "row", display: "inline-flex" }}>
            {isLoading && (
              <div className="ml-2">
                {" "}
                <Loader />
              </div>
            )}
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PermanantEARedeemModal;
