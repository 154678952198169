import { useEffect, useState, useRef } from "react";
import { Card, Button } from "react-bootstrap";
import { BsChevronRight, BsSortDown, BsSortUp } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { dispatch } from "../../redux";
import { getScriptList, getUnauthorizedScriptList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { DownloadsIcon } from "../../util/iconStyle";
import { FormattedDate, FormattedMessage } from "react-intl";
import {
  formatDateMM,
  saveFile,
  getFileName,
  sort,
  customFormatDatetimeWithLocale,
} from "../../util/utils";
import { ArrowDownward, ArrowUpward, Filter } from "@mui/icons-material";
import Script from "../../assets/img/Script.svg";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import { ProgressDownload } from "../../Components/common/ProgressDownload";
import {
  ToolFilter,
  ToolFilterFunction,
} from "../../Components/common/ToolFilter";

export const Scripts = () => {
  const navigate = useNavigate();
  const ScriptsList = useSelector(
    (state: Store) => state.scriptReducers.scriptList,
  ) as Array<Object>;
  const UnauthorizedScriptLst = useSelector(
    (state: Store) => state.scriptReducers.UnauthorizedScriptLst,
  ) as Array<Object>;
  const isLoading = useSelector(
    (state: Store) => state.scriptReducers.isLoading,
  ) as any;
  const availableScriptsIsLoading = useSelector(
    (state: Store) => state.scriptReducers.availableScriptsIsLoading,
  ) as any;
  const unavailableScriptsIsLoading = useSelector(
    (state: Store) => state.scriptReducers.unavailableScriptsIsLoading,
  ) as any;

  const [lstScripts, setLstScripts] = useState([]);
  const [lstunAuthScripts, setLstUnAuthScripts] = useState([]);
  const [Load, setLoad] = useState<any>([]);
  const btnRef = useRef<any>();

  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("DESC");

  const [unAusortBy, setunAuSortBy] = useState("updatedAt");
  const [unAusortOrder, setunAuSortOrder] = useState("DESC");

  // For the filter purpose
  const [toolFilterTypeState, setToolFilterTypeState] = useState<
    "name" | "description"
  >("name");
  const [filteringQuery, setFilteringQuery] = useState("");

  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";

  useEffect(() => {
    dispatch(getScriptList(false, { by: sortBy, order: sortOrder }));
    dispatch(
      getUnauthorizedScriptList(false, {
        by: unAusortBy,
        order: unAusortOrder,
      }),
    );
  }, []);

  useEffect(() => {
    const t: any = sort(lstScripts, sortBy, sortOrder.toLowerCase());
    const filteredScripts = ToolFilterFunction(
      toolFilterTypeState,
      t,
      filteringQuery,
    );

    setLstScripts(filteredScripts);
  }, [sortOrder, sortBy, filteringQuery, toolFilterTypeState, lstScripts]);

  useEffect(() => {
    const t: any = sort(
      UnauthorizedScriptLst,
      unAusortBy,
      unAusortOrder.toLowerCase(),
    );
    const filteredScripts = ToolFilterFunction(
      toolFilterTypeState,
      t,
      filteringQuery,
    );
    setLstUnAuthScripts(filteredScripts);
  }, [
    unAusortOrder,
    unAusortBy,
    filteringQuery,
    toolFilterTypeState,
    UnauthorizedScriptLst,
  ]);

  useEffect(() => {
    if (ScriptsList) {
      const lst: any = [];
      ScriptsList.map((ea: any) => {
        let classes = "";
        (ea?.classTools || []).map((c: any) => {
          classes = classes + ", " + c.class.name;
        });
        classes = classes.substring(1);
        lst.push({
          ...ea,
          classes: classes,
        });
      });
      setLstScripts(lst);
    }
  }, [ScriptsList]);

  useEffect(() => {
    if (UnauthorizedScriptLst) {
      const lst: any = [];
      UnauthorizedScriptLst.map((ea: any) => {
        let classes = "";
        (ea?.classTools || []).map((c: any) => {
          classes = classes + ", " + c.class.name;
        });
        classes = classes.substring(1);
        lst.push({
          ...ea,
          classes: classes,
        });
      });
      setLstUnAuthScripts(lst);
    }
  }, [UnauthorizedScriptLst]);

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const setunAuthSorting = (prop) => {
    if (unAusortBy === prop) {
      setunAuSortOrder(unAusortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setunAuSortBy(prop);
      setunAuSortOrder("ASC");
    }
  };

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      console.log("btnRef.current", btnRef);
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <section
          className="page-content container-fluid"
          id="authorized-scripts"
        >
          <ToolFilter
            onChangeToolFilterType={setToolFilterTypeState}
            onChangeFilteringQuery={setFilteringQuery}
          />
          <header className="page-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h1>
                  {" "}
                  <FormattedMessage
                    id="subHead.AvailableScript"
                    defaultMessage="Script"
                  />
                </h1>
              </div>
              <div className="ml-auto">
                <a href="#unauthorized-scripts">
                  <ArrowDownward />
                  <FormattedMessage
                    id="subHead.UnAuthorizedScript"
                    defaultMessage="UnAuthorized Script"
                  />
                </a>
              </div>
            </div>
          </header>

          {(availableScriptsIsLoading || unavailableScriptsIsLoading) && (
            <CenterLoader />
          )}
          {lstScripts.length ? (
            <Card>
              <Card.Body>
                <div className="table-responsive">
                  <table className="table table-hover table-striped m-0">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="table.head.image"
                            defaultMessage="Image"
                          />{" "}
                          #
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("name")}
                        >
                          <FormattedMessage
                            id="table.head.Name"
                            defaultMessage="Name"
                          />
                          {sortBy === "name" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("info")}
                        >
                          <FormattedMessage
                            id="table.head.Description"
                            defaultMessage="Description"
                          />
                          {sortBy === "info" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("sellingPrice")}
                        >
                          <FormattedMessage
                            id="table.head.Price"
                            defaultMessage="Price"
                          />
                          {sortBy === "sellingPrice" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("updatedAt")}
                        >
                          <FormattedMessage
                            id="table.head.LastUpdated"
                            defaultMessage="Last Update"
                          />
                          {sortBy === "updatedAt" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th>
                          <FormattedMessage
                            id="table.head.Details"
                            defaultMessage="Details"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="table.head.Download"
                            defaultMessage="Download"
                          />
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {lstScripts.length &&
                        lstScripts.map((i: any, index: any) => (
                          <tr key={i.id} style={{ lineHeight: "1.5" }}>
                            <td style={{ width: "6%" }}>
                              <div
                                style={{
                                  height: "55px",
                                  width: "55px",
                                  borderRight: "1px solid #cccccc",
                                }}
                              >
                                <img
                                  style={{
                                    height: "55px",
                                    width: "55px",
                                    borderRadius: "3px",
                                    marginLeft: "-9px",
                                  }}
                                  src={i.image_path || Script}
                                />
                              </div>
                            </td>
                            <td style={{ width: "20%" }}>
                              <p style={{ lineHeight: "1.5" }}>
                                {" "}
                                {i.name}
                                <p style={{ lineHeight: "1.5" }}>
                                  <small> {i.classes}</small>{" "}
                                </p>
                              </p>
                            </td>
                            <td style={{ width: "45%" }}>{i.info}</td>
                            <td style={{ width: "15%" }}>
                              {i?.sellingPrice !== undefined && (
                                <p style={{ lineHeight: "1.5" }}>
                                  {" "}
                                  USD$ {i.sellingPrice}
                                  <p style={{ lineHeight: "1.5" }}>
                                    {" "}
                                    <small>After discount</small>
                                  </p>{" "}
                                </p>
                              )}
                            </td>
                            <td style={{ width: "45%", fontSize: 12 }}>
                              {customFormatDatetimeWithLocale(
                                i.updatedAt,
                                locale,
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() =>
                                  navigate(`/scripts/${i.id}`, {
                                    state: { isGranted: i.isGranted },
                                  })
                                }
                              >
                                <FormattedMessage
                                  id="expert.Details"
                                  defaultMessage="Details"
                                />

                                <BsChevronRight />
                              </Button>
                            </td>
                            <td>
                              {i?.ScriptVersionList?.[0]?.download_path ? (
                                <>
                                  <ProgressDownload
                                    setLoad={setLoad}
                                    Load={Load}
                                    elId={`p${index}1`}
                                    ref={btnRef}
                                  >
                                    <Button
                                      id={`p${index}`}
                                      onClick={() =>
                                        onDonwloadButtonClick(
                                          `p${index}`,
                                          i?.ScriptVersionList?.[0]
                                            ?.download_path,
                                        )
                                      }
                                    >
                                      <DownloadsIcon />
                                    </Button>
                                  </ProgressDownload>
                                </>
                              ) : (
                                <Button
                                  onClick={() => toast.warn("No file found")}
                                >
                                  <DownloadsIcon />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          ) : null}
        </section>

        <section
          className="page-content container-fluid"
          id="unauthorized-scripts"
        >
          <header className="page-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h1>
                  {" "}
                  <FormattedMessage
                    id="subHead.UnAuthorizedScript"
                    defaultMessage="Script"
                  />
                </h1>
              </div>
              <div className="ml-auto">
                <a href="#authorized-scripts">
                  <ArrowUpward />
                  <FormattedMessage
                    id="subHead.AvailableScript"
                    defaultMessage="Available Scripts"
                  />
                </a>
              </div>
            </div>
          </header>

          {isLoading && <CenterLoader />}
          {lstunAuthScripts.length ? (
            <Card>
              <Card.Body>
                <div className="table-responsive">
                  <table className="table table-hover table-striped m-0">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="table.head.image"
                            defaultMessage="Image"
                          />{" "}
                          #
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setunAuthSorting("name")}
                        >
                          <FormattedMessage
                            id="table.head.Name"
                            defaultMessage="Name"
                          />
                          {unAusortBy === "name" ? (
                            unAusortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setunAuthSorting("info")}
                        >
                          <FormattedMessage
                            id="table.head.Description"
                            defaultMessage="Description"
                          />
                          {unAusortBy === "info" ? (
                            unAusortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setunAuthSorting("sellingPrice")}
                        >
                          <FormattedMessage
                            id="table.head.Price"
                            defaultMessage="Price"
                          />
                          {unAusortBy === "sellingPrice" ? (
                            unAusortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setunAuthSorting("updatedAt")}
                        >
                          <FormattedMessage
                            id="table.head.LastUpdated"
                            defaultMessage="Last Update"
                          />
                          {unAusortBy === "updatedAt" ? (
                            unAusortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th>
                          <FormattedMessage
                            id="table.head.Details"
                            defaultMessage="Details"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="table.head.Download"
                            defaultMessage="Download"
                          />
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {lstunAuthScripts.length &&
                        lstunAuthScripts.map((i: any) => (
                          <tr key={i.id} style={{ lineHeight: "1.5" }}>
                            <td style={{ width: "6%" }}>
                              <div
                                style={{
                                  height: "55px",
                                  width: "55px",
                                  borderRight: "1px solid #cccccc",
                                }}
                              >
                                <img
                                  style={{
                                    height: "55px",
                                    width: "55px",
                                    borderRadius: "3px",
                                    marginLeft: "-9px",
                                  }}
                                  src={i.image_path || Script}
                                />
                              </div>
                            </td>
                            <td style={{ width: "20%" }}>
                              <p style={{ lineHeight: "1.5" }}> {i.name}</p>
                            </td>
                            <td style={{ width: "45%" }}>{i.info}</td>
                            <td style={{ width: "15%" }}>
                              {i?.sellingPrice !== undefined && (
                                <p style={{ lineHeight: "1.5" }}>
                                  {" "}
                                  USD$ {i.sellingPrice}
                                  <p style={{ lineHeight: "1.5" }}>
                                    {" "}
                                    <small>After discount</small>
                                  </p>{" "}
                                </p>
                              )}
                            </td>
                            <td style={{ width: "45%", fontSize: 12 }}>
                              {customFormatDatetimeWithLocale(
                                i.updatedAt,
                                locale,
                              )}
                            </td>
                            <td>
                              <Button
                                style={{
                                  backgroundColor: "#68439a",
                                  borderColor: "#68439a",
                                }}
                                disabled
                              >
                                <FormattedMessage
                                  id="expert.Details"
                                  defaultMessage="Details"
                                />
                                <BsChevronRight />
                              </Button>
                            </td>
                            <td>
                              <Button
                                style={{
                                  backgroundColor: "#68439a",
                                  borderColor: "#68439a",
                                }}
                                disabled
                              >
                                {" "}
                                <DownloadsIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          ) : null}
        </section>
      </div>
    </div>
  );
};

export default Scripts;
