import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/img/gemsFX_normal.svg";
import { forgotPasswordSchema1 } from "../util/validationSchema";
import { fpEmail } from "../redux/action";
import { Store } from "../redux/Actions";
import { FormattedMessage, useIntl } from "react-intl";
import { Translation } from "../Components/common/Translation";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonClick, setButtonClick] = useState(false);

  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );

  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const isFPEmail = useSelector(
    (state: Store) => state.userDataReducer.isFPEmail,
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema1,
    onSubmit: (values) => {
      dispatch(fpEmail(values.email));
      setButtonClick(true);
    },
  });

  const { touched, errors } = formik;
  useEffect(() => {
    if (isFPEmail && buttonClick) {
      navigate("/login");
    }
  }, [isFPEmail]);

  useEffect(() => {
    if (isLoggedin) {
      navigate("/home");
    }
  }, [isLoggedin]);

  return (
    <React.Fragment>
      <div className="main-container d-flex align-items-center justify-content-center bg-login-image">
        <div className="login-box common-box">
          <Translation />
          <div className="login-logo">
            <img style={{ height: "50px" }} src={Logo} alt="Logo" />
          </div>
          <div className="login-box-body">
            <h1 className="text-center mb-3 font-weight-500">
              <FormattedMessage
                id="head.RecoverPassword"
                defaultMessage="Recover Password"
              />
            </h1>
            <p className="text-center mb-4">
              <FormattedMessage
                id="head.RegisteredEmail"
                defaultMessage="Enter your registered email"
              />
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder={useIntl().formatMessage({
                    id: "login.Email",
                    defaultMessage: "Email Id",
                  })}
                />
                {errors.email && touched.email && (
                  <div className="text-error">
                    <FormattedMessage id={errors.email.toString()} />
                  </div>
                )}
              </div>
              {}
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-primary btn-block btn-lg"
              >
                {!isLoading ? (
                  <FormattedMessage
                    id="head.sendEmail"
                    defaultMessage="Send Email"
                  />
                ) : (
                  <span
                    className="login-button--loading"
                    style={{ position: "relative", left: -10 }}
                  ></span>
                )}
              </button>
              <div className="d-flex align-items-center justify-content-between m-t-20">
                <div className="password-reset-link">
                  <Link className="text-accent-custom font-size-14" to="/login">
                    {" "}
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="login-box-footer border-top">
            <div className="password-reset-link text-center">
              <FormattedMessage
                id="dont.Account"
                defaultMessage="Don't have an account"
              />{" "}
              ?
              <Link className="text-accent-custom" to="/register">
                {" "}
                <FormattedMessage
                  id="login.Register"
                  defaultMessage="Register here"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
