import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Store } from "../redux/Actions";

const ProtectedToolsRoute: React.FC = ({ children }) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [_, toolType] = pathname.split("/");

  const authorizedExpertAdvisors = useSelector(
    (state: Store) => state.expertAdvisorReducer.EAList,
  ) as Array<any>;
  const authorizedIndicators = useSelector(
    (state: Store) => state.indicatorsReducer.indicatorList,
  ) as Array<any>;
  const authorizedUtilities = useSelector(
    (state: Store) => state.utilityReducers.utilityList,
  ) as Array<any>;
  const authorizedScripts = useSelector(
    (state: Store) => state.scriptReducers.scriptList,
  ) as Array<any>;
  const authorizedLibraries = useSelector(
    (state: Store) => state.libararyReducers.libraryList,
  ) as Array<any>;

  let authorizedTools = [];

  switch (toolType) {
    case "expert-advisors":
      authorizedTools = authorizedExpertAdvisors;
      break;

    case "indicators":
      authorizedTools = authorizedIndicators;
      break;

    case "utilities":
      authorizedTools = authorizedUtilities;
      break;

    case "scripts":
      authorizedTools = authorizedScripts;
      break;

    case "libraries":
      authorizedTools = authorizedLibraries;
      break;

    default:
      break;
  }

  // Redirect to tool list if the target tool id is not found in the authorized tool list
  if (authorizedTools.findIndex((tool) => tool.id === Number(id)) === -1) {
    return <Navigate to={`/${toolType}`} />;
  }

  return <>{children}</>;
};

export default ProtectedToolsRoute;
