import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import {
  getIndustryTypeList,
  getJobTitleList,
  getRegistrationMaterial,
  setRegisterSteps,
} from "../../redux/action";
import { getRegisterStep3Schema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./register.css";
import { CURTSYTITLE } from "../../Static/RegisterConstant";

import { Store } from "../../redux/Actions";

export interface IDictonary {
  [index: string]: Array<object>;
}

const RegisterStep2 = ({ onBack }: any) => {
  const dispatch = useDispatch();
  const [industry, setIndustry] = useState<any>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<any>([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState<any>(null);
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [validationSchema, setValidationSchema] = useState<any>();

  const users = useSelector((state: Store) => state.userDataReducer.userObject);

  const industryLst = useSelector(
    (state: Store) => state.registerReducer.industry,
  ) as Array<Object>;
  const jobTitles = useSelector(
    (state: Store) => state.registerReducer.jobTitles,
  ) as Array<Object>;
  const local = useSelector((state: Store) => state.userDataReducer.local);
  const districtLst: any = useSelector<any>(
    (state: Store) => state.registerReducer.districtLst || {},
  );

  useEffect(() => {
    const vs = getRegisterStep3Schema(11);
    setValidationSchema(vs);
  }, []);

  useEffect(() => {
    dispatch(getIndustryTypeList(false, local));
    dispatch(getRegistrationMaterial(false, local));
    setSelectedIndustry(null);
    setSelectedDistrict(null);
    setSelectedJobTitle(null);
    formik.setFieldValue("industry", "");
    formik.setFieldValue("jobTitle", "");
    formik.setFieldValue("district", "");
    formik.setFieldTouched("industry", false);
    formik.setFieldTouched("jobTitle", false);
    formik.setFieldTouched("district", false);
  }, [local]);

  useEffect(() => {
    if (industryLst) {
      setIndustry(industryLst);
    }
  }, [industryLst]);

  const formik = useFormik({
    initialValues: {
      title: users.title || "",
      chineseLastName: users.chineseLastName || "",
      chineseFirstName: users.chineseFirstName || "",
      englishFirstName: users.englishFirstName || "",
      englishLastName: users.englishLastName || "",
      preferredNickName: users.preferredNickName || "",
      industry: users.industry || "",
      jobTitle: users.jobTitle || "",
      DOB: users.DOB || "",
      phoneNumber: users.phoneNumber || "",
      countryCode: users.countryCode || "",
      dialCode: users.dialCode || "",
      address: users.address || "",
      address2: users.address2 || "",
      district: users.district || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setRegisterSteps(2, values));
    },
  });

  useEffect(() => {
    const t = [
      { label: "Mr.", value: CURTSYTITLE.MR },
      { label: "Mrs.", value: CURTSYTITLE.MRS },
      { label: "Miss", value: CURTSYTITLE.MS },
    ];

    t.map((i) => {
      if (i.value === users.title) {
        setSelectedTitle(i);
      }
    });

    if (users.industry) {
      formik.setFieldValue("industry", users.industry);
    }
    if (users.jobTitle) {
      formik.setFieldValue("jobTitle", users.jobTitle);
    }

    if (users.district) {
      formik.setFieldValue("district", users.district);
    }
    industryLst.map((i: any) => {
      if (i.IndustryTypeId === users.industry) {
        setSelectedIndustry(i);
      }
    });

    if (users.phoneNumber) {
      const s = users.phoneNumber.length;
      const l = getRegisterStep3Schema(s);
      setValidationSchema(l);
    }
    jobTitles.map((i: any) => {
      if (i.JobTitleId === users.jobTitle) {
        setSelectedJobTitle(i);
      }
    });

    districtLst.map((i: any) => {
      if (i.districtId === users.district) {
        setSelectedDistrict(i);
      }
    });
  }, [users]);

  useEffect(() => {
    if (selectedIndustry && selectedIndustry.IndustryTypeId > 0) {
      dispatch(getJobTitleList(false, local, selectedIndustry.IndustryTypeId));
    }
  }, [selectedIndustry, local]);

  const onIndustryChange = (selectedOption: any) => {
    formik.setFieldValue("industry", selectedOption?.IndustryTypeId);
    setSelectedIndustry(() => ({ ...selectedOption }));
    setSelectedJobTitle(null);
  };

  const handleOnChange = async (value, data, event, formattedValue) => {
    const dialCode = data.dialCode;
    let s = data?.format?.match(/\./g).length || 11;
    if (s > 15) {
      s = 15;
    }
    const v = getRegisterStep3Schema(s);
    await setValidationSchema(v);

    formik.setFieldValue("phoneNumber", value);
    formik.setFieldValue("dialCode", dialCode);
    formik.setFieldValue("countryCode", data.countryCode);
  };

  const { touched, errors, values } = formik;

  return (
    <>
      <div className="mb-4">
        <div className="d-flex flex-wrap justify-content-between border-bottom pb-4 mb-4">
          <h1 className="h5 font-weight-600 m-0">
            <FormattedMessage
              id="register.setp3"
              defaultMessage="Enter Personal Information"
            />{" "}
          </h1>
          <button
            type="button"
            onClick={onBack}
            className="btn btn-primary btn btn-primary"
          >
            <BsChevronLeft />{" "}
            <FormattedMessage id="register.step3.Back" defaultMessage="" />{" "}
          </button>
        </div>
        <Row className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step3.ChineseLName"
                  defaultMessage="Chinese Last Name "
                />
              </label>
              <input
                type="text"
                name="chineseLastName"
                value={values.chineseLastName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
                placeholder={useIntl().formatMessage({
                  id: "register.step3.ChineseLName",
                  defaultMessage: "Chinese Last Name",
                })}
              />
              {errors.chineseLastName && touched.chineseLastName && (
                <div className="error">
                  <FormattedMessage
                    id={errors.chineseLastName.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step3.ChineseFName"
                  defaultMessage="Chinese First Name "
                />
              </label>
              <input
                type="text"
                name="chineseFirstName"
                value={values.chineseFirstName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
                placeholder={useIntl().formatMessage({
                  id: "register.step3.ChineseFName",
                  defaultMessage: "Chinese First Name",
                })}
              />
              {errors.chineseFirstName && touched.chineseFirstName && (
                <div className="error">
                  <FormattedMessage
                    id={errors.chineseFirstName.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.EnglishLName"
                  defaultMessage="English Last Name"
                />
              </label>
              <input
                type="text"
                name="englishLastName"
                value={values.englishLastName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
                placeholder={useIntl().formatMessage({
                  id: "register.step3.EnglishLName",
                  defaultMessage: "English Last Name",
                })}
              />
              {errors.englishLastName && touched.englishLastName && (
                <div className="error">
                  <FormattedMessage
                    id={errors.englishLastName.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step3.EnglishFName"
                  defaultMessage="English First Name  "
                />
              </label>
              <input
                type="text"
                name="englishFirstName"
                value={values.englishFirstName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
                placeholder={useIntl().formatMessage({
                  id: "register.step3.EnglishFName",
                  defaultMessage: "English First Name",
                })}
              />
              {errors.englishFirstName && touched.englishFirstName && (
                <div className="error">
                  <FormattedMessage
                    id={errors.englishFirstName.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step3.PreferName"
                  defaultMessage="Nickname"
                />{" "}
              </label>
              <input
                type="text"
                name="preferredNickName"
                onBlur={formik.handleBlur}
                value={values.preferredNickName}
                onChange={formik.handleChange}
                className="form-control"
                placeholder={useIntl().formatMessage({
                  id: "register.step3.preferredNickName",
                  defaultMessage: "Preferred Nick Name",
                })}
              />
              {errors.preferredNickName && touched.preferredNickName && (
                <div className="error">
                  <FormattedMessage
                    id={errors.preferredNickName.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.Title"
                  defaultMessage="Title"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedTitle}
                options={[
                  { label: "Mr.", value: CURTSYTITLE.MR },
                  { label: "Mrs.", value: CURTSYTITLE.MRS },
                  { label: "Miss", value: CURTSYTITLE.MS },
                ]}
                onBlur={() => formik.setFieldTouched("title", true)}
                onChange={(option: any) => {
                  setSelectedTitle(option);
                  formik.setFieldValue("title", option.value);
                }}
                placeholder={useIntl().formatMessage({
                  id: "register.step3.title",
                  defaultMessage: "Select Title",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />{" "}
              {errors.title && touched.title && (
                <div className="error">
                  <FormattedMessage
                    id={errors.title.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.DOB"
                  defaultMessage="DOB"
                />{" "}
              </label>
              <input
                type="date"
                name="DOB"
                max={new Date().toISOString().split("T")[0]}
                value={values.DOB}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              />
              {errors.DOB && touched.DOB && (
                <div className="error">
                  <FormattedMessage
                    id={errors.DOB.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row sm={1} md={2} lg={2} className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.Industry"
                  defaultMessage="Industry"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedIndustry}
                onBlur={() => formik.setFieldTouched("industry", true)}
                onChange={onIndustryChange}
                options={industry}
                name="industry"
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.IndustryTypeId}
                placeholder={useIntl().formatMessage({
                  id: "register.step3.industry",
                  defaultMessage: "Select Industry",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.industry && touched.industry && (
                <div className="error">
                  <FormattedMessage
                    id={errors.industry.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.Job"
                  defaultMessage="Job"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedJobTitle}
                onChange={(selectedOption: any) => {
                  formik.setFieldTouched("jobTitle", true);
                  setSelectedJobTitle(selectedOption);
                  formik.setFieldValue("jobTitle", selectedOption.JobTitleId);
                }}
                options={jobTitles}
                name="jobTitle"
                getOptionValue={(option: any) => option.JobTitleId}
                getOptionLabel={(option: any) => option.name}
                placeholder={useIntl().formatMessage({
                  id: "register.step3.jobTitle",
                  defaultMessage: "Select Job Title",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />

              {errors.jobTitle && touched.jobTitle && (
                <div className="error">
                  <FormattedMessage
                    id={errors.jobTitle.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row sm={1} md={2} lg={2} className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.Phonenumber"
                  defaultMessage="Phone Number"
                />{" "}
              </label>
              <ReactPhoneInput
                country={"hk"}
                value={values.phoneNumber}
                onChange={handleOnChange}
                onBlur={(e: any) => formik.handleBlur(e)}
                inputProps={{ name: "phoneNumber" }}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <div className="error">
                  <FormattedMessage
                    id={errors.phoneNumber.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step3.District"
                  defaultMessage="Home Address"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedDistrict}
                options={districtLst}
                onBlur={() => formik.setFieldTouched("district", true)}
                onChange={(option: any) => {
                  setSelectedDistrict(option);
                  formik.setFieldValue("district", option.districtId);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.districtId}
                placeholder={useIntl().formatMessage({
                  id: "register.step3.SelectDistrict",
                  defaultMessage: "Select district",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />{" "}
              {errors.district && touched.district && (
                <div className="error">
                  <FormattedMessage
                    id={errors.district.toString()}
                    defaultMessage="error"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="text-right mt-auto">
        <Button
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {" "}
          <FormattedMessage
            id="register.step3.Button"
            defaultMessage="Next"
          />{" "}
          <BsChevronRight />{" "}
        </Button>
      </div>
    </>
  );
};

export default RegisterStep2;
