import Select from "react-select";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { ToolTypeOption } from "../../util/constants/UserGroup";
import { dispatch } from "../../redux";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import {
  addTicketList,
  deleteMediaLocalInit,
  getTicketAssigneeList,
  getTicketIssueTypeList,
  getTicketToolList,
  getTicketToolVersionList,
  setMediaUploadEmpty,
} from "../../redux/action";
import { AddTicketSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import FileUploadImage from "../common/MediaUpload/FileUploadImage";
import { _UploadMediaMutiple } from "../../service/MediaUpload/classes.service";
import { BsTrash } from "react-icons/bs";
import { getFileName } from "../../util/utils";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import EditorContainer from "../common/RichTextEditor/Editor";
import { toast } from "react-toastify";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { useNavigate } from "react-router";
import { appIntl } from "../../util/intl";

export const TICKET_ATTACHMENT = "ticketAttachment";

const TicketModal = () => {
  const tools = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;
  const toolVersionList = useSelector(
    (state: Store) => state.ticketReducer.toolVersionList,
  ) as any;
  const issueList = useSelector(
    (state: Store) => state.ticketReducer.issueList,
  ) as any;
  const page = 1;
  const onSaveLoading = useSelector(
    (state: Store) => state.ticketReducer.onSaveLoading,
  ) as any;
  const [buttonClick, setbuttonClick] = useState(false);
  const ticketAttachment = useSelector(
    (state: Store) => state.mediaUploadReducer.ticketAttachment,
  );
  const uploadLoad = useSelector(
    (state: Store) => state.mediaUploadReducer.uploadLoad,
  );
  const ticketAssigneeList = useSelector(
    (state: Store) => state.ticketReducer.ticketAsignee,
  ) as any;

  const navigate = useNavigate();

  const [type, setType] = useState<any>(null);
  const [software, setSoftware] = useState(null);
  const [version, setVersion] = useState(null);
  const [group, setGroup] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [softwareVersion, setSoftwareVersion] = useState<any>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: "",
      title: "",
      software: 0,
      version: 0,
      isPublic: false,
      type: 0,
      attachmentPath: [],
      tool: 0,
      ticketgroup: "",
    },
    validationSchema: AddTicketSchema,
    onSubmit: (values) => {
      const imgs: any = values.attachmentPath.map((i: any) => i.location);
      const data: any = {
        ...values,
        attachmentPath: imgs,
      };
      dispatch(addTicketList(true, data, page));
      setbuttonClick(true);
    },
  });
  useEffect(() => {
    const id = formik.values.type;
    dispatch(getTicketToolList(false, id));
    dispatch(getTicketIssueTypeList());
    dispatch(getTicketAssigneeList(false));

    setSoftware(null);
    setVersion(null);
    setGroup(null);
  }, []);

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      // setTicketModal();
      setbuttonClick(false);
      resetForm();
      setSoftware(null);
      setType(null);
      setVersion(null);
      setGroup(null);
      dispatch(setMediaUploadEmpty(TICKET_ATTACHMENT));
      setEditorState(EditorState.createEmpty());
      navigate("/ticket");
    }
  }, [onSaveLoading]);

  useEffect(() => {
    formik.setFieldValue("attachmentPath", ticketAttachment);
  }, [ticketAttachment]);

  const onToolTypeChange = (option: any) => {
    formik.setFieldValue("tool", option.id);
    dispatch(getTicketToolList(false, parseInt(option.id)));
    setType(option);

    setSoftware(null);
    setVersion(null);
  };

  const onToolChange = async (option: any) => {
    await formik.setFieldValue("software", option.id);
    // await formik.setFieldValue("type", option.type);
    await formik.setFieldValue("tool", option.type);

    setSoftwareVersion([]);
    setSoftwareVersion(option.versionList);

    setSoftware(option);
    setVersion(null);
  };

  const onToolVersionChange = (option: any) => {
    formik.setFieldValue("version", option.id);
    setVersion(option);
  };

  const onTicketAssigneeChange = (option: any) => {
    formik.setFieldValue("ticketgroup", option.id);
    setGroup(option);
  };

  const handleDrop = async (acceptedFiles: any) => {
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 2097152) {
        const maxSizeInMB = (2097152 / 1024 / 1024).toString();
        toast.error(
          appIntl().formatMessage(
            { id: "errors.uploadFileSizeError" },
            { maxSizeInMB: maxSizeInMB },
          ),
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      await _UploadMediaMutiple(acceptedFiles as File[], TICKET_ATTACHMENT);
    }
  };

  const onDeleteImage = (key: any) => {
    dispatch(deleteMediaLocalInit("ticketAttachment", key));
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let text = eState.toString().replaceAll("&nbsp;", "");
    text = text.replaceAll("<p>", "");
    text = text.replaceAll("</p>", "");
    text = text.replaceAll("<br>", "");
    text = text.replaceAll("\n", "");
    text = text.trim();

    if (text.length > 0) {
      formik.setFieldValue(
        "content",
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
    } else {
      formik.setFieldValue("content", "");
    }
  };

  const { errors, touched, resetForm } = formik;

  return (
    // <Modal
    //   centered
    //   size={"lg"}
    //   show={ticketModal}
    //   className="modal-content-md"
    //   onHide={() => {
    //     setTicketModal(false);
    //     setSoftware(null);
    //     setVersion(null);
    //     setSoftwareVersion(null);
    //     formik.resetForm();
    //     setEditorState(EditorState.createEmpty());
    //   }}
    //   onBackdropClick={() => {
    //     return false;
    //   }}
    // >
    <form onSubmit={formik.handleSubmit}>
      {uploadLoad && <CenterLoader />}
      {/* <Modal.Header> */}
      {/* <h4 className="modal-title">
            {" "}
            <FormattedMessage
              id="ticketmodal.Ticket"
              defaultMessage="Add Ticket"
            />{" "}
          </h4> */}
      {/* <button
            type="button"
            className="close"
            onClick={() => setTicketModal(false)}
          >
            <span aria-hidden="true" className="zmdi zmdi-close"></span>
          </button> */}
      {/* </Modal.Header>
        <Modal.Body style={{ maxHeight: "81vh" }}> */}
      {/* <div className="form-group">
            <FormattedMessage
              id="ticketmodal.Tool"
              defaultMessage="Tool Type"
            />
            <Select
            classNamePrefix="react-select-dropdown"
              value={type}
              onChange={onToolTypeChange}
              options={ToolTypeOption}
              onBlur={() => formik.setFieldTouched("tool", true)}
              getOptionLabel={(option: any) => option.group}
              getOptionValue={(option: any) => option.id}
            />
            {errors.tool && touched.tool && (
              <div className="error">
                <FormattedMessage
                  id={errors.tool.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div> */}
      <Row>
        <Col lg={6} md={6} sm={12}>
          <div className="form-group">
            <FormattedMessage
              id="ticketmodal.Software"
              defaultMessage="Software"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              value={software}
              onBlur={() => formik.setFieldTouched("software", true)}
              onChange={onToolChange}
              options={tools}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              placeholder={useIntl().formatMessage({
                id: "select.Software",
                defaultMessage: "Select software",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.software && touched.software && (
              <div className="error">
                <FormattedMessage
                  id={errors.software.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <div className="form-group">
            <FormattedMessage
              id="ticketmodal.Version"
              defaultMessage="Version"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              value={version}
              onBlur={() => formik.setFieldTouched("version", true)}
              onChange={onToolVersionChange}
              options={softwareVersion}
              getOptionLabel={(option: any) => option.version}
              getOptionValue={(option: any) => option.id}
              placeholder={useIntl().formatMessage({
                id: "select.Version",
                defaultMessage: "Select version",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.version && touched.version && (
              <div className="error">
                <FormattedMessage
                  id={errors.version.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={12}>
          <div className="form-group">
            <FormattedMessage id="ticketmodal.Title" defaultMessage="Title" />
            <input
              type="text"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
              placeholder={useIntl().formatMessage({
                id: "select.Title",
                defaultMessage: "Title",
              })}
              value={formik.values.title}
            />
            {errors.title && touched.title && (
              <div className="error">
                <FormattedMessage
                  id={errors.title.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <div className="form-group">
            <FormattedMessage
              id="ticketmodal.Issue"
              defaultMessage="Issue Type"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              onChange={(option: any) => {
                formik.setFieldValue("type", option.id);
                setType(option);
              }}
              value={type}
              onBlur={() => formik.setFieldTouched("type", true)}
              options={issueList}
              placeholder={useIntl().formatMessage({
                id: "select.IssueType",
                defaultMessage: "Select issue type",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.type && touched.type && (
              <div className="error">
                <FormattedMessage
                  id={errors.type.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={12}>
          <div className="form-group">
            <FormattedMessage
              id="ticketmodal.ticketgroup"
              defaultMessage="Ticket Group"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              value={group}
              onBlur={() => formik.setFieldTouched("ticketgroup", true)}
              onChange={onTicketAssigneeChange}
              options={ticketAssigneeList}
              getOptionLabel={(option: any) => option.group_name}
              getOptionValue={(option: any) => option.id}
              placeholder={useIntl().formatMessage({
                id: "select.ticketgroup",
                defaultMessage: "Select Ticket Group",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.ticketgroup && touched.ticketgroup && (
              <div className="error">
                <FormattedMessage
                  id={errors.ticketgroup.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <div className="form-group">
        <FormattedMessage
          id="ticketmodal.Description"
          defaultMessage="Description"
        />

        <EditorContainer
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          toolbar={true}
          editable={true}
          height={"200px"}
          overflowY={"scroll"}
          border={"1px solid #dfe7f3"}
        />

        {errors.content && touched.content && (
          <div className="error">
            <FormattedMessage
              id={errors.content.toString()}
              defaultMessage="require"
            />{" "}
          </div>
        )}
      </div>

      <div className="form-group">
        <FormattedMessage
          id="ticketmodal.Attachment"
          defaultMessage="Attachment"
        />
        <FileUploadImage handleDrop={handleDrop} optional={true} accept="" />
        <div className="mt-2">
          {formik.values.attachmentPath.length > 0 &&
            formik.values.attachmentPath.map((i: any) => (
              <Row key={i.key} className="align-items-center mt-1">
                <Col className="col-md-9">
                  <p>{getFileName(i.location)} </p>
                </Col>
                <Col className="col-md-3 text-right">
                  <button
                    type="button"
                    className="border-delete-icon delete"
                    onClick={() => {
                      onDeleteImage(i.key);
                    }}
                  >
                    <BsTrash />
                  </button>
                </Col>
              </Row>
            ))}
        </div>
      </div>
      {/* </Modal.Body>
        <Modal.Footer> */}

      <Row className="mt-2">
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "inherit",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <button
              className="btn btn-secondary"
              onClick={() => {
                // setTicketModal();
                resetForm();
                setSoftware(null);
                setType(null);
                setVersion(null);
                setGroup(null);
                dispatch(setMediaUploadEmpty(TICKET_ATTACHMENT));
                setEditorState(EditorState.createEmpty());
              }}
              type="button"
            >
              {" "}
              <FormattedMessage id="page.Reset" defaultMessage="Reset" />
            </button>
            <button className="btn btn-primary" type="submit">
              {" "}
              <FormattedMessage id="page.Add" defaultMessage="Add" />
            </button>
          </div>
        </Col>
      </Row>
      {/* </Modal.Footer> */}
    </form>
    // </Modal>
  );
};

export default TicketModal;
