import React from "react";
import { FormattedMessage } from "react-intl";
const TermsAndConditions = ({ checkboxChange, isCheckbox = true }: any) => {
  return (
    <div
      className="terms-and-conditions"
      style={{
        backgroundColor: "transparent",
        borderLeft: "2px solid #68439a",
        width: "100%",
        padding: "3px",
      }}
    >
      {isCheckbox == true ? (
        <h3>
          {" "}
          <FormattedMessage
            id="register.term"
            defaultMessage="Please scroll down and read all of the following terms and conditions agreement, and click on the agree checkbox if you agree to bound by all of the terms and conditions of this agreement."
          />{" "}
        </h3>
      ) : null}

      <div className="term-and-conditions-body">
        <p>
          <FormattedMessage id="terms.and.condition.privacy" />
        </p>
        <ol>
          <li>
            <FormattedMessage id="terms.and.condition.privacy.1" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.1.1a" />
                <a href="mailto:info@forexforest.com">info@forexforest.com</a>
                <FormattedMessage id="terms.and.condition.privacy.1.1b" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.1.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.1.3" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.2" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.2.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.2.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.2.3" />
                <ol>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.1" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.2" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.3" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.4" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.5" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.6" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.7" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.8" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.9" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.10" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.2.3.11" />
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.3" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.3.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.3.2" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.4" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.4.1" />
                <ol>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.4.1.1" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.4.1.2" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.4.1.3" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.4.1.4" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.4.1.5" />
                  </li>
                </ol>
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.4.2" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.5" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.5.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.6" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.6.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.6.2" />
                <ol>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.1" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.2" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.3" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.4" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.5" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.6" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.privacy.6.2.7" />
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.7" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.7.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.8" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.8.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.9" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.9.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.10" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.10.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.10.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.10.3" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.11" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.11.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.privacy.12" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.12.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.12.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.12.3" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.privacy.12.4" />
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.1" />
        </p>
        <br />
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.2" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.3" />
        </p>
        <br />
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.4" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.5" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.6" />
        </p>
        <br />
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.7" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.8" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.9" />
        </p>
        <br />
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.10" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.11" />
        </p>
        <br />
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.12" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.13" />
        </p>
        <p>
          <FormattedMessage id="terms.and.condition.privacy.13.14" />
        </p>
      </div>
      <div className="term-and-conditions-body">
        <p>
          <FormattedMessage id="termsAndConditions.website" />
        </p>
        <ol>
          <li>
            <FormattedMessage id="termsAndConditions.website.1" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.1.1" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.1.2" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.1.3" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.1.4" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="termsAndConditions.website.2" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.2.1" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.2.2" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.2.3" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.2.4" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.2.5" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.2.6" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="termsAndConditions.website.3" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.3.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="termsAndConditions.website.4" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.1" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.2" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.3" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.4" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.5" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.6" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.4.7" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="termsAndConditions.website.5" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.5.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="termsAndConditions.website.6" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.6.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="termsAndConditions.website.7" />
            <ol>
              <li>
                <FormattedMessage id="termsAndConditions.website.7.1" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.7.2" />
              </li>
              <li>
                <FormattedMessage id="termsAndConditions.website.7.3" />
                <ol>
                  <li>
                    <FormattedMessage id="termsAndConditions.website.7.3.1" />
                  </li>
                  <li>
                    <FormattedMessage id="termsAndConditions.website.7.3.2" />
                  </li>
                  <li>
                    <FormattedMessage id="termsAndConditions.website.7.3.3" />
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="term-and-conditions-body">
        <p>
          <FormattedMessage id="terms.and.condition.EULA" />
        </p>
        <ol>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.1" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.1.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.EULA.2" />
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.EULA.3" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.3.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.3.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.3.3" />
                <br />
                <p>
                  <FormattedMessage id="terms.and.condition.EULA.3.3a" />
                </p>
                <ol className="roman-order-list">
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3b" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3c" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3d" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3e" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3f" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3g" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3h" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.3.3i" />
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.EULA.4" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.4.1" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="terms.and.condition.EULA.5" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.5.1" />
                <br />
                <p>
                  <FormattedMessage id="terms.and.condition.EULA.5.1a" />
                </p>
                <ol className="roman-order-list">
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.5.1b" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.5.1c" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.5.1d" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.and.condition.EULA.5.1e" />
                  </li>
                </ol>
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.5.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.5.3" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.5.4" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.5.5" />
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.6" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.6.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.6.2" />
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.7" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.8" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.9" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.10" />
            <ol>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.10.1" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.10.2" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.10.3" />
              </li>
              <li>
                <FormattedMessage id="terms.and.condition.EULA.10.4" />
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.11" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.12" />
          </li>
        </ol>
        <p>
          <FormattedMessage id="terms.and.condition.EULA.13.1" />
          <a href="mailto:info@forexforest.com">info@forexforest.com</a>
        </p>
        <br />
        <p>
          <FormattedMessage id="terms.and.condition.EULA.13.2" />
        </p>
        <ol>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.13.3.1" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.13.3.2" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.13.3.3" />
          </li>
          <li>
            <FormattedMessage id="terms.and.condition.EULA.13.3.4" />
          </li>
        </ol>
        <p>
          <FormattedMessage id="terms.and.condition.EULA.13.4" />
          <a href="mailto:info@forexforest.com">info@forexforest.com</a>
        </p>
      </div>

      {isCheckbox == true ? (
        <div className="form-group m-0 mt-2">
          <label
            className="control control-outline control-primary control--checkbox m-0"
            htmlFor="as"
          >
            <FormattedMessage id="Iagreewithtermsandcondition" />
            <input
              type="checkbox"
              onChange={(e) => checkboxChange(e)}
              id="as"
            />
            <div className="control__indicator"></div>
          </label>
        </div>
      ) : null}
    </div>
  );
};

export default TermsAndConditions;
