import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import SignatureCanvas from "react-signature-canvas";
import { useFormik } from "formik";
import FileUploadImage from "../../Components/common/MediaUpload/FileUploadImage";
import { RegisterStep5Schema } from "../../util/validationSchema";
import { setRegisterSteps } from "../../redux/action";
import { dispatch } from "../../redux";
import { _RegisterUploadMedia } from "../../service/MediaUpload/classes.service";
import { Store } from "../../redux/Actions";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FaTrash } from "react-icons/fa";
const RegisterStep5 = ({ onBack }: any) => {
  let signatureRef: any;
  const signatureCanvasParentRef = useRef<HTMLDivElement>(null);

  const [SelectedFiles, setSelectedFiles] = useState<Blob>();
  const [SelectedFilesSign, setSelectedFilesSign] = useState<any>();
  const [canvasVisibility, setCanvasVisibility] = useState<any>(false);
  const users = useSelector((state: Store) => state.userDataReducer.userObject);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    if (users?.signature?.length > 0) {
      setCanvasVisibility(true);
    }
  }, [users]);

  useEffect(() => {
    if (signatureCanvasParentRef.current && signatureRef) {
      const canvas = signatureRef.getCanvas();
      const width = signatureCanvasParentRef.current.offsetWidth;
      const height = signatureCanvasParentRef.current.offsetHeight;

      canvas.width = width;
      canvas.height = height;
    }
  }, [signatureCanvasParentRef, signatureRef]);

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: users.avatar || null,
      signature: users.signature || "",
      signatureFile: users.signatureFile || null,
    },
    validationSchema: RegisterStep5Schema,
    onSubmit: async (values) => {
      setCanvasVisibility(true);
      values.signature = values.signature.replace("data:image/png;base64,", "");
      values.signatureFile = SelectedFilesSign;
      dispatch(setRegisterSteps(4, values));
    },
  });
  const { touched, errors } = formik;
  const clear = () => {
    setCanvasVisibility(false);
    formik.setFieldValue("signature", "");
    setSelectedFiles(undefined);
    if (signatureRef) {
      signatureRef.clear();
    }
  };

  useEffect(() => {
    if (users.signatureFile) {
      setSelectedFilesSign(users.signatureFile);
    }
  }, [users]);

  useEffect(() => {
    if (SelectedFiles) {
      formik.setFieldValue(
        "signature",
        signatureRef.toDataURL().replace("data:image/png;base64,", ""),
      );
    }
  }, [SelectedFiles]);

  const handleDrop = async (acceptedFiles: any) => {
    setFileLoading(true);
    const { fileUploadResponse, error }: any = await _RegisterUploadMedia(
      acceptedFiles[0] as File,
    );

    if (!error) {
      formik.setFieldValue("avatar", acceptedFiles[0]);
    }

    setFileLoading(false);
  };

  const onDeleteImage = async () => {
    formik.setFieldValue("avatar", null);
  };

  return (
    <>
      <div className="mb-4">
        <div className="d-flex flex-wrap justify-content-between border-bottom pb-4 mb-4">
          <h1 className="h5 font-weight-600 m-0">
            {" "}
            <FormattedMessage
              id="register.setp5"
              defaultMessage="Step 5: Upload Profile Picture and Signature"
            />{" "}
          </h1>
          <button
            type="button"
            onClick={onBack}
            className="btn btn-primary btn btn-primary"
          >
            <BsChevronLeft />{" "}
            <FormattedMessage id="register.step5.Back" defaultMessage="Back" />{" "}
          </button>
        </div>
        {fileLoading && <CenterLoader />}
        <Row className="form-row">
          <Col>
            <div className="form-group">
              {/* <input type="file" className="form-control" /> */}
              {formik.values.avatar == null && (
                <>
                  <label>
                    {" "}
                    <FormattedMessage
                      id="register.step5.Selectfile"
                      defaultMessage="Upload Profile Picture *"
                    />{" "}
                  </label>
                  <FileUploadImage handleDrop={handleDrop} optional={false} />
                </>
              )}

              {formik.values.avatar !== null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      border: "3px lightgrey solid",
                      height: "200px",
                      width: "200px",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={URL.createObjectURL(formik.values.avatar)}
                      alt="User Profile Picture"
                    />
                  </div>
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => onDeleteImage()}
                  >
                    <i className="text-danger">
                      <FaTrash />{" "}
                    </i>
                  </button>
                </div>
              )}

              {errors.avatar && touched.avatar && (
                <div className="error">
                  <FormattedMessage
                    id={errors.avatar.toString()}
                    defaultMessage="Profile picture is required"
                  />{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <p className="mb-2">
              <b>
                <FormattedMessage
                  id="register.step5.Notes"
                  defaultMessage="Notes:"
                />{" "}
              </b>
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step5.Notes11"
                defaultMessage="1. Please upload Qingxin's frontal avatar."
              />{" "}
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step5.Notes22"
                defaultMessage="2. The identity verification document will only be used to verify your identity."
              />{" "}
            </p>
          </Col>
        </Row>
        <br />
        <Row className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step5.PersonalSign"
                  defaultMessage="Personal signature *"
                />{" "}
              </label>
              <>
                <div
                  ref={signatureCanvasParentRef}
                  className="signature-canvas-bg"
                >
                  {canvasVisibility ? (
                    <img
                      style={{ height: "100%" }}
                      className="sigCanvas"
                      src={`data:image/png;base64,${formik.values.signature}`}
                    />
                  ) : (
                    <SignatureCanvas
                      ref={(ref) => (signatureRef = ref)}
                      onEnd={() => {
                        const aa = signatureRef.getCanvas().toDataURL();
                        setSelectedFiles(signatureRef.toDataURL());
                        if (aa.length > 0) {
                          setSelectedFilesSign(
                            new File([dataURItoBlob(aa)], "signature.png"),
                          );
                        }
                      }}
                      penColor="blue"
                    />
                  )}
                </div>
              </>
              {errors.signature && touched.signature && (
                <div className="error">
                  <FormattedMessage
                    id={errors.signature.toString()}
                    defaultMessage="error"
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <br />
            <div className="form-group mt-2">
              <Button onClick={() => clear()}>
                {" "}
                <FormattedMessage
                  id="register.step5.ResetSign"
                  defaultMessage="Reset Signature"
                />{" "}
              </Button>
              <label className="d-block">&nbsp;</label>
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <p className="mb-2">
              <b>
                <FormattedMessage
                  id="register.step5.Notes"
                  defaultMessage="Notes:"
                />{" "}
              </b>
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step5.Notes1"
                defaultMessage="1. Please upload Qingxin's frontal avatar."
              />{" "}
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step5.Notes2"
                defaultMessage="2. The identity verification document will only be used to verify your identity."
              />{" "}
            </p>
          </Col>
        </Row>
      </div>
      <div className="text-right mt-auto">
        <Button
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          <FormattedMessage id="register.step5.Next" defaultMessage="Next" />{" "}
          <BsChevronRight />{" "}
        </Button>
      </div>
    </>
  );
};

export default RegisterStep5;
