import { Avatar, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Loader from "../../Components/common/Loader";
import { dispatch } from "../../redux";
import {
  addTicketCommentList,
  deleteMediaLocalInit,
  getTicketCommentList,
  setMediaUploadEmpty,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { _UploadMediaMutiple } from "../../service/MediaUpload/classes.service";
import { uploadFileAmazonS3 } from "../../service/third-party";
import {
  customFormatDatetimeWithLocale,
  formatDate,
  getFileName,
  getTextFromHtmlRowText,
} from "../../util/utils";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import EditorContainer from "../../Components/common/RichTextEditor/Editor";
import Pagination from "../../Components/common/Paginate/Pagination";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { ToastDownload } from "../../Components/common/ToastDownload";
import { appIntl } from "../../util/intl";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const TICKET_COMMENT_ATTACHMENT = "ticketCommentAttachment";

const MAX_SIZE = 2 * 1024 * 1024; // 2 MB

const TicketComment = () => {
  const urlParams = useParams();
  const [commentText, setCommentText] = useState("");
  const ticketComment: any = useSelector(
    (state: Store) => state.ticketReducer.ticketComment,
  ) as any;
  const onCSaveLoading: any = useSelector(
    (state: Store) => state.ticketReducer.onCSaveLoading,
  ) as any;
  const uploadLoad: any = useSelector(
    (state: Store) => state.mediaUploadReducer.uploadLoad,
  ) as any;
  const userDetail = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const ticketDetails = useSelector(
    (state: Store) => state.ticketReducer.ticketDetails,
  ) as any;
  const totalRecord: any = useSelector(
    (state: Store) => state.ticketReducer.totalCommentRecords,
  ) as any;

  const [edtCommentText, setEdtCommentText] = useState(
    EditorState.createEmpty(),
  );
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [isCommentEdit, setIsCommentEdit] = useState([]);
  const [commentError, setCommentError] = useState(false);
  const commentAttchmentRef = useRef<any>();
  const [pageNumber, setPageNumber] = useState(1);
  const btnRef = useRef<any>();
  const [LoadToastId, setLoadToastId] = useState<any>([]);

  const ticketCommentAttachment = useSelector(
    (state: Store) => state.mediaUploadReducer.ticketCommentAttachment,
  );

  const [buttonClick, setButtonClick] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [editorConfig, setEditorConfig] = useState({
    toolbar: false,
    editable: true,
    height: "70px",
    overflowY: "unset",
    border: "1px solid #efefef",
  });

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(
        getTicketCommentList(false, parseInt(id), pageNumber, pageLimit),
      );
    }
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    setPageNumber(1);
  }, [ticketDetails]);

  useEffect(() => {
    if (!onCSaveLoading && buttonClick) {
      dispatch(setMediaUploadEmpty(TICKET_COMMENT_ATTACHMENT));
      setCommentText("");
      setButtonClick(false);
    }
  }, [onCSaveLoading, buttonClick]);

  const onCommentSubmit = () => {
    if (urlParams.id) {
      const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      let text = eState.toString().replaceAll("&nbsp;", "");
      text = text.replaceAll("<p>", "");
      text = text.replaceAll("</p>", "");
      text = text.replaceAll("<br>", "");
      text = text.replaceAll("\n", "");
      text = text.trim();

      if (
        (text.length > 0 && eState.trim() != "<p></p>") ||
        ticketCommentAttachment.length > 0 ||
        ticketCommentAttachment.length > 0
      ) {
        dispatch(
          addTicketCommentList(
            true,
            {
              comment: eState,
              attachmentPath: ticketCommentAttachment.map(
                (i: any) => i.location,
              ),
            },
            parseInt(urlParams.id),
            1,
            10,
          ),
        );
        const estate = EditorState.createEmpty();
        setEditorState(estate);
        setCommentError(false);
        setButtonClick(true);
      } else {
        setCommentError(true);
      }
    }
  };

  //Add comment Attachment
  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > MAX_SIZE) {
        const maxSizeInMB = (MAX_SIZE / 1024 / 1024).toString();
        toast.error(
          appIntl().formatMessage(
            { id: "errors.uploadFileSizeError" },
            { maxSizeInMB: maxSizeInMB },
          ),
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      await _UploadMediaMutiple(
        [...acceptedFiles] as File[],
        TICKET_COMMENT_ATTACHMENT,
      );
    }
    // await uploadFileAmazonS3(acceptedFiles[0]);
    setCommentError(false);
    e.target.value = "";
  };

  const attchmentClick = () => {
    if (commentAttchmentRef.current) {
      setCommentError(false);
      commentAttchmentRef?.current.click();
    }
  };

  const onDelete = (key) => {
    dispatch(deleteMediaLocalInit(TICKET_COMMENT_ATTACHMENT, key));
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const text = getTextFromHtmlRowText(eState);

    if (text.length > 0) {
      setCommentError(false);
    }
  };

  const getCommentBy = (comment: any) => {
    let by = "";
    if (comment?.commentBy?.userType !== "admin") {
      if (comment.createdBy) {
        by = comment?.createdBy?.englishFirstName || "-";
        by = by + " " + comment?.createdBy?.englishLastName || " -";
      } else {
        by = "unknown";
      }
    } else {
      by = comment?.ticketGroup?.group_name || "";
    }
    return by;
  };

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  return (
    <div>
      {uploadLoad && <CenterLoader />}
      <h2 className="mt-5">
        <FormattedMessage
          id="announcementDetail.comment"
          defaultMessage="Comment"
        />
      </h2>
      <Row>
        <Col lg={1} md={3} sm={4}>
          <Avatar sx={{ bgcolor: "gray" }} aria-label="recipe">
            {userDetail?.englishFirstName?.charAt(0).toUpperCase()}
          </Avatar>
        </Col>
        <Col lg={10} md={9} sm={8}>
          <small>
            *{" "}
            <FormattedMessage
              id="announcementDetail.comment.subhead"
              defaultMessage="You will leave a message here."
            />
          </small>
          <EditorContainer
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            toolbar={editorConfig.toolbar}
            editable={
              ticketDetails.status === "closed"
                ? !editorConfig.editable
                : editorConfig.editable
            }
            height={editorConfig.height}
            overflowY={editorConfig.overflowY}
            border={editorConfig.border}
          />{" "}
          {commentText}
          {commentError && (
            <div className="text-error">
              <FormattedMessage
                id="comment.error"
                defaultMessage="Please add a comment"
              />
            </div>
          )}
          {/* </textarea> */}
          {ticketCommentAttachment.map((i: any, index: any) => (
            <div>
              <Row>
                <Col>
                  <ToastDownload
                    Load={LoadToastId}
                    setLoad={setLoadToastId}
                    ref={btnRef}
                  />

                  <a
                    // href={i.location}
                    onClick={() => {
                      if (btnRef.current) {
                        setLoadToastId((prv: any) => [
                          ...prv,
                          `TickCa${index}`,
                        ]);
                        btnRef?.current.downloadFiles(
                          i.location,
                          `TickCa${index}`,
                        );
                      }
                    }}
                    // target="_blank"
                    className="ticket-comment-attachment-url"
                  >
                    {getFileName(i.location)}
                  </a>
                </Col>
                <Col md={2} lg={1} sm={3}>
                  <Button onClick={() => onDelete(i.key)}>
                    <BsTrash />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          {ticketDetails.status === "closed" ? (
            <Button
              size="small"
              className="mr-2 mt-2"
              variant="contained"
              onClick={onCommentSubmit}
              style={{ textTransform: "none", cursor: "not-allowed" }}
              disabled
            >
              <FormattedMessage id="page.submit" defaultMessage="Submit" />
              {onCSaveLoading && (
                <div className="ml-2">
                  {" "}
                  <Loader />
                </div>
              )}
            </Button>
          ) : (
            <Button
              size="small"
              className="mr-2 mt-2"
              variant="contained"
              onClick={onCommentSubmit}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="page.submit" defaultMessage="Submit" />
              {onCSaveLoading && (
                <div className="ml-2">
                  {" "}
                  <Loader />
                </div>
              )}
            </Button>
          )}
          {ticketDetails.status === "closed" ? (
            <Button
              size="small"
              className="mt-2"
              variant="text"
              color="inherit"
              style={{ textTransform: "none", cursor: "not-allowed" }}
              disabled
            >
              <FormattedMessage
                id="page.AddAttachment"
                defaultMessage="Add attachment"
              />
            </Button>
          ) : (
            <Button
              size="small"
              onClick={attchmentClick}
              className="mt-2"
              variant="text"
              color="inherit"
              style={{ textTransform: "none" }}
            >
              <FormattedMessage
                id="page.AddAttachment"
                defaultMessage="Add attachment"
              />
            </Button>
          )}
        </Col>
      </Row>

      <input
        type="file"
        ref={commentAttchmentRef}
        style={{ display: "none" }}
        onChangeCapture={(e: any) => handleDrop(e)}
        multiple
      />

      {ticketComment &&
        ticketComment !== null &&
        ticketComment.map((comment: any) => (
          <>
            <Row className="mb-2 mt-4">
              <Col lg={1} md={2} sm={4}>
                <Avatar>
                  {getCommentBy(comment)?.charAt(0)?.toUpperCase()}
                </Avatar>
              </Col>
              <Col lg={10} md={9} sm={8}>
                <strong>{getCommentBy(comment)}</strong>
                <small className="ml-2" style={{ color: "lightgray" }}>
                  {customFormatDatetimeWithLocale(
                    comment.createdAt,
                    locale,
                    true,
                  )}{" "}
                </small>
                <br />
                {isCommentEdit == comment.id ? (
                  <EditorContainer
                    onEditorStateChange={onEditorStateChange}
                    editorState={edtCommentText}
                    toolbar={editorConfig.toolbar}
                    editable={editorConfig.editable}
                    height={editorConfig.height}
                    overflowY={editorConfig.overflowY}
                    border={editorConfig.border}
                  />
                ) : (
                  <p className="mt-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment.comment),
                      }}
                    />
                  </p>
                )}
                {(comment?.attachmentList || []).map((i: any, index: any) => (
                  <>
                    <ToastDownload
                      Load={LoadToastId}
                      setLoad={setLoadToastId}
                      ref={btnRef}
                    />

                    <a
                      target="_blank"
                      // href={i.attachmentPath}
                      onClick={() => {
                        if (btnRef.current) {
                          setLoadToastId((prv: any) => [
                            ...prv,
                            `TickC${index}`,
                          ]);
                          btnRef?.current.downloadFiles(
                            i.attachmentPath,
                            `TickC${index}`,
                          );
                        }
                      }}
                      className="ticket-comment-attachment-url"
                    >
                      {getFileName(i.attachmentPath)}
                    </a>
                    <br />
                  </>
                ))}
              </Col>
            </Row>
          </>
        ))}

      {ticketComment &&
        ticketComment.length > 0 &&
        ticketComment !== null &&
        totalRecord > 10 && (
          <>
            <FormControl className="pagelimit">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <FormattedMessage id="page_size" defaultMessage="Page Size" />
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={pageLimit}
                onChange={(e: any) => {
                  setPageLimit(e.target.value);
                  setPageNumber(1);
                }}
              >
                <FormControlLabel value={10} control={<Radio />} label="10" />
                <FormControlLabel value={20} control={<Radio />} label="20" />
                <FormControlLabel value={50} control={<Radio />} label="50" />
              </RadioGroup>
            </FormControl>
            <div className="pag">
              <Pagination
                itemsPerPage={pageLimit}
                totalPages={totalRecord}
                onPageChage={onPageChange}
                page={pageNumber - 1}
              />
            </div>
          </>
        )}
    </div>
  );
};

export default TicketComment;
