import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { RiFileEditFill } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import AddSignalModal from "../../Components/Siganl/AddSignalModal";
import { dispatch } from "../../redux";
import {
  addClassSignal,
  deleteClassSignal,
  getMySignalList,
  getUserRegisteredClass,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const MySingal = () => {
  const MySignalLsts = useSelector(
    (state: Store) => state.signalReducer.MySignalLst || [],
  ) as Array<Object>;
  const registerdClass = useSelector(
    (state: Store) => state.classReducer.registerdClass,
  ) as Array<Object>;
  const isAPILoading = useSelector(
    (state: Store) => state.signalReducer.isLoading,
  );
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [modal, setModal] = useState(false);
  const [selectedSignal, setSelectedSignal] = useState({});
  const [MySignalLst, setMySignalLst] = useState<any>([]);
  const [delModal, setDelModal] = useState({ modal: false, id: "" });
  const [pageNumber, setPageNumber] = useState(1);
  const [text, setText] = useState("");
  const [allText, setAllText] = useState("");
  const [filterQ, setFilterQ] = useState("");

  const totalRecord = useSelector(
    (state: Store) => state.signalReducer.totalRecord,
  ) as any;

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");

  useEffect(() => {
    dispatch(getUserRegisteredClass(false));
  }, []);

  useEffect(() => {
    setMySignalLst(MySignalLsts);
  }, [MySignalLsts]);

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(text);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [text]);

  useEffect(() => {
    setFilter(text);
  }, [allText]);

  useEffect(() => {
    dispatch(
      getMySignalList(
        false,
        pageNumber,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [sortOrder, sortBy, pageNumber, filterQ, pageLimit]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  const deleteAccount = () => {
    dispatch(deleteClassSignal(false, delModal.id));
    setDelModal({ modal: false, id: "" });
  };

  const AddSignal = (siganl: any) => {
    dispatch(addClassSignal(true, siganl));
  };

  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };
  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        {isAPILoading && <CenterLoader />}
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="menu.signal.my"
                  defaultMessage="My Signal"
                />
              </h1>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => {
                setModal(true), setSelectedSignal({});
              }}
            >
              <FormattedMessage
                id="page.Add.Signal"
                defaultMessage="Add Signal"
              />
            </button>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>

              {MySignalLst.length > 0 ? (
                <>
                  <div className="table-responsive">
                    {" "}
                    <table className="table table-hover table-striped m-0">
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            <FormattedMessage
                              id="signalmodal.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("accountType")}
                          >
                            <FormattedMessage
                              id="table.head.Type"
                              defaultMessage="Type"
                            />
                            {sortBy === "accountType" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("class")}
                          >
                            <FormattedMessage
                              id="activity.Class"
                              defaultMessage="Class"
                            />
                            {sortBy === "class" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("profit")}
                          >
                            <FormattedMessage
                              id="table.head.Profit"
                              defaultMessage="Profit"
                            />
                            (%)
                            {sortBy === "profit" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("drawdown")}
                          >
                            <FormattedMessage
                              id="table.head.Drawdown"
                              defaultMessage="Drawdown"
                            />
                            (%)
                            {sortBy === "drawdown" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("equity")}
                          >
                            <FormattedMessage
                              id="table.head.Equity"
                              defaultMessage="Equity"
                            />
                            {sortBy === "equity" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("balance")}
                          >
                            <FormattedMessage
                              id="table.head.Balance"
                              defaultMessage="Balance"
                            />
                            {sortBy === "balance" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("remarks")}
                          >
                            <FormattedMessage
                              id="table.head.Remark"
                              defaultMessage="Remark"
                            />
                            {sortBy === "remarks" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>{" "}
                          <th>
                            <FormattedMessage
                              id="page.Edit"
                              defaultMessage="Edit"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.Delete"
                              defaultMessage="Delete"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {MySignalLst?.length &&
                          (MySignalLst || []).map((i: any) => (
                            <tr>
                              <td>
                                <a href={i?.mql5_link} target="_blank">
                                  {" "}
                                  {i?.name}{" "}
                                </a>
                              </td>
                              <td>{i?.accountType}</td>
                              <td>{i?.class?.name}</td>
                              <td>{i?.profit}</td>
                              <td>{i?.drawdown}</td>
                              <td>{i?.equity}</td>
                              <td>{i?.balance}</td>
                              <td>{i?.remarks}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setModal(true), setSelectedSignal(i);
                                  }}
                                >
                                  <i className="d-flex justify-content-center text-white">
                                    <RiFileEditFill />{" "}
                                  </i>{" "}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setDelModal({ id: i.id, modal: true })
                                  }
                                >
                                  <i className="d-flex justify-content-center text-white">
                                    <FaTrash />{" "}
                                  </i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {totalRecord > 10 && (
                    <>
                      <FormControl className="pagelimit">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <FormattedMessage
                            id="page_size"
                            defaultMessage="Page Size"
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={pageLimit}
                          onChange={(e: any) => {
                            setPageLimit(e.target.value);
                            setPageNumber(1);
                          }}
                        >
                          <FormControlLabel
                            value={10}
                            control={<Radio />}
                            label="10"
                          />
                          <FormControlLabel
                            value={20}
                            control={<Radio />}
                            label="20"
                          />
                          <FormControlLabel
                            value={50}
                            control={<Radio />}
                            label="50"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div className="mt-3">
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <p>{!isAPILoading && <NoDatFound />}</p>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
      <AddSignalModal
        onAddSignal={AddSignal}
        signal={selectedSignal}
        onCancle={() => {
          setModal(false);
          setSelectedSignal({});
        }}
        showModal={modal}
        classes={registerdClass}
      />

      {delModal.modal && (
        <DeletePopup
          message={
            <FormattedMessage
              id="msg.deleteSignal"
              defaultMessage="Are you sure want to delete signal ?"
            />
          }
          onClose={() => setDelModal((prv: any) => ({ ...prv, modal: false }))}
          open={delModal.modal}
          onConfirm={() => deleteAccount()}
          isLoading={isAPILoading}
        />
      )}
    </div>
  );
};

export default MySingal;
