/* eslint-disable no-case-declarations */
import {
  GET_EA_LIST,
  GET_EA_VERSION_LIST,
  GET_EA_UNAUTHORIZED_LIST,
  GET_CLASS_EXPERT_ADVISORS_LIST,
} from "../constants/action-types";
import { EAData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  availableExpertAdvisorsIsLoading: false,
  unavailableExpertAdvisorsIsLoading: false,
  onSaveLoading: false,
  EAList: [],
  EAarchiveData: [],
  EACurrentData: [],
  EADetail: {},
  Advertisement: {},
  UnauthorizedEAList: [],
  classExpertAdvisors: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: EAData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_EA_LIST.GET_EA_LIST_INITIALIZATION:
        return {
          ...state,
          EAList: [],
          isLoading: true,
          availableExpertAdvisorsIsLoading: true,
        };

      case GET_EA_LIST.GET_EA_LIST_SUCCESS:
        const allPost = action.payload;
        return {
          ...state,
          EAList: allPost,
          isLoading: false,
          availableExpertAdvisorsIsLoading: false,
        };

      case GET_EA_LIST.GET_EA_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          availableExpertAdvisorsIsLoading: false,
        };

      case GET_EA_UNAUTHORIZED_LIST.GET_EA_UNAUTHORIZED_LIST_INITIALIZATION:
        return {
          ...state,
          UnauthorizedEAList: [],
          isLoading: true,
          unavailableExpertAdvisorsIsLoading: true,
        };

      case GET_EA_UNAUTHORIZED_LIST.GET_EA_UNAUTHORIZED_LIST_SUCCESS:
        return {
          ...state,
          UnauthorizedEAList: action.payload,
          isLoading: false,
          unavailableExpertAdvisorsIsLoading: false,
        };

      case GET_EA_UNAUTHORIZED_LIST.GET_EA_UNAUTHORIZED_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          unavailableExpertAdvisorsIsLoading: false,
        };

      case GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_SUCCESS:
        const vdata: any = action.payload;
        return {
          ...state,
          EADetail: vdata.EADetail,
          EAarchiveData: vdata.archiveData,
          EACurrentData: vdata.data,
          Advertisement: vdata.EAadvertisement,
          isLoading: false,
        };

      case GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_CLASS_EXPERT_ADVISORS_LIST.GET_CLASS_EXPERT_ADVISORS_LIST_INITIALIZATION:
        return {
          ...state,
          classExpertAdvisors: [],
          isLoading: true,
        };

      case GET_CLASS_EXPERT_ADVISORS_LIST.GET_CLASS_EXPERT_ADVISORS_LIST_SUCCESS:
        return {
          ...state,
          classExpertAdvisors: action.payload,
          isLoading: false,
        };

      case GET_CLASS_EXPERT_ADVISORS_LIST.GET_CLASS_EXPERT_ADVISORS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
