import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setRegisterSteps } from "../../redux/action";
import { FormattedMessage } from "react-intl";
import {
  EmailIcon,
  IdentityIcon,
  SelectionIcon,
  UploadSelfiIcon,
  PaymentReciptIcon,
} from "../../util/iconStyle";
import { Box } from "@mui/material";

const RegisterStep1 = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="mb-4">
        <div className="first-left-box px-5 py-4">
          <div className="text-center mb-5">
            <h2 style={{ fontWeight: "500" }}>
              <Box sx={{ color: "#68439a" }}>
                {" "}
                <FormattedMessage
                  id="register.step1.head"
                  defaultMessage="Before We Start"
                />
              </Box>{" "}
            </h2>
            <FormattedMessage
              id="register.step1.subhead"
              defaultMessage="In order to speed up your registration process, we recommend that you prepare the following documents first."
            />
          </div>
          <ul className="list-unstyled we-start-list">
            <li className="mb-4">
              <i
                className="MuiListItemIcon-root-reg"
                style={{ fill: "#68439a" }}
              >
                <EmailIcon />
              </i>
              <div>
                <h4 style={{ fontWeight: "500" }}>
                  {" "}
                  <Box sx={{ color: "#68439a" }}>
                    <FormattedMessage
                      id="register.emailAddress"
                      defaultMessage="Email Address"
                    />
                  </Box>{" "}
                </h4>
                <small className="mb-2">
                  <FormattedMessage
                    id="register.desc.email"
                    defaultMessage="Please prepare a FREQUENTLY used email for member communication."
                  />
                </small>
              </div>
            </li>
            <li className="mb-4">
              <i
                className="MuiListItemIcon-root-reg"
                style={{ fill: "#68439a" }}
              >
                <IdentityIcon />
              </i>
              <div>
                <h4 style={{ fontWeight: "500" }}>
                  <Box sx={{ color: "#68439a" }}>
                    <FormattedMessage
                      id="register.identityDocument"
                      defaultMessage="Email Address"
                    />
                  </Box>
                </h4>
                <small className="mb-2">
                  <FormattedMessage
                    id="register.desc.identityDocument"
                    defaultMessage="Please prepare a FREQUENTLY used email for member communication."
                  />
                </small>
              </div>
            </li>
            <li className="mb-4">
              <i
                className="MuiListItemIcon-root-reg"
                style={{ fill: "#68439a" }}
              >
                <UploadSelfiIcon />
              </i>
              <div>
                <h4 style={{ fontWeight: "500" }}>
                  <Box sx={{ color: "#68439a" }}>
                    <FormattedMessage
                      id="register.profilePicture"
                      defaultMessage="Email Address"
                    />
                  </Box>
                </h4>
                <small className="mb-2">
                  <FormattedMessage
                    id="register.desc.profilePicture"
                    defaultMessage="Please prepare a FREQUENTLY used email for member communication."
                  />
                </small>
              </div>
            </li>
            <li className="mb-4">
              <i
                className="MuiListItemIcon-root-reg"
                style={{ fill: "#68439a" }}
              >
                <SelectionIcon />
              </i>
              <div>
                <h4 style={{ fontWeight: "500" }}>
                  <Box sx={{ color: "#68439a" }}>
                    <FormattedMessage
                      id="register.course"
                      defaultMessage="Email Address"
                    />
                  </Box>
                </h4>
                <small className="mb-2">
                  <FormattedMessage
                    id="register.desc.course"
                    defaultMessage="Please prepare a FREQUENTLY used email for member communication."
                  />
                </small>
              </div>
            </li>
            <li className="mb-4">
              <i
                className="MuiListItemIcon-root-reg"
                style={{ fill: "#68439a" }}
              >
                <PaymentReciptIcon />
              </i>
              <div>
                <h4 style={{ fontWeight: "500" }}>
                  <Box sx={{ color: "#68439a" }}>
                    <FormattedMessage
                      id="register.payment"
                      defaultMessage="Email Address"
                    />
                  </Box>
                </h4>
                <small className="mb-2">
                  <FormattedMessage
                    id="register.desc.payment"
                    defaultMessage="Please prepare a FREQUENTLY used email for member communication."
                  />
                </small>
              </div>
            </li>
          </ul>

          <div className="text-right mt-5">
            <Button
              className="btn btn-primary"
              onClick={() => dispatch(setRegisterSteps(0, { accepted: true }))}
            >
              <FormattedMessage
                id="register.confirm"
                defaultMessage="Confirm"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterStep1;
