/* eslint-disable no-case-declarations */
import {
  GET_ANNOUNCEMENT_LIST,
  GET_ANNOUNCEMENT_DETAILS,
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_COMMENT,
  GET_ANNOUNCEMENT_COMMENT,
  MARKED_ANNOUNCEMENT_READ,
  GET_UNREAD_ANNOUNCEMENT_LIST,
} from "../constants/action-types";
import { AnnouncementsData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  announcements: {},
  announcementComments: [],
  announcementsDetails: {},
  totalRecord: 0,
  unreadAnnouncementsList: [],
  isMarkedAsRead: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: AnnouncementsData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_INITLIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_SUCCESS:
        const lstAnnouncemnt: any = action.payload;
        return {
          ...state,
          announcements: lstAnnouncemnt.data,
          isLoading: false,
          totalRecord: lstAnnouncemnt.totalCount,
        };

      case GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_ERORR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };
      case GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_SUCCESS:
        return {
          ...state,
          announcementsDetails: action.payload,
          isLoading: false,
        };

      case GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_SUCCESS:
        const lstComment: any = action.payload;
        return {
          ...state,
          isLoading: false,
          announcementComments: lstComment.data,
          totalRecord: lstComment.count,
        };

      case GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };

      case ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };
      case GET_UNREAD_ANNOUNCEMENT_LIST.GET_UNREAD_ANNOUNCEMENT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_UNREAD_ANNOUNCEMENT_LIST.GET_UNREAD_ANNOUNCEMENT_LIST_SUCCESS:
        const unread: any = action.payload;
        return {
          ...state,
          isLoading: false,
          unreadAnnouncementsList: unread.data,
        };

      case GET_UNREAD_ANNOUNCEMENT_LIST.GET_UNREAD_ANNOUNCEMENT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case MARKED_ANNOUNCEMENT_READ.MARKED_ANNOUNCEMENT_READ_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          isMarkedAsRead: false,
        };

      case MARKED_ANNOUNCEMENT_READ.MARKED_ANNOUNCEMENT_READ_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isMarkedAsRead: true,
        };

      case MARKED_ANNOUNCEMENT_READ.MARKED_ANNOUNCEMENT_READ_ERROR:
        return {
          ...state,
          isLoading: false,
          isMarkedAsRead: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
