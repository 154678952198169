import React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

const PasswordAlert = ({
  message,
  onClose,
  open,
  onConfirm,
  isLoading,
}: any) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {message || "Are you sure want to perform action?"}{" "}
        </DialogTitle>
        <List
          sx={{ pt: 0 }}
          style={{ textAlign: "end", padding: "12px 12px 12px 12px" }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            startIcon={<CloseIcon />}
            className="mr-2"
            color="error"
          >
            <FormattedMessage
              id="passwordAlert.accepThreat"
              defaultMessage="No"
            />
          </Button>
          <Button
            onClick={onConfirm}
            variant="outlined"
            startIcon={<DoneIcon />}
            color="success"
          >
            <FormattedMessage
              id="passwordAlert.changePassword"
              defaultMessage="Yes"
            />

            {isLoading && <p style={{ display: "none" }}>Loading...</p>}
          </Button>
        </List>
      </Dialog>
    </div>
  );
};

export default PasswordAlert;
