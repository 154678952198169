import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {
  addAnnouncementComment,
  getAnnouncementComment,
  getAnnouncementDetails,
  getQR,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import {
  customFormatDatetimeWithLocale,
  formatDate,
  getFileDetail,
  getTextFromHtmlRowText,
} from "../../util/utils";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { FormattedMessage } from "react-intl";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import EditorContainer from "../../Components/common/RichTextEditor/Editor";
import Pagination from "../../Components/common/Paginate/Pagination";
import DOMPurify from "dompurify";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const AnnouncementComments = ({
  announcementsDetails,
  announcementComments,
  isLoading,
}: any) => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [decription, setDescription] = useState("Inital Test");
  const [deleteCall, setDeleteCall] = useState(false);
  const [comment, setComment] = useState("");
  const [commentDelete, setCommentDelete] = useState({ modal: false, id: 0 });
  const [img, setImg] = useState({ image: "", show: false });
  const attchRef = useRef<any>();
  const [announcementAttachment, setAnnouncemenAttachment] = useState<any>([]);

  const userDetail = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;

  const totalRecord: any = useSelector(
    (state: Store) => state.announcementsReducer.totalRecord,
  ) as any;

  const [fileLoading, setFileLoading] = useState(false);
  const [editedClass, setEditedClass] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [classEdit, setClassEdit] = useState(false);
  const [commentError, setCommentError] = useState(false);

  const [editorConfig, setEditorConfig] = useState({
    toolbar: false,
    editable: true,
    height: "70px",
    overflowY: "unset",
    border: "1px solid #efefef",
  });

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0 && classEdit) {
      dispatch(getAnnouncementDetails(false, parseInt(id)));
    }
  }, [classEdit]);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getAnnouncementDetails(false, parseInt(id)));
      dispatch(getAnnouncementComment(false, id, 1));
      dispatch(getQR());
    }
    setCommentError(false);
  }, []);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getAnnouncementComment(false, id, pageNumber, pageLimit));
    }
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    if (!isLoading && deleteCall) {
      navigate(-1);
      setDeleteCall(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const estate = EditorState.createEmpty();
    setEditorState(estate);
  }, [announcementsDetails]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const text = getTextFromHtmlRowText(eState);

    if (text.length > 0) {
      setCommentError(false);
    }
  };

  const onAddCommnent = () => {
    const { id } = urlParams;
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let text = eState.toString().replaceAll("&nbsp;", "");
    text = text.replaceAll("<p>", "");
    text = text.replaceAll("</p>", "");
    text = text.replaceAll("<br>", "");
    text = text.replaceAll("\n", "");
    text = text.trim();

    if (text.length > 0 && eState.trim() != "<p></p>") {
      dispatch(
        addAnnouncementComment(true, { comment: eState, announcement: id }, 1),
      );
      setComment("");
      setCommentError(false);
      const estate = EditorState.createEmpty();
      setEditorState(estate);
      setPageNumber(1);
    } else {
      setCommentError(true);
    }
  };

  const onEditClick = () => {
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const enableEditing = {
      toolbar: false,
      editable: false,
      height: "200px",
      overflowY: "unset",
      border: "1px solid transparent",
    };
    setEditorConfig(() => ({ ...enableEditing }));
    const { id } = urlParams;
    let text = eState.toString().replaceAll("&nbsp;", "");
    text = text.replaceAll("<p>", "");
    text = text.replaceAll("</p>", "");
    text = text.replaceAll("<br>", "");
    text = text.replaceAll("\n", "");
    text = text.trim();
    if (text.length > 0 && eState.trim() != "<p></p>") {
      announcementsDetails.content = eState;
      const temp = {
        title: announcementsDetails.title,
        content: announcementsDetails.content,
        class_group: editedClass,
        attachmentPath: announcementsDetails.attechment.map(
          (i: any) => i.attachmentPath,
        ),
      };
    } else {
      const blocksFromHTML = convertFromHTML(decription);
      const editorsState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      onEditorStateChange(editorsState);
    }
  };

  const deleteComment = (commentId: any) => {
    const { id } = urlParams;

    setCommentDelete({ modal: false, id: 0 });
  };

  const deleteCommentModal = (id: any) => {
    setCommentDelete({ modal: true, id: id });
  };

  const attchmentClick = () => {
    if (attchRef.current) {
      attchRef?.current.click();
    }
  };

  const handleDrop = async (e: any) => {
    setFileLoading(true);
    const acceptedFiles = e.target.files;
    const { fileUploadResponse, error }: any = await _UploadMedia(
      acceptedFiles[0] as File,
      false,
    );
    if (!error) {
      const files = getFileDetail([fileUploadResponse]);
      const announcementAttachments = announcementAttachment;
      announcementAttachments.push(...files);

      setAnnouncemenAttachment(() => [...announcementAttachments]);

      const Detail = {
        title: announcementsDetails.title,
        content: announcementsDetails.content,
        class_group: announcementsDetails.class_group,
        attachmentPath: [
          ...announcementsDetails.attechment.map((i: any) => i.attachmentPath),
          fileUploadResponse,
        ],
      };
      const { id } = urlParams;
    }
    setFileLoading(false);
  };

  const onImageDelete = (index: any) => {
    const a = announcementAttachment.filter(
      (i: any, ind: any) => ind !== index,
    );
    setAnnouncemenAttachment(() => [...a]);
    const { id } = urlParams;
    const announcementDetail = {
      title: announcementsDetails.title,
      content: announcementsDetails.content,
      class_group: announcementsDetails.class_group,
      attachmentPath: a.map((i: any) => i.path),
    };
  };

  const getCommentBy = (comment: any) => {
    let by = "unknown";
    if (comment?.commentBy?.userType !== "admin") {
      if (comment.createdBy) {
        by = comment?.createdBy?.englishFirstName || "-";
        by = by + " " + comment?.createdBy?.englishLastName || " -";
      } else {
        by = "unknown";
      }
    } else {
      by = comment?.group?.group_name || "unknown";
    }
    return by;
  };

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  return (
    <>
      {fileLoading && <CenterLoader />}
      {isLoading && <CenterLoader />}

      <h4 className="font-weight-500 my-4">
        <FormattedMessage
          id="announcementDetail.comment"
          defaultMessage="Comment"
        />{" "}
        <small className="font-size-12 ml-2">
          (*{" "}
          {
            <FormattedMessage
              id="announcementDetail.comment.subhead"
              defaultMessage="You will leave a message here"
            />
          }
          )
        </small>
      </h4>
      <div className="comments-media">
        <div className="media">
          <Avatar sx={{ bgcolor: "gray" }} aria-label="recipe" className="mb-3">
            {userDetail?.englishFirstName?.charAt(0).toUpperCase()}
          </Avatar>
          <div className="media-body ml-3 editor-state-change">
            <EditorContainer
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              toolbar={editorConfig.toolbar}
              editable={editorConfig.editable}
              height={editorConfig.height}
              overflowY={editorConfig.overflowY}
              border={editorConfig.border}
            />{" "}
            {/* </textarea> */}
            {commentError && (
              <div className="text-error">
                <FormattedMessage
                  id="comment.error"
                  defaultMessage="Please add a comment"
                />
              </div>
            )}
            {/* {announcementAttachment.map((i: any) => (
              <div>
                <Row>
                  <Col>
                    <a
                      href={i.path}
                      target="_blank"
                      style={{
                        color: "GrayText",
                        fontSize: "12px",
                        borderBottom: "1px GrayText solid",
                      }}
                    >
                      {i.name}
                    </a>
                  </Col>
                  <Col md={2} lg={1} sm={3}>
                    <Button>
                      <BsTrash />
                    </Button>
                  </Col>
                </Row>
              </div>
            ))} */}
            <button
              className="btn btn-primary btn-sm mt-2"
              onClick={() => onAddCommnent()}
            >
              <FormattedMessage id="page.submit" defaultMessage="Submit" />
            </button>
            {/* <Button
              size="small"
              onClick={attchmentClick}
              className="mt-2"
              variant="text"
              color="inherit"
              style={{ textTransform: "none", marginLeft: "5px" }}
            >
              <FormattedMessage
                id="page.AddAttachment"
                defaultMessage="Add attachment"
              />
            </Button>
            <input
              type="file"
              ref={attchRef}
              style={{ display: "none" }}
              onChangeCapture={(e: any) => handleDrop(e)}
              multiple
            /> */}
          </div>
        </div>
        <br />
        {announcementComments &&
          announcementComments.map((comment: any) => (
            <>
              <div className="media mt-2">
                <Avatar>
                  {/* {comment?.createdBy?.avatar ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={comment.createdBy.avatar}
                    />
                  ) : (
                    <>{comment.comment.charAt(0)} </>
                  )} */}
                  {getCommentBy(comment)?.charAt(0)?.toUpperCase()}
                </Avatar>
                <div className="media-body ml-3">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <p className="font-size-14 font-weight-600 m-0">
                      {getCommentBy(comment)}

                      {/* {comment.createdBy.userType == "admin" ? (
                        "GemsFX Development Team"
                      ) : (
                        <>
                          {comment.createdBy ? (
                            <>
                              {comment?.createdBy?.englishFirstName +
                                " " +
                                comment.createdBy.englishLastName}
                            </>
                          ) : (
                            "unknown"
                          )}{" "}
                        </>
                      )} */}
                      <small className="text-muted ml-1">
                        {customFormatDatetimeWithLocale(
                          comment.createdAt,
                          locale,
                          true,
                        )}{" "}
                      </small>
                    </p>
                    {/* <button
                      className="no-back-red "
                      onClick={() => {
                        deleteCommentModal(comment.id);
                      }}
                      style={{
                        fontSize: "12px",
                        color: "lightskyblue",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <i className="fa fa-trash-alt"></i>
                    </button> */}
                  </div>
                  <p className="mb-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment.comment),
                      }}
                    />
                  </p>
                </div>
              </div>
            </>
          ))}
      </div>
      {announcementComments.length > 0 && totalRecord > 10 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>

          <div className="pag">
            <Pagination
              itemsPerPage={pageLimit}
              totalPages={totalRecord}
              onPageChage={onPageChange}
              page={pageNumber - 1}
            />
          </div>
        </>
      )}
      {/* </Card.Body> */}
      {/* </Card> */}
      {/* </section> */}
      {/* </div> */}
      {/* {
        commentDelete && (
          <DeletePopup
            onClose={() => setCommentDelete({ modal: false, id: 0 })}
            onDeleteClick={() => deleteComment(commentDelete.id)}
            open={commentDelete.modal}
          />
        )
      } */}

      {/* {
        classEdit && !isLoading && (
          <EditAnnouncementClassModal
            onModalClose={() => setClassEdit(false)}
            classList={classes}
            selectedClass={announcementsDetails.class_group}
            isAPILoading={onSaveLoading}
            isLoading={isLoading}
            setEditedClass={(classes: any) => {
              setEditedClass(classes);
              onEditClick();
            }}
            announcementsDetails={announcementsDetails}
          />
        )
      } */}
    </>
  );
};
export default AnnouncementComments;
