/* eslint-disable no-case-declarations */
import {
  GET_SCRIPT_LIST,
  GET_SCRIPT_VERSION_LIST,
  GET_UNAUTHORIZED_SCRIPT_LIST,
} from "../constants/action-types";
import { ScriptData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  scriptList: [],
  UnauthorizedScriptLst: [],
  scriptDetail: {},
  advertisement: {},
  currentVData: [],
  archiveVData: [],
  unavailableScriptsIsLoading: false,
  availableScriptsIsLoading: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ScriptData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_SCRIPT_LIST.GET_SCRIPT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          availableScriptsIsLoading: true,
        };

      case GET_SCRIPT_LIST.GET_SCRIPT_LIST_SUCCESS:
        const allPost = action.payload;
        return {
          ...state,
          scriptList: allPost,
          isLoading: false,
          availableScriptsIsLoading: false,
        };

      case GET_SCRIPT_LIST.GET_SCRIPT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          availableScriptsIsLoading: false,
        };

      case GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_SUCCESS:
        const versionDetail: any = action.payload;
        return {
          ...state,
          scriptDetail: versionDetail.ScriptDetail,
          currentVData: versionDetail.data,
          archiveVData: versionDetail.archiveData,
          advertisement: versionDetail.Scriptadvertisement,
          isLoading: false,
        };

      case GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_UNAUTHORIZED_SCRIPT_LIST.GET_UNAUTHORIZED_SCRIPT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          unavailableScriptsIsLoading: true,
        };

      case GET_UNAUTHORIZED_SCRIPT_LIST.GET_UNAUTHORIZED_SCRIPT_LIST_SUCCESS:
        return {
          ...state,
          UnauthorizedScriptLst: action.payload,
          isLoading: false,
          unavailableScriptsIsLoading: false,
        };

      case GET_UNAUTHORIZED_SCRIPT_LIST.GET_UNAUTHORIZED_SCRIPT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          unavailableScriptsIsLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
