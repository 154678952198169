import ReactPlayer from "react-player";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import welcomeVideoPoster from "../assets/img/welcomeVideoPosterCompressed.jpg";

const LandingPage = () => {
  const navigate = useNavigate();

  const [showPoster, setShowPoster] = useState(true);

  useEffect(() => {
    checkData();
  }, []);

  const checkData = async () => {
    await getAllCacheData();
  };

  const getAllCacheData = async () => {
    const url = process.env.REACT_APP_PUBLIC_URL || "";
    const names = await caches.keys();

    const cacheDataArray = [];
    if (names.length > 0) {
      names.forEach(async (name) => {
        // Opening that particular cache
        const cacheStorage = await caches.open(name);

        // Fetching that particular cache data
        const cachedResponse = await cacheStorage.match(url);
        const data = await cachedResponse?.json();

        // Pushing fetched data into our cacheDataArray
        cacheDataArray.push(data);

        const sp = cacheDataArray[0]?.split(",");

        if (sp?.[0] == "data:video/mp4;base64") {
          // setFile(cacheDataArray[0]);
        } else {
          getDataFromURL();
        }

        return cacheDataArray[0] || "";
      });
    } else {
      getDataFromURL();
    }
  };

  const getDataFromURL = () => {
    const str = process.env.REACT_APP_PUBLIC_URL || "";
    const xhr = new XMLHttpRequest();

    xhr.open("GET", `${process.env.REACT_APP_PUBLIC_URL}New_BG.mp4`, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      const fr = new FileReader();
      fr.onload = function (e) {
        addDataIntoCache("video", str, e.target.result);
      };
      fr.readAsDataURL(xhr.response);
    };
    xhr.send(null);
  };

  const addDataIntoCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));

    if ("caches" in window) {
      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };

  return (
    <div
      className="wrapper w-100p landing-page "
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/login")}
    >
      <div
        className="full-screen-banner full-screen-video"
        id="l"
        onClick={() => navigate("/login")}
      >
        <div className="item d-flex align-items-center">
          <ReactPlayer
            className="react-player fixed-bottom"
            url={
              "https://gems-fx-prod.s3.amazonaws.com/public_assets/New_BG.mp4"
            }
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            playing={true}
            controls={false}
            // loop={true}
            muted
            playsinline={true}
            onReady={() => setShowPoster(false)}
            onEnded={() => navigate("/login")}
          />
          {showPoster ? (
            <img
              className="fixed-bottom"
              width="100%"
              height="100%"
              src={welcomeVideoPoster}
              alt="loading..."
              style={{ objectFit: "cover", zIndex: 2048 }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
