import { Avatar } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Select from "react-select";
import { dispatch } from "../../redux";
import {
  editTicketList,
  getTicketAssigneeList,
  getTicketIssueTypeList,
  getTicketToolList,
  getTicketToolVersionList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { TicketOption, ToolTypeOption } from "../../util/constants/UserGroup";
import { formatTime } from "../../util/timeZoneUtils";
import {
  customFormatDatetimeWithLocale,
  formatDateDiff,
} from "../../util/utils";
import { softwareUpdateSchema } from "../../util/validationSchema";

const TicketAssignee = ({ ticketDetail, id }: any) => {
  const IssueType = useSelector(
    (state: Store) => state.ticketReducer.issueList,
  ) as any;
  const toolList = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;
  const toolVersionList = useSelector(
    (state: Store) => state.ticketReducer.toolVersionList,
  ) as any;
  const ticketAsignee = useSelector(
    (state: Store) => state.ticketReducer.ticketAsignee,
  ) as any;
  const myInfo = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const [selectedSoftwareType, setSelectedSoftwareType] = useState<any>({});
  const [selectedSoftware, setSelectedSoftware] = useState<any>({});
  const [selectedSoftwareVersion, setSelectedSoftwareVersion] = useState<any>(
    {},
  );
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>({});
  const [selectedChange, setChange] = useState<any>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      toolType: ticketDetail ? ticketDetail?.tool : -1,
      tool: ticketDetail ? ticketDetail?.software?.id : -1,
      version: ticketDetail ? ticketDetail?.version?.id : -1,
    },
    validationSchema: softwareUpdateSchema,
    onSubmit: () => {
      editTicket();
    },
  });
  const { errors, touched } = formik;

  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";

  useEffect(() => {
    dispatch(getTicketIssueTypeList());
    dispatch(getTicketAssigneeList(false));
    dispatch(getTicketToolList(false, 0));

    if (ticketDetail.status) {
      TicketOption.map((i: any) => {
        if (i.id === ticketDetail.status) {
          setSelectedStatus(i);
        }
      });
    }

    if (ticketDetail.type) {
      setSelectedType(ticketDetail.type);
    }
    if (ticketDetail.tool > -1) {
      ToolTypeOption.map((i: any) => {
        if (i.id === ticketDetail.tool) {
          setSelectedSoftwareType(i);
          formik.setFieldValue("type", i.tool);
        }
      });
    }

    if (ticketDetail.ticketGroup) {
      setSelectedGroup(ticketDetail.ticketGroup);
    }
  }, [ticketDetail]);

  useEffect(() => {
    if (ticketDetail.software) {
      setSelectedSoftware(ticketDetail.software);
      setSelectedSoftwareVersion(ticketDetail.version);
    }
  }, [ticketDetail, toolList]);

  const onToolChange = (option: any) => {
    setSelectedSoftware(option);
    formik.setFieldValue("version", -1);
    formik.setFieldValue("tool", option.id);
    setSelectedSoftwareVersion({});
    dispatch(getTicketToolVersionList(false, parseInt(option.id), 0));
  };

  const onTicketGroupChange = (option: any) => {
    setSelectedGroup(option);
    editTicket();
  };

  const onToolVersionChange = (option: any) => {
    setSelectedSoftwareVersion(option);
    formik.setFieldValue("version", option.id);
  };

  const editTicket = () => {
    const tcdetail = ticketDetail;
    delete tcdetail.id;
    delete tcdetail.commentList;
    delete tcdetail.ticketGroup;
    delete tcdetail.createdAt;
    delete tcdetail.updatedAt;
    delete tcdetail.reporter;
    const ticketDetails = {
      content: tcdetail.content,
      title: tcdetail.title,
      isActive: tcdetail.isActive,
      isPublic: tcdetail.isPublic,
      software: formik.values.tool,
      tool: formik.values.toolType,
      version: formik.values.version,
      type: selectedType.id,
      status: selectedStatus.id,
      ticketGroupId: selectedGroup.id,
      attachmentList: (tcdetail?.attachmentList || []).map(
        (i: any) => i.attachmentPath,
      ),
    };
    dispatch(editTicketList(true, id, ticketDetails, 0));
  };

  useEffect(() => {
    if (selectedChange) {
      editTicket();
    }
    setChange(false);
  }, [selectedChange]);
  return (
    <>
      <br />

      <div className="mb-3">
        <label>
          <FormattedMessage
            id="ticketAssignee.status"
            defaultMessage="Status"
          />
        </label>
        <Select
          classNamePrefix="react-select-dropdown"
          options={TicketOption}
          value={selectedStatus}
          isDisabled={!(myInfo?.id == ticketDetail?.reporter?.id)}
          onChange={(option: any) => {
            setSelectedStatus(option);
            setChange(true);
          }}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>

      <div className="mb-3">
        <label>
          <FormattedMessage id="ticketAssignee.type" defaultMessage="Type" />
        </label>
        <Select
          classNamePrefix="react-select-dropdown"
          options={IssueType}
          value={selectedType}
          isDisabled={true}
          onChange={(option: any) => {
            setSelectedType(() => option);
            setChange(true);
          }}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>

      <div className="mb-3">
        <label>
          <FormattedMessage
            id="ticketAssignee.assignee"
            defaultMessage="Assignee"
          />
        </label>
        <Select
          classNamePrefix="react-select-dropdown"
          options={ticketAsignee}
          value={selectedGroup}
          isDisabled={true}
          onChange={(option: any) => onTicketGroupChange(option)}
          getOptionLabel={(option: any) => option.group_name}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>

      <div className="mb-3">
        <label>
          <FormattedMessage
            id="ticketAssignee.reporter"
            defaultMessage="Reporter"
          />
        </label>
        <Row className="ml-2">
          <div className="mr-3">
            <Avatar>
              {ticketDetail?.reporter?.englishFirstName
                ? ticketDetail?.reporter?.englishFirstName
                    ?.charAt(0)
                    ?.toUpperCase()
                : ticketDetail?.reporter?.englishLastName
                  ? ticketDetail?.reporter?.englishLastName
                      ?.charAt(0)
                      ?.toUpperCase()
                  : "U"}
            </Avatar>
          </div>
          <label>
            {ticketDetail?.reporter?.englishFirstName &&
              ticketDetail?.reporter?.englishFirstName}{" "}
            {ticketDetail?.reporter?.englishLastName &&
              ticketDetail?.reporter?.englishLastName}
          </label>
        </Row>
      </div>
      <label>
        <FormattedMessage
          id="ticketAssignee.softwareChanges"
          defaultMessage="Software Changes"
        />
      </label>
      <div className="mb-2">
        <label>
          <FormattedMessage
            id="ticketAssignee.software"
            defaultMessage="Software"
          />
        </label>
        <Select
          classNamePrefix="react-select-dropdown"
          options={toolList}
          onChange={onToolChange}
          isDisabled={true}
          onBlur={() => formik.setFieldTouched("tool", true)}
          value={selectedSoftware}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {errors.tool && touched.tool && (
          <div className="error">
            <FormattedMessage
              id={errors.tool.toString()}
              defaultMessage="require"
            />
          </div>
        )}
      </div>

      <div className="mb-2">
        <label>
          <FormattedMessage
            id="ticketAssignee.version"
            defaultMessage="Version"
          />
        </label>
        <Select
          classNamePrefix="react-select-dropdown"
          value={selectedSoftwareVersion}
          onChange={onToolVersionChange}
          onBlur={() => formik.setFieldTouched("version", true)}
          options={toolVersionList}
          isDisabled={true}
          getOptionLabel={(option: any) => option.version}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {errors.version && touched.version && (
          <div className="error">
            <FormattedMessage
              id={errors.version.toString()}
              defaultMessage="require"
            />
          </div>
        )}
      </div>
      <br />
      <br />
      <div>
        <label>
          <FormattedMessage
            id="ticketAssignee.createdAt"
            defaultMessage="Created At"
          />
          :{" "}
          {customFormatDatetimeWithLocale(ticketDetail.createdAt, locale, true)}
        </label>{" "}
        <br />
        <label>
          <FormattedMessage id="updatedAt" defaultMessage="Updated At" />:{" "}
          {customFormatDatetimeWithLocale(ticketDetail.updatedAt, locale, true)}
        </label>{" "}
        <br />
        <label>
          <FormattedMessage id="updated" defaultMessage="Updated" />{" "}
          {formatDateDiff(new Date(), ticketDetail.updatedAt)}{" "}
          <FormattedMessage id="daysAgo" defaultMessage="days ago" />
        </label>
      </div>
    </>
  );
};

export default TicketAssignee;
