/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  LOGIN,
  LOGOUT,
  SET_REGISTER_STEPS,
  SET_CURRENT_STEP_BACK,
  REGISTER,
  CHANGE_LOCAL,
  SET_REGISTER,
  GET_QR_CODE,
  EMAIL_CHECK,
  MOBILE_CHECK,
  EMAIL_VERIFICATION,
  RESEND_OTP,
  FORGOT_PASSWORD,
  FP_EMAIL_VERIFIED,
  CHANGE_PASSWORD,
  SET_THEME,
  ALLOW_UNCHANGED_PASSWORD,
  DIRECT_EMAIL_VERIFICATION,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  USER_SIGNIN,
  USER_SIGNUP,
  GET_QR_CODE_URL,
  CHANGE_PASSWORD_URL,
  EMAIL_CHECK_URL,
  MOBILE_CHECK_URL,
  EMAIL_VERIFICATION_URL,
  RESEND_OTP_URL,
  ALLOW_UNCHANGED_PASSWORD_URL,
  DIRECT_EMAIL_VERIFICATION_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";
import { LOGIN_TYPE } from "../../util/constants/UserGroup";
import moment from "moment";
import { getUnreadAnnouncement } from "./announcementsActions";
import { appIntl } from "../../util/intl";

export const translate =
  (local: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_LOCAL,
      payload: local,
    });
  };

export const set_email =
  (isVerified: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: "EMAIL_VERIFIED",
      payload: isVerified,
    });
  };

export const setTheme =
  (dark: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_THEME,
      payload: dark,
    });
  };

export const setRegister =
  (isRegisterd: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_REGISTER,
      payload: isRegisterd,
    });
  };

export const setRegisterStepBack =
  (step: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_CURRENT_STEP_BACK,
      payload: step,
    });
  };

export const login =
  (
    email: any,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(loginInit(email, password));
  };

export const loginInit =
  (
    email: any,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: LOGIN.LOGIN_INITLIZATION,
    });
    apiCall(
      USER_SIGNIN,
      (res: Object) => dispatch(loginSuccess(res, email, password)),
      (err: any) => {
        dispatch(loginError(err));
      },
      {
        email,
        password,
        type: LOGIN_TYPE.USER,
      },
      METHOD.POST,
      { showToast: false },
    );
  };

export const loginSuccess =
  (
    res: any,
    email: any,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    setToken("login-auth-token", res.data.access_token).then(function () {
      setToken("isLoggedIn", "true");
      const date: any = moment().add(3, "hours").toDate().getTime();
      setToken("exp", date);
      dispatch({
        type: LOGIN.LOGIN_SUCCESS,
        payload: { response: res, email, password },
      });
      dispatch(getUnreadAnnouncement(false));
    });
  };

export const loginError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const isEmailVerified =
      err?.isEmailVerified === undefined ? true : err?.isEmailVerified;
    dispatch({
      type: LOGIN.LOGIN_ERORR,
      payload: {
        msg: err?.message || err?.data?.message,
        email: isEmailVerified,
      },
    });
  };

//logout
export const logout =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(logoutInit(showToast));
  };

export const logoutInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: LOGOUT.LOGOUT_INITLIZATION,
    });
    dispatch(logoutSuccess(showToast));
  };

export const logoutSuccess =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    localStorage.clear();
    if (showToast) {
      toast.success(appIntl().formatMessage({ id: "logout.success" }));
    }
    dispatch({
      type: LOGOUT.LOGOUT_SUCCESS,
      payload: {},
    });
  };

export const logoutError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: LOGOUT.LOGOUT_ERORR,
    });
  };

export const setRegisterSteps =
  (
    step: any,
    userInfo: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_REGISTER_STEPS,
      payload: { completedStep: step, totalStep: 6, userInfo: userInfo },
    });
  };

export const register =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(registerInit(email));
  };

export const registerInit =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER.REGISTER_INITLIZATION,
    });
    apiCall(
      USER_SIGNUP,
      (res: Object) => dispatch(registerSuccess(res)),
      (err: any) => {
        dispatch(registerError());
      },
      email,
      METHOD.POST,
      { showToast: true },
    );
  };

export const registerSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    localStorage.removeItem("register_completion");
    dispatch({
      type: REGISTER.REGISTER_SUCCESS,
      payload: { response: res },
    });
  };

export const registerError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REGISTER.REGISTER_ERORR,
    });
  };
export const emailVerification =
  (
    email: any,
    OTP: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(emailVerificationInit(email, OTP));
  };

export const emailVerificationInit =
  (
    email: any,
    OTP: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EMAIL_VERIFICATION.EMAIL_VERIFICATION_INITIALIZATION,
    });
    apiCall(
      EMAIL_VERIFICATION_URL,
      (res: Object) => dispatch(emailVerificationSuccess(res)),
      (err: any) => {
        dispatch(emailVerificationError());
      },
      { email, OTP },
      METHOD.POST,
      { showToast: true },
    );
  };

export const emailVerificationSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    toast.success(
      "您可以在管理員批准後登錄 You can login after admins approval",
    );
    dispatch({
      type: EMAIL_VERIFICATION.EMAIL_VERIFICATION_SUCCESS,
      payload: { response: res },
    });
  };

export const emailVerificationError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EMAIL_VERIFICATION.EMAIL_VERIFICATION_ERROR,
    });
  };

export const resendOtp =
  (
    email: any,
    resend: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(resendOtpInit(email, resend));
  };

export const resendOtpInit =
  (
    email: any,
    resend: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_INITIALIZATION,
    });
    apiCall(
      RESEND_OTP_URL,
      (res: Object) => dispatch(resendOtpSuccess(res, resend)),
      (err: any) => {
        dispatch(resendOtpError(resend));
      },
      { email },
      METHOD.POST,
      { showToast: true },
    );
  };

export const resendOtpSuccess =
  (
    res: any,
    resend: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_SUCCESS,
      payload: resend,
    });
  };

export const resendOtpError =
  (resend: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_ERROR,
      payload: resend,
    });
  };

export const getQR =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch(getQRInit());
  };

export const getQRInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_QR_CODE.GET_QR_CODE_INITIALIZATION,
    });
    apiCall(
      GET_QR_CODE_URL,
      (res: Object) => dispatch(getQRSuccess(res)),
      (err: any) => {
        dispatch(getQRError());
      },
      {},
      METHOD.GET,
      {},
    );
  };

export const getQRSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    localStorage.removeItem("getQR_completion");
    dispatch({
      type: GET_QR_CODE.GET_QR_CODE_SUCCESS,
      payload: res.data,
    });
  };

export const getQRError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_QR_CODE.GET_QR_CODE_ERROR,
    });
  };

export const checkEmail =
  (email: string): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(checkEmailInit(email));
  };

export const checkEmailInit =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EMAIL_CHECK.EMAIL_CHECK_INITLIZATION,
    });
    apiCall(
      EMAIL_CHECK_URL,
      (res: Object) => dispatch(checkEmailSuccess()),
      (err: any) => {
        dispatch(checkEmailError());
      },
      {
        email: email,
      },
      METHOD.POST,
      {},
    );
  };

export const checkEmailSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EMAIL_CHECK.EMAIL_CHECK_SUCCESS,
    });
  };

export const checkEmailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EMAIL_CHECK.EMAIL_CHECK_ERORR,
    });
  };

export const checkMobile =
  (mobile: string): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(checkMobileInit(mobile));
  };

export const checkMobileInit =
  (mobile: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MOBILE_CHECK.MOBILE_CHECK_INITLIZATION,
    });
    apiCall(
      MOBILE_CHECK_URL,
      (res: Object) => dispatch(checkMobileSuccess()),
      (err: any) => {
        dispatch(checkMobileError());
      },
      {
        phoneNumber: mobile,
      },
      METHOD.POST,
      {},
    );
  };

export const checkMobileSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: MOBILE_CHECK.MOBILE_CHECK_SUCCESS,
    });
  };

export const checkMobileError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: MOBILE_CHECK.MOBILE_CHECK_ERORR,
    });
  };

export const forgotPassword =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(forgotPasswordInit(email));
  };

export const forgotPasswordInit =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION,
    });
    apiCall(
      `users/update-password`,
      (res: Object) => dispatch(forgotPasswordSuccess(res, email)),
      (err: any) => {
        dispatch(forgotPasswordError(err));
      },
      email,
      METHOD.POST,
      { showToast: true },
    );
  };

export const forgotPasswordSuccess =
  (res: any, email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS,
    });
  };

export const forgotPasswordError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR,
      payload: err.data?.message || {},
    });
  };

export const fpEmail =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(fpEmailInit(email));
  };

export const fpEmailInit =
  (email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FP_EMAIL_VERIFIED.FP_EMAIL_VERIFIED_INITLIZATION,
    });
    apiCall(
      `users/forget-password`,
      (res: Object) => dispatch(fpEmailSuccess(res, email)),
      (err: any) => {
        dispatch(fpEmailError(err));
      },
      {
        email,
      },
      METHOD.POST,
      { showToast: true },
    );
  };

export const fpEmailSuccess =
  (res: any, email: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FP_EMAIL_VERIFIED.FP_EMAIL_VERIFIED_SUCCESS,
    });
  };

export const fpEmailError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: FP_EMAIL_VERIFIED.FP_EMAIL_VERIFIED_ERORR,
      payload: err?.message || err?.data?.message,
    });
  };

export const changePassword =
  (
    showToast = true,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(changePasswordInit(showToast, password));
  };

export const changePasswordInit =
  (
    showToast: boolean,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION,
    });
    apiCall(
      CHANGE_PASSWORD_URL,
      (res: Object) => dispatch(changePasswordSuccess()),
      (err: any) => {
        dispatch(changePasswordError(err));
      },
      password,
      METHOD.PATCH,
      { showToast: showToast },
    );
  };

export const changePasswordSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
    });
  };

export const changePasswordError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_ERORR,
      payload: err.message,
    });
  };

export const setPasswordAlert =
  (
    showToast = true,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(setPasswordAlertInit(showToast, password));
  };

export const setPasswordAlertInit =
  (
    showToast: boolean,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ALLOW_UNCHANGED_PASSWORD.ALLOW_UNCHANGED_PASSWORD_INITIALIZATION,
    });
    apiCall(
      ALLOW_UNCHANGED_PASSWORD_URL,
      (res: Object) => dispatch(setPasswordAlertSuccess()),
      (err: any) => {
        dispatch(setPasswordAlertError(err));
      },
      password,
      METHOD.PUT,
      { showToast: showToast },
    );
  };

export const setPasswordAlertSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ALLOW_UNCHANGED_PASSWORD.ALLOW_UNCHANGED_PASSWORD_SUCCESS,
    });
  };

export const setPasswordAlertError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ALLOW_UNCHANGED_PASSWORD.ALLOW_UNCHANGED_PASSWORD_ERROR,
      payload: err.message,
    });
  };

export const directEmailVerification =
  (directEmail: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(directEmailVerificationInit(directEmail));
  };

export const directEmailVerificationInit =
  (directEmail: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DIRECT_EMAIL_VERIFICATION.DIRECT_EMAIL_VERIFICATION_INITIALIZATION,
    });
    apiCall(
      DIRECT_EMAIL_VERIFICATION_URL,
      (res: Object) => dispatch(directEmailVerificationSuccess(res)),
      (err: any) => {
        dispatch(directEmailVerificationError());
      },
      directEmail,
      METHOD.POST,
      { showToast: true },
    );
  };

export const directEmailVerificationSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DIRECT_EMAIL_VERIFICATION.DIRECT_EMAIL_VERIFICATION_SUCCESS,
      payload: { response: res },
    });
  };

export const directEmailVerificationError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DIRECT_EMAIL_VERIFICATION.DIRECT_EMAIL_VERIFICATION_ERROR,
    });
  };
