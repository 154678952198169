/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import { GET_FAQ_LIST } from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { GET_ALL_FAQ_LIST_URL } from "../../service/apiEndPoints";

//Get All Faq List
export const getFAQList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getFAQListInit(showToast));
  };

export const getFAQListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_FAQ_LIST.GET_FAQ_LIST_INITIALIZATION,
    });
    apiCall(
      GET_ALL_FAQ_LIST_URL,
      (res: Object) => dispatch(getFAQListSuccess(res)),
      (err: any) => dispatch(getFAQListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getFAQListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_FAQ_LIST.GET_FAQ_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getFAQListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_FAQ_LIST.GET_FAQ_LIST_ERROR,
    });
  };
