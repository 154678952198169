import React from "react";
import ManagementHeader from "./ManagementHeader";
import ManagementRow, {
  ChangeVpsStatus,
  type UpdateDetailsPayload,
  type VpsDetail,
} from "./ManagementRow";

interface ManagementTableProps {
  pageNumber: number;
  pageLimit: number;
  sortBy: string;
  sortOrder: string;
  setSorting: React.Dispatch<React.SetStateAction<string>>;
  allVpsMachinesList: VpsDetail[];
  setChangeVpsStatus: React.Dispatch<React.SetStateAction<ChangeVpsStatus>>;
  setUpdateDetailsPayload: React.Dispatch<
    React.SetStateAction<UpdateDetailsPayload>
  >;
}

const ManagementTable = (props: ManagementTableProps) => {
  const {
    pageNumber,
    pageLimit,
    sortBy,
    sortOrder,
    setSorting,
    allVpsMachinesList,
    setChangeVpsStatus,
    setUpdateDetailsPayload,
  } = props;

  return (
    <table className="table table-hover m-0">
      <ManagementHeader
        sortBy={sortBy}
        sortOrder={sortOrder}
        setSorting={setSorting}
      />
      <tbody>
        {allVpsMachinesList.map((vpsDetail, i) => (
          <ManagementRow
            rowNumber={(pageNumber - 1) * pageLimit + i + 1}
            vpsDetail={vpsDetail}
            setChangeVpsStatus={setChangeVpsStatus}
            setUpdateDetailsPayload={setUpdateDetailsPayload}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ManagementTable;
