const REGISTER = {
  REGISTER_INITLIZATION: "REGISTER_INITLIZATION",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERORR: "REGISTER_ERORR",
};

const LOGIN = {
  LOGIN_INITLIZATION: "LOGIN_INITLIZATION",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERORR: "LOGIN_ERORR",
};

const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_INITLIZATION: "FORGOT_PASSWORD_INITLIZATION",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERORR: "FORGOT_PASSWORD_ERORR",
};

const FP_EMAIL_VERIFIED = {
  FP_EMAIL_VERIFIED_INITLIZATION: "FP_EMAIL_VERIFIED_INITLIZATION",
  FP_EMAIL_VERIFIED_SUCCESS: "FP_EMAIL_VERIFIED_SUCCESS",
  FP_EMAIL_VERIFIED_ERORR: "FP_EMAIL_VERIFIED_ERORR",
};

const EMAIL_CHECK = {
  EMAIL_CHECK_INITLIZATION: "EMAIL_CHECK_INITLIZATION",
  EMAIL_CHECK_SUCCESS: "EMAIL_CHECK_SUCCESS",
  EMAIL_CHECK_ERORR: "EMAIL_CHECK_ERORR",
};

const MOBILE_CHECK = {
  MOBILE_CHECK_INITLIZATION: "MOBILE_CHECK_INITLIZATION",
  MOBILE_CHECK_SUCCESS: "MOBILE_CHECK_SUCCESS",
  MOBILE_CHECK_ERORR: "MOBILE_CHECK_ERORR",
};

const GET_INDUSTRY_LIST = {
  GET_INDUSTRY_LIST_INITLIZATION: "GET_INDUSTRY_LIST_INITLIZATION",
  GET_INDUSTRY_LIST_SUCCESS: "GET_INDUSTRY_LIST_SUCCESS",
  GET_INDUSTRY_LIST_ERORR: "GET_INDUSTRY_LIST_ERORR",
};

const GET_JOB_LIST = {
  GET_JOB_LIST_INITLIZATION: "GET_JOB_LIST_INITLIZATION",
  GET_JOB_LIST_SUCCESS: "GET_JOB_LIST_SUCCESS",
  GET_JOB_LIST_ERORR: "GET_JOB_LIST_ERORR",
};

const LOGOUT = {
  LOGOUT_INITLIZATION: "LOGOUT_INITLIZATION",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERORR: "LOGOUT_ERORR",
};

const SET_REGISTER_STEPS = "SET_REGISTER_STEPS";

const GET_GIFT_LIST = {
  GET_GIFT_LIST_INITIALIZATION: "GET_GIFT_LIST_INITIALIZATION",
  GET_GIFT_LIST_SUCCESS: "GET_GIFT_LIST_SUCCESS",
  GET_GIFT_LIST_ERROR: "GET_GIFT_LIST_ERROR",
};

const GET_GIFT_DETAILS = {
  GET_GIFT_DETAILS_INITIALIZATION: "GET_GIFT_DETAILS_INITIALIZATION",
  GET_GIFT_DETAILS_SUCCESS: "GET_GIFT_DETAILS_SUCCESS",
  GET_GIFT_DETAILS_ERROR: "GET_GIFT_LIST_ERROR",
};

const ADD_GIFT_LIST = {
  ADD_GIFT_LIST_INITIALIZATION: "ADD_GIFT_LIST_INITIALIZATION",
  ADD_GIFT_LIST_SUCCESS: "ADD_GIFT_LIST_SUCCESS",
  ADD_GIFT_LIST_ERROR: "ADD_GIFT_LIST_ERROR",
};

const REDEEM_GIFT_LIST = {
  REDEEM_GIFT_LIST_INITIALIZATION: "REDEEM_GIFT_LIST_INITIALIZATION",
  REDEEM_GIFT_LIST_SUCCESS: "REDEEM_GIFT_LIST_SUCCESS",
  REDEEM_GIFT_LIST_ERROR: "REDEEM_GIFT_LIST_ERROR",
};

const GET_TICKET_LIST = {
  GET_TICKET_LIST_INITIALIZATION: "GET_TICKET_LIST_INITIALIZATION",
  GET_TICKET_LIST_SUCCESS: "GET_TICKET_LIST_SUCCESS",
  GET_TICKET_LIST_ERROR: "GET_TICKET_LIST_ERROR",
};

const GET_TICKET_DETAILS = {
  GET_TICKET_DETAILS_INITIALIZATION: "GET_TICKET_DETAILS_INITIALIZATION",
  GET_TICKET_DETAILS_SUCCESS: "GET_TICKET_DETAILS_SUCCESS",
  GET_TICKET_DETAILS_ERROR: "GET_TICKET_LIST_ERROR",
};

const ADD_TICKET_LIST = {
  ADD_TICKET_LIST_INITIALIZATION: "ADD_TICKET_LIST_INITIALIZATION",
  ADD_TICKET_LIST_SUCCESS: "ADD_TICKET_LIST_SUCCESS",
  ADD_TICKET_LIST_ERROR: "ADD_TICKET_LIST_ERROR",
};

const EDIT_TICKET_LIST = {
  EDIT_TICKET_LIST_INITIALIZATION: "EDIT_TICKET_LIST_INITIALIZATION",
  EDIT_TICKET_LIST_SUCCESS: "EDIT_TICKET_LIST_SUCCESS",
  EDIT_TICKET_LIST_ERROR: "EDIT_TICKET_LIST_ERROR",
};

const GET_TICKET_ISSUE_TYPE_LIST = {
  GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION:
    "GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION",
  GET_TICKET_ISSUE_TYPE_LIST_SUCCESS: "GET_TICKET_ISSUE_TYPE_LIST_SUCCESS",
  GET_TICKET_ISSUE_TYPE_LIST_ERROR: "GET_TICKET_ISSUE_TYPE_LIST_ERROR",
};

const GET_TICKET_COMMENT_LIST = {
  GET_TICKET_COMMENT_LIST_INITIALIZATION:
    "GET_TICKET_COMMENT_LIST_INITIALIZATION",
  GET_TICKET_COMMENT_LIST_SUCCESS: "GET_TICKET_COMMENT_LIST_SUCCESS",
  GET_TICKET_COMMENT_LIST_ERROR: "GET_TICKET_COMMENT_LIST_ERROR",
};

const ADD_TICKET_COMMENT_LIST = {
  ADD_TICKET_COMMENT_LIST_INITIALIZATION:
    "ADD_TICKET_COMMENT_LIST_INITIALIZATION",
  ADD_TICKET_COMMENT_LIST_SUCCESS: "ADD_TICKET_COMMENT_LIST_SUCCESS",
  ADD_TICKET_COMMENT_LIST_ERROR: "ADD_TICKET_COMMENT_LIST_ERROR",
};

const GET_VPS_MACHINE_LIST = {
  GET_VPS_MACHINE_LIST_INITIALIZATION: "GET_VPS_MACHINE_LIST_INITIALIZATION",
  GET_VPS_MACHINE_LIST_SUCCESS: "GET_VPS_MACHINE_LIST_SUCCESS",
  GET_VPS_MACHINE_LIST_ERROR: "GET_VPS_MACHINE_LIST_ERROR",
};

const VPS_MACHINE_CONTACT_LIST = {
  VPS_MACHINE_CONTACT_LIST_INITIALIZATION:
    "VPS_MACHINE_CONTACT_LIST_INITIALIZATION",
  VPS_MACHINE_CONTACT_LIST_SUCCESS: "VPS_MACHINE_CONTACT_LIST_SUCCESS",
  VPS_MACHINE_CONTACT_LIST_ERROR: "VPS_MACHINE_CONTACT_LIST_ERROR",
};

const GET_VPS_MACHINE_TYPE_LIST = {
  GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION:
    "GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION",
  GET_VPS_MACHINE_TYPE_LIST_SUCCESS: "GET_VPS_MACHINE_TYPE_LIST_SUCCESS",
  GET_VPS_MACHINE_TYPE_LIST_ERROR: "GET_VPS_MACHINE_TYPE_LIST_ERROR",
};

const CREATE_VPS_MACHINE = {
  CREATE_VPS_MACHINE_INITIALIZATION: "CREATE_VPS_MACHINE_INITIALIZATION",
  CREATE_VPS_MACHINE_SUCCESS: "CREATE_VPS_MACHINE_SUCCESS",
  CREATE_VPS_MACHINE_ERROR: "CREATE_VPS_MACHINE_ERROR",
};

const GET_ANNOUNCEMENT_LIST = {
  GET_ANNOUNCEMENT_LIST_INITLIZATION: "GET_ANNOUNCEMENT_LIST_INITLIZATION",
  GET_ANNOUNCEMENT_LIST_SUCCESS: "GET_ANNOUNCEMENT_LIST_SUCCESS",
  GET_ANNOUNCEMENT_LIST_ERORR: "GET_ANNOUNCEMENT_LIST_ERORR",
};

const ADD_ANNOUNCEMENT_COMMENT = {
  ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION:
    "ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION",
  ADD_ANNOUNCEMENT_COMMENT_SUCCESS: "ADD_ANNOUNCEMENT_COMMENT_SUCCESS",
  ADD_ANNOUNCEMENT_COMMENT_ERROR: "ADD_ANNOUNCEMENT_COMMENT_ERROR",
};

const GET_ANNOUNCEMENT_COMMENT = {
  GET_ANNOUNCEMENT_COMMENT_INITIALIZATION:
    "GET_ANNOUNCEMENT_COMMENT_INITIALIZATION",
  GET_ANNOUNCEMENT_COMMENT_SUCCESS: "GET_ANNOUNCEMENT_COMMENT_SUCCESS",
  GET_ANNOUNCEMENT_COMMENT_ERROR: "GET_ANNOUNCEMENT_COMMENT_ERROR",
};

const GET_ANNOUNCEMENT_DETAILS = {
  GET_ANNOUNCEMENT_DETAILS_INITIALIZATION:
    "GET_ANNOUNCEMENT_DETAILS_INITIALIZATION",
  GET_ANNOUNCEMENT_DETAILS_SUCCESS: "GET_ANNOUNCEMENT_DETAILS_SUCCESS",
  GET_ANNOUNCEMENT_DETAILS_ERROR: "GET_ANNOUNCEMENT_LIST_ERROR",
};

const ADD_ANNOUNCEMENT = {
  ADD_ANNOUNCEMENT_INITIALIZATION: "ADD_ANNOUNCEMENT_INITIALIZATION",
  ADD_ANNOUNCEMENT_SUCCESS: "ADD_ANNOUNCEMENT_SUCCESS",
  ADD_ANNOUNCEMENT_ERROR: "ADD_ANNOUNCEMENT_ERROR",
};

const READ_ANNOUNCEMENT = {
  READ_ANNOUNCEMENT_INITIALIZATION: "READ_ANNOUNCEMENT_INITIALIZATION",
  READ_ANNOUNCEMENT_SUCCESS: "READ_ANNOUNCEMENT_SUCCESS",
  READ_ANNOUNCEMENT_ERROR: "READ_ANNOUNCEMENT_ERROR",
};

const GET_CLASSES = {
  GET_CLASSES_INITIALIZATION: "GET_CLASSES_INITIALIZATION",
  GET_CLASSES_SUCCESS: "GET_CLASSES_SUCCESS",
  GET_CLASSES_ERROR: "GET_CLASSES_ERROR",
};

const GET_ALL_CLASSES = {
  GET_ALL_CLASSES_INITIALIZATION: "GET_ALL_CLASSES_INITIALIZATION",
  GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_SUCCESS",
  GET_ALL_CLASSES_ERROR: "GET_ALL_CLASSES_ERROR",
};

const REGISTER_STUDENT_IN_CLASS = {
  REGISTER_STUDENT_IN_CLASS_INITIALIZATION:
    "REGISTER_STUDENT_IN_CLASS_INITIALIZATION",
  REGISTER_STUDENT_IN_CLASS_SUCCESS: "REGISTER_STUDENT_IN_CLASS_SUCCESS",
  REGISTER_STUDENT_IN_CLASS_ERROR: "REGISTER_STUDENT_IN_CLASS_ERROR",
};

const GET_CLASSES_DETAIL = {
  GET_CLASSES_DETAIL_INITIALIZATION: "GET_CLASSES_DETAIL_INITIALIZATION",
  GET_CLASSES_DETAIL_SUCCESS: "GET_CLASSES_DETAIL_SUCCESS",
  GET_CLASSES_DETAIL_ERROR: "GET_CLASSES_DETAIL_ERROR",
};

const GET_ACTIVITY_LIST = {
  GET_ACTIVITY_LIST_INITIALIZATION: "GET_ACTIVITY_LIST_INITIALIZATION",
  GET_ACTIVITY_LIST_SUCCESS: "GET_ACTIVITY_LIST_SUCCESS",
  GET_ACTIVITY_LIST_ERROR: "GET_ACTIVITY_LIST_ERROR",
};

const REGISTER_ACTIVITY_LIST = {
  REGISTER_ACTIVITY_LIST_INITIALIZATION:
    "REGISTER_ACTIVITY_LIST_INITIALIZATION",
  REGISTER_ACTIVITY_LIST_SUCCESS: "REGISTER_ACTIVITY_LIST_SUCCESS",
  REGISTER_ACTIVITY_LIST_ERROR: "REGISTER_ACTIVITY_LIST_ERROR",
};

const GET_ATTENDENCE_LIST = {
  GET_ATTENDENCE_LIST_INITIALIZATION: "GET_ATTENDENCE_LIST_INITIALIZATION",
  GET_ATTENDENCE_LIST_SUCCESS: "GET_ATTENDENCE_LIST_SUCCESS",
  GET_ATTENDENCE_LIST_ERROR: "GET_ATTENDENCE_LIST_ERROR",
};

const GET_POINTS_LIST = {
  GET_POINTS_LIST_INITIALIZATION: "GET_POINTS_LIST_INITIALIZATION",
  GET_POINTS_LIST_SUCCESS: "GET_POINTS_LIST_SUCCESS",
  GET_POINTS_LIST_ERROR: "GET_POINTS_LIST_ERROR",
};

const REDEEM_ACCOUNT = {
  REDEEM_ACCOUNT_INITIALIZATION: "REDEEM_ACCOUNT_INITIALIZATION",
  REDEEM_ACCOUNT_SUCCESS: "REDEEM_ACCOUNT_SUCCESS",
  REDEEM_ACCOUNT_ERROR: "REDEEM_ACCOUNT_ERROR",
};

const MEDIA_IMAGE_UPLOAD = {
  MEDIA_IMAGE_UPLOAD_INITIALIZATION: "MEDIA_IMAGE_UPLOAD_INITIALIZATION",
  MEDIA_IMAGE_UPLOAD_SUCCESS: "MEDIA_IMAGE_UPLOAD_SUCCESS",
  MEDIA_IMAGE_UPLOAD_ERROR: "MEDIA_IMAGE_UPLOAD_ERROR",
};

const GET_REGISTRATION_MATERIAL = {
  GET_REGISTRATION_MATERIAL_INITIALIZATION:
    "GET_REGISTRATION_MATERIAL_INITIALIZATION",
  GET_REGISTRATION_MATERIAL_SUCCESS: "GET_REGISTRATION_MATERIAL_SUCCESS",
  GET_REGISTRATION_MATERIAL_ERROR: "GET_REGISTRATION_MATERIAL_ERROR",
};

const GET_EA_LIST = {
  GET_EA_LIST_INITIALIZATION: "GET_EA_LIST_INITIALIZATION",
  GET_EA_LIST_SUCCESS: "GET_EA_LIST_SUCCESS",
  GET_EA_LIST_ERROR: "GET_EA_LIST_ERROR",
};

const GET_EA_UNAUTHORIZED_LIST = {
  GET_EA_UNAUTHORIZED_LIST_INITIALIZATION:
    "GET_EA_UNAUTHORIZED_LIST_INITIALIZATION",
  GET_EA_UNAUTHORIZED_LIST_SUCCESS: "GET_EA_UNAUTHORIZED_LIST_SUCCESS",
  GET_EA_UNAUTHORIZED_LIST_ERROR: "GET_EA_UNAUTHORIZED_LIST_ERROR",
};

const GET_EA_VERSION_LIST = {
  GET_EA_VERSION_LIST_INITIALIZATION: "GET_EA_VERSION_LIST_INITIALIZATION",
  GET_EA_VERSION_LIST_SUCCESS: "GET_EA_VERSION_LIST_SUCCESS",
  GET_EA_VERSION_LIST_ERROR: "GET_EA_VERSION_LIST_ERROR",
};

const GET_CLASS_EXPERT_ADVISORS_LIST = {
  GET_CLASS_EXPERT_ADVISORS_LIST_INITIALIZATION:
    "GET_CLASS_EXPERT_ADVISORS_LIST_INITIALIZATION",
  GET_CLASS_EXPERT_ADVISORS_LIST_SUCCESS:
    "GET_CLASS_EXPERT_ADVISORS_LIST_SUCCESS",
  GET_CLASS_EXPERT_ADVISORS_LIST_ERROR: "GET_CLASS_EXPERT_ADVISORS_LIST_ERROR",
};

const GET_LIBRARY_VERSION_LIST = {
  GET_LIBRARY_VERSION_LIST_INITIALIZATION:
    "GET_LIBRARY_VERSION_LIST_INITIALIZATION",
  GET_LIBRARY_VERSION_LIST_SUCCESS: "GET_LIBRARY_VERSION_LIST_SUCCESS",
  GET_LIBRARY_VERSION_LIST_ERROR: "GET_LIBRARY_VERSION_LIST_ERROR",
};

const GET_UTILITY_VERSION_LIST = {
  GET_UTILITY_VERSION_LIST_INITIALIZATION:
    "GET_UTILITY_VERSION_LIST_INITIALIZATION",
  GET_UTILITY_VERSION_LIST_SUCCESS: "GET_UTILITY_VERSION_LIST_SUCCESS",
  GET_UTILITY_VERSION_LIST_ERROR: "GET_UTILITY_VERSION_LIST_ERROR",
};

const GET_SCRIPT_VERSION_LIST = {
  GET_SCRIPT_VERSION_LIST_INITIALIZATION:
    "GET_SCRIPT_VERSION_LIST_INITIALIZATION",
  GET_SCRIPT_VERSION_LIST_SUCCESS: "GET_SCRIPT_VERSION_LIST_SUCCESS",
  GET_SCRIPT_VERSION_LIST_ERROR: "GET_SCRIPT_VERSION_LIST_ERROR",
};

const GET_INDICATOR_VERSION_LIST = {
  GET_INDICATOR_VERSION_LIST_INITIALIZATION:
    "GET_INDICATOR_VERSION_LIST_INITIALIZATION",
  GET_INDICATOR_VERSION_LIST_SUCCESS: "GET_INDICATOR_VERSION_LIST_SUCCESS",
  GET_INDICATOR_VERSION_LIST_ERROR: "GET_INDICATOR_VERSION_LIST_ERROR",
};

const GET_INDICATORS_LIST = {
  GET_INDICATORS_LIST_INITIALIZATION: "GET_INDICATORS_LIST_INITIALIZATION",
  GET_INDICATORS_LIST_SUCCESS: "GET_INDICATORS_LIST_SUCCESS",
  GET_INDICATORS_LIST_ERROR: "GET_INDICATORS_LIST_ERROR",
};

const GET_UTILITY_LIST = {
  GET_UTILITY_LIST_INITIALIZATION: "GET_UTILITY_LIST_INITIALIZATION",
  GET_UTILITY_LIST_SUCCESS: "GET_UTILITY_LIST_SUCCESS",
  GET_UTILITY_LIST_ERROR: "GET_UTILITY_LIST_ERROR",
};

const GET_SCRIPT_LIST = {
  GET_SCRIPT_LIST_INITIALIZATION: "GET_SCRIPT_LIST_INITIALIZATION",
  GET_SCRIPT_LIST_SUCCESS: "GET_SCRIPT_LIST_SUCCESS",
  GET_SCRIPT_LIST_ERROR: "GET_SCRIPT_LIST_ERROR",
};

const GET_LIBRARY_LIST = {
  GET_LIBRARY_LIST_INITIALIZATION: "GET_LIBRARY_LIST_INITIALIZATION",
  GET_LIBRARY_LIST_SUCCESS: "GET_LIBRARY_LIST_SUCCESS",
  GET_LIBRARY_LIST_ERROR: "GET_LIBRARY_LIST_ERROR",
};

const GET_TEMPOARARY_ACCOUNT_LIST = {
  GET_TEMPOARARY_ACCOUNT_LIST_INITIALIZATION:
    "GET_TEMPOARARY_ACCOUNT_LIST_INITIALIZATION",
  GET_TEMPOARARY_ACCOUNT_LIST_SUCCESS: "GET_TEMPOARARY_ACCOUNT_LIST_SUCCESS",
  GET_TEMPOARARY_ACCOUNT_LIST_ERROR: "GET_TEMPOARARY_ACCOUNT_LIST_ERROR",
};

const GET_DEMO_ACCOUNT_LIST = {
  GET_DEMO_ACCOUNT_LIST_INITIALIZATION: "GET_DEMO_ACCOUNT_LIST_INITIALIZATION",
  GET_DEMO_ACCOUNT_LIST_SUCCESS: "GET_DEMO_ACCOUNT_LIST_SUCCESS",
  GET_DEMO_ACCOUNT_LIST_ERROR: "GET_DEMO_ACCOUNT_LIST_ERROR",
};

const GET_ALL_ACCOUNT_LIST = {
  GET_ALL_ACCOUNT_LIST_INITIALIZATION: "GET_ALL_ACCOUNT_LIST_INITIALIZATION",
  GET_ALL_ACCOUNT_LIST_SUCCESS: "GET_ALL_ACCOUNT_LIST_SUCCESS",
  GET_ALL_ACCOUNT_LIST_ERROR: "GET_ALL_ACCOUNT_LIST_ERROR",
};

const ADD_ACCOUNT_LIST = {
  ADD_ACCOUNT_LIST_INITIALIZATION: "ADD_ACCOUNT_LIST_INITIALIZATION",
  ADD_ACCOUNT_LIST_SUCCESS: "ADD_ACCOUNT_LIST_SUCCESS",
  ADD_ACCOUNT_LIST_ERROR: "ADD_ACCOUNT_LIST_ERROR",
};

const DELETE_ACCOUNT_LIST = {
  DELETE_ACCOUNT_LIST_INITIALIZATION: "DELETE_ACCOUNT_LIST_INITIALIZATION",
  DELETE_ACCOUNT_LIST_SUCCESS: "DELETE_ACCOUNT_LIST_SUCCESS",
  DELETE_ACCOUNT_LIST_ERROR: "DELETE_ACCOUNT_LIST_ERROR",
};

const GET_LIVE_ACCOUNT_LIST = {
  GET_LIVE_ACCOUNT_LIST_INITIALIZATION: "GET_LIVE_ACCOUNT_LIST_INITIALIZATION",
  GET_LIVE_ACCOUNT_LIST_SUCCESS: "GET_LIVE_ACCOUNT_LIST_SUCCESS",
  GET_LIVE_ACCOUNT_LIST_ERROR: "GET_LIVE_ACCOUNT_LIST_ERROR",
};

const GET_MY_SIGNAL_LIST = {
  GET_MY_SIGNAL_LIST_INITIALIZATION: "GET_MY_SIGNAL_LIST_INITIALIZATION",
  GET_MY_SIGNAL_LIST_SUCCESS: "GET_MY_SIGNAL_LIST_SUCCESS",
  GET_MY_SIGNAL_LIST_ERROR: "GET_MY_SIGNAL_LIST_ERROR",
};

const GET_CLASS_SIGNAL_LIST = {
  GET_CLASS_SIGNAL_LIST_INITIALIZATION: "GET_CLASS_SIGNAL_LIST_INITIALIZATION",
  GET_CLASS_SIGNAL_LIST_SUCCESS: "GET_CLASS_SIGNAL_LIST_SUCCESS",
  GET_CLASS_SIGNAL_LIST_ERROR: "GET_CLASS_SIGNAL_LIST_ERROR",
};

const ADD_CLASS_SIGNAL = {
  ADD_CLASS_SIGNAL_INITIALIZATION: "ADD_CLASS_SIGNAL_INITIALIZATION",
  ADD_CLASS_SIGNAL_SUCCESS: "ADD_CLASS_SIGNAL_SUCCESS",
  ADD_CLASS_SIGNAL_ERROR: "ADD_CLASS_SIGNAL_ERROR",
};

const EDIT_CLASS_SIGNAL = {
  EDIT_CLASS_SIGNAL_INITIALIZATION: "EDIT_CLASS_SIGNAL_INITIALIZATION",
  EDIT_CLASS_SIGNAL_SUCCESS: "EDIT_CLASS_SIGNAL_SUCCESS",
  EDIT_CLASS_SIGNAL_ERROR: "EDIT_CLASS_SIGNAL_ERROR",
};

const DELETE_CLASS_SIGNAL = {
  DELETE_CLASS_SIGNAL_INITIALIZATION: "DELETE_CLASS_SIGNAL_INITIALIZATION",
  DELETE_CLASS_SIGNAL_SUCCESS: "DELETE_CLASS_SIGNAL_SUCCESS",
  DELETE_CLASS_SIGNAL_ERROR: "DELETE_CLASS_SIGNAL_ERROR",
};

const CHANGE_LOCAL = "CHANGE_LOCAL";
const SET_THEME = "SET_THEME";
const SET_REGISTER = "SET_REGISTER";
const SET_CURRENT_STEP_BACK = "SET_CURRENT_STEP_BACK";

const GET_USER_REGISTERD_CLASS = {
  GET_USER_REGISTERD_CLASS_INITIALIZATION:
    "GET_USER_REGISTERD_CLASS_INITIALIZATION",
  GET_USER_REGISTERD_CLASS_SUCCESS: "GET_USER_REGISTERD_CLASS_SUCCESS",
  GET_USER_REGISTERD_CLASS_ERROR: "GET_USER_REGISTERD_CLASS_ERROR",
};

const GET_TICKET_TOOL_LIST = {
  GET_TICKET_TOOL_LIST_INITIALIZATION: "GET_TICKET_TOOL_LIST_INITIALIZATION",
  GET_TICKET_TOOL_LIST_SUCCESS: "GET_TICKET_TOOL_LIST_SUCCESS",
  GET_TICKET_TOOL_LIST_ERROR: "GET_TICKET_TOOL_LIST_ERROR",
};

const GET_TICKET_TOOL_VERSION_LIST = {
  GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION:
    "GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION",
  GET_TICKET_TOOL_VERSION_LIST_SUCCESS: "GET_TICKET_TOOL_VERSION_LIST_SUCCESS",
  GET_TICKET_TOOL_VERSION_LIST_ERROR: "GET_TICKET_TOOL_VERSION_LIST_ERROR",
};

const GET_QR_CODE = {
  GET_QR_CODE_INITIALIZATION: "GET_QR_CODE_INITIALIZATION",
  GET_QR_CODE_SUCCESS: "GET_QR_CODE_SUCCESS",
  GET_QR_CODE_ERROR: "GET_QR_CODE_ERROR",
};

const GET_UNAUTHORIZED_INDICATORS_LIST = {
  GET_UNAUTHORIZED_INDICATORS_LIST_INITIALIZATION:
    "GET_UNAUTHORIZED_INDICATORS_LIST_INITIALIZATION",
  GET_UNAUTHORIZED_INDICATORS_LIST_SUCCESS:
    "GET_UNAUTHORIZED_INDICATORS_LIST_SUCCESS",
  GET_UNAUTHORIZED_INDICATORS_LIST_ERROR:
    "GET_UNAUTHORIZED_INDICATORS_LIST_ERROR",
};

const GET_UNAUTHORIZED_UTILITY_LIST = {
  GET_UNAUTHORIZED_UTILITY_LIST_INITIALIZATION:
    "GET_UNAUTHORIZED_UTILITY_LIST_INITIALIZATION",
  GET_UNAUTHORIZED_UTILITY_LIST_SUCCESS:
    "GET_UNAUTHORIZED_UTILITY_LIST_SUCCESS",
  GET_UNAUTHORIZED_UTILITY_LIST_ERROR: "GET_UNAUTHORIZED_UTILITY_LIST_ERROR",
};

const GET_UNAUTHORIZED_SCRIPT_LIST = {
  GET_UNAUTHORIZED_SCRIPT_LIST_INITIALIZATION:
    "GET_UNAUTHORIZED_SCRIPT_LIST_INITIALIZATION",
  GET_UNAUTHORIZED_SCRIPT_LIST_SUCCESS: "GET_UNAUTHORIZED_SCRIPT_LIST_SUCCESS",
  GET_UNAUTHORIZED_SCRIPT_LIST_ERROR: "GET_UNAUTHORIZED_SCRIPT_LIST_ERROR",
};

const UPLOAD_CLASS_SLIP = {
  UPLOAD_CLASS_SLIP_INITIALIZATION: "UPLOAD_CLASS_SLIP_INITIALIZATION",
  UPLOAD_CLASS_SLIP_SUCCESS: "UPLOAD_CLASS_SLIP_SUCCESS",
  UPLOAD_CLASS_SLIP_ERROR: "UPLOAD_CLASS_SLIP_ERROR",
};

const EMAIL_VERIFICATION = {
  EMAIL_VERIFICATION_INITIALIZATION: "EMAIL_VERIFICATION_INITIALIZATION",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  EMAIL_VERIFICATION_ERROR: "EMAIL_VERIFICATION_ERROR",
};

const DIRECT_EMAIL_VERIFICATION = {
  DIRECT_EMAIL_VERIFICATION_INITIALIZATION:
    "DIRECT_EMAIL_VERIFICATION_INITIALIZATION",
  DIRECT_EMAIL_VERIFICATION_SUCCESS: "DIRECT_EMAIL_VERIFICATION_SUCCESS",
  DIRECT_EMAIL_VERIFICATION_ERROR: "DIRECT_EMAIL_VERIFICATION_ERROR",
};

const RESEND_OTP = {
  RESEND_OTP_INITIALIZATION: "RESEND_OTP_INITIALIZATION",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERROR: "RESEND_OTP_ERROR",
};

const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_INITLIZATION: "CHANGE_PASSWORD_INITLIZATION",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERORR: "CHANGE_PASSWORD_ERORR",
};

const UPALOD_MEDIA_LIST = {
  UPALOD_MEDIA_LIST_INITIALIZATION: "UPALOD_MEDIA_LIST_INITIALIZATION",
  UPALOD_MEDIA_LIST_SUCCESS: "UPALOD_MEDIA_LIST_SUCCESS",
  UPALOD_MEDIA_LIST_ERROR: "UPALOD_MEDIA_LIST_ERROR",
};

const DELETE_MEDIA_LOCAL = {
  DELETE_MEDIA_LOCAL_INITIALIZATION: "DELETE_MEDIA_LOCAL_INITIALIZATION",
  DELETE_MEDIA_LOCAL_SUCCESS: "DELETE_MEDIA_LOCAL_SUCCESS",
  DELETE_MEDIA_LOCAL_ERROR: "DELETE_MEDIA_LOCAL_ERROR",
};

const DELETE_MEDIA_AMAZONS3 = {
  DELETE_MEDIA_AMAZONS3_INITIALIZATION: "DELETE_MEDIA_AMAZONS3_INITIALIZATION",
  DELETE_MEDIA_AMAZONS3_SUCCESS: "DELETE_MEDIA_AMAZONS3_SUCCESS",
  DELETE_MEDIA_AMAZONS3_ERROR: "DELETE_MEDIA_AMAZONS3_ERROR",
};

const SET_MEDIA_UPLOAD_PROGRESS = "SET_MEDIA_UPLOAD_PROGRESS";
const SET_MEDIA_UPLOAD_EMPTY = "SET_MEDIA_UPLOAD_EMPTY";

const GET_ALTERNATIVE_NAME_LIST = {
  GET_ALTERNATIVE_NAME_LIST_INITIALIZATION:
    "GET_ALTERNATIVE_NAME_LIST_INITIALIZATION",
  GET_ALTERNATIVE_NAME_LIST_SUCCESS: "GET_ALTERNATIVE_NAME_LIST_SUCCESS",
  GET_ALTERNATIVE_NAME_LIST_ERROR: "GET_ALTERNATIVE_NAME_LIST_ERROR",
};

const ADD_ALTERNATIVE_NAME_LIST = {
  ADD_ALTERNATIVE_NAME_LIST_INITIALIZATION:
    "ADD_ALTERNATIVE_NAME_LIST_INITIALIZATION",
  ADD_ALTERNATIVE_NAME_LIST_SUCCESS: "ADD_ALTERNATIVE_NAME_LIST_SUCCESS",
  ADD_ALTERNATIVE_NAME_LIST_ERROR: "ADD_ALTERNATIVE_NAME_LIST_ERROR",
};

const GET_UTILITY_UNAUTHORIZED_LIST = {
  GET_UTILITY_UNAUTHORIZED_LIST_INITIALIZATION:
    "GET_UTILITY_UNAUTHORIZED_LIST_INITIALIZATION",
  GET_UTILITY_UNAUTHORIZED_LIST_SUCCESS:
    "GET_UTILITY_UNAUTHORIZED_LIST_SUCCESS",
  GET_UTILITY_UNAUTHORIZED_LIST_ERROR: "GET_UTILITY_UNAUTHORIZED_LIST_ERROR",
};

const GET_TICKET_ASSIGNEE_LIST = {
  GET_TICKET_ASSIGNEE_LIST_INITIALIZATION:
    "GET_TICKET_ASSIGNEE_LIST_INITIALIZATION",
  GET_TICKET_ASSIGNEE_LIST_SUCCESS: "GET_TICKET_ASSIGNEE_LIST_SUCCESS",
  GET_TICKET_ASSIGNEE_LIST_ERROR: "GET_TICKET_ASSIGNEE_LIST_ERROR",
};

const UPDATE_TICKET_ASSIGNEE_LIST = {
  UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION:
    "UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION",
  UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS: "UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS",
  UPDATE_TICKET_ASSIGNEE_LIST_ERROR: "UPDATE_TICKET_ASSIGNEE_LIST_ERROR",
};

const ALLOW_UNCHANGED_PASSWORD = {
  ALLOW_UNCHANGED_PASSWORD_INITIALIZATION:
    "ALLOW_UNCHANGED_PASSWORD_INITIALIZATION",
  ALLOW_UNCHANGED_PASSWORD_SUCCESS: "ALLOW_UNCHANGED_PASSWORD_SUCCESS",
  ALLOW_UNCHANGED_PASSWORD_ERROR: "ALLOW_UNCHANGED_PASSWORD_ERROR",
};
const GET_UNREAD_ANNOUNCEMENT_LIST = {
  GET_UNREAD_ANNOUNCEMENT_LIST_INITIALIZATION:
    "GET_UNREAD_ANNOUNCEMENT_LIST_INITIALIZATION",
  GET_UNREAD_ANNOUNCEMENT_LIST_SUCCESS: "GET_UNREAD_ANNOUNCEMENT_LIST_SUCCESS",
  GET_UNREAD_ANNOUNCEMENT_LIST_ERROR: "GET_UNREAD_ANNOUNCEMENT_LIST_ERROR",
};
const MARKED_ANNOUNCEMENT_READ = {
  MARKED_ANNOUNCEMENT_READ_INITIALIZATION:
    "MARKED_ANNOUNCEMENT_READ_INITIALIZATION",
  MARKED_ANNOUNCEMENT_READ_SUCCESS: "MARKED_ANNOUNCEMENT_READ_SUCCESS",
  MARKED_ANNOUNCEMENT_READ_ERROR: "MARKED_ANNOUNCEMENT_READ_ERROR",
};
const UPLOAD_SIGNATURE_IN_CLASS = {
  UPLOAD_SIGNATURE_IN_CLASS_INITIALIZATION:
    "UPLOAD_SIGNATURE_IN_CLASS_INITIALIZATION",
  UPLOAD_SIGNATURE_IN_CLASS_SUCCESS: "UPLOAD_SIGNATURE_IN_CLASS_SUCCESS",
  UPLOAD_SIGNATURE_IN_CLASS_ERROR: "UPLOAD_SIGNATURE_IN_CLASS_ERROR",
};
const GET_TRADING_ACCOUNT_LIST = {
  GET_TRADING_ACCOUNT_LIST_INITIALIZATION:
    "GET_TRADING_ACCOUNT_LIST_INITIALIZATION",
  GET_TRADING_ACCOUNT_LIST_SUCCESS: "GET_TRADING_ACCOUNT_LIST_SUCCESS",
  GET_TRADING_ACCOUNT_LIST_ERROR: "GET_TRADING_ACCOUNT_LIST_ERROR",
};
const ADD_TRADING_ACCOUNT = {
  ADD_TRADING_ACCOUNT_INITIALIZATION: "ADD_TRADING_ACCOUNT_INITIALIZATION",
  ADD_TRADING_ACCOUNT_SUCCESS: "ADD_TRADING_ACCOUNT_SUCCESS",
  ADD_TRADING_ACCOUNT_ERROR: "ADD_TRADING_ACCOUNT_ERROR",
};
const DELETE_TRADING_ACCOUNT = {
  DELETE_TRADING_ACCOUNT_INITIALIZATION:
    "DELETE_TRADING_ACCOUNT_INITIALIZATION",
  DELETE_TRADING_ACCOUNT_SUCCESS: "DELETE_TRADING_ACCOUNT_SUCCESS",
  DELETE_TRADING_ACCOUNT_ERROR: "DELETE_TRADING_ACCOUNT_ERROR",
};
const GET_BROKER_LIST = {
  GET_BROKER_LIST_INITIALIZATION: "GET_BROKER_LIST_INITIALIZATION",
  GET_BROKER_LIST_SUCCESS: "GET_BROKER_LIST_SUCCESS",
  GET_BROKER_LIST_ERROR: "GET_BROKER_LIST_ERROR",
};

const DOWNLOAD_MEDIA_LIST = {
  DOWNLOAD_MEDIA_LIST_INITIALIZATION: "DOWNLOAD_MEDIA_LIST_INITIALIZATION",
  DOWNLOAD_MEDIA_LIST_SUCCESS: "DOWNLOAD_MEDIA_LIST_SUCCESS",
  DOWNLOAD_MEDIA_LIST_ERROR: "DOWNLOAD_MEDIA_LIST_ERROR",
};
const SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS =
  "SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS";
const COMPLETE_DOWNLOAD_MEDIA = "COMPLETE_DOWNLOAD_MEDIA";

const GET_FAQ_LIST = {
  GET_FAQ_LIST_INITIALIZATION: "GET_FAQ_LIST_INITIALIZATION",
  GET_FAQ_LIST_SUCCESS: "GET_FAQ_LIST_SUCCESS",
  GET_FAQ_LIST_ERROR: "GET_FAQ_LIST_ERROR",
};

const VPS = {
  VPS_INITLIZATION: "VPS_INITLIZATION",
  VPS_SUCCESS: "VPS_SUCCESS",
  VPS_ERORR: "VPS_ERORR",
  ALL_VPS_MACHINES_LIST_INITIALIZATION: "ALL_VPS_MACHINES_LIST_INITIALIZATION",
  ALL_VPS_MACHINES_LIST_SUCCESS: "ALL_VPS_MACHINES_LIST_SUCCESS",
  ALL_VPS_MACHINES_LIST_ERROR: "ALL_VPS_MACHINES_LIST_ERROR",
  UPDATE_VPS_MACHINE_STATUS_INITIALIZATION:
    "UPDATE_VPS_MACHINE_STATUS_INITIALIZATION",
  UPDATE_VPS_MACHINE_STATUS_SUCCESS: "UPDATE_VPS_MACHINE_STATUS_SUCCESS",
  UPDATE_VPS_MACHINE_STATUS_ERROR: "UPDATE_VPS_MACHINE_STATUS_ERROR",
  UPDATE_VPS_MACHINE_PASSWORD_INITIALIZATION:
    "UPDATE_VPS_MACHINE_PASSWORD_INITIALIZATION",
  UPDATE_VPS_MACHINE_PASSWORD_SUCCESS: "UPDATE_VPS_MACHINE_PASSWORD_SUCCESS",
  UPDATE_VPS_MACHINE_PASSWORD_ERROR: "UPDATE_VPS_MACHINE_PASSWORD_ERROR",
  UPDATE_VPS_MACHINE_DETAIL_INITIALIZATION:
    "UPDATE_VPS_MACHINE_DETAIL_INITIALIZATION",
  UPDATE_VPS_MACHINE_DETAIL_SUCCESS: "UPDATE_VPS_MACHINE_DETAIL_SUCCESS",
  UPDATE_VPS_MACHINE_DETAIL_ERROR: "UPDATE_VPS_MACHINE_DETAIL_ERROR",
};

const GET_GIFT_VPS_LIST = {
  GET_GIFT_VPS_LIST_INITIALIZATION: "GET_GIFT_VPS_LIST_INITIALIZATION",
  GET_GIFT_VPS_LIST_SUCCESS: "GET_GIFT_VPS_LIST_SUCCESS",
  GET_GIFT_VPS_LIST_ERROR: "GET_GIFT_VPS_LIST_ERROR",
};

export {
  REGISTER,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  SET_REGISTER_STEPS,
  GET_GIFT_DETAILS,
  GET_GIFT_LIST,
  REDEEM_GIFT_LIST,
  ADD_GIFT_LIST,
  GET_TICKET_DETAILS,
  GET_TICKET_LIST,
  ADD_TICKET_LIST,
  EDIT_TICKET_LIST,
  GET_TICKET_ISSUE_TYPE_LIST,
  GET_TICKET_COMMENT_LIST,
  ADD_TICKET_COMMENT_LIST,
  GET_VPS_MACHINE_LIST,
  GET_VPS_MACHINE_TYPE_LIST,
  CREATE_VPS_MACHINE,
  GET_ANNOUNCEMENT_LIST,
  ADD_ANNOUNCEMENT_COMMENT,
  GET_ANNOUNCEMENT_COMMENT,
  GET_ANNOUNCEMENT_DETAILS,
  ADD_ANNOUNCEMENT,
  GET_CLASSES,
  REGISTER_STUDENT_IN_CLASS,
  GET_CLASSES_DETAIL,
  GET_ALL_CLASSES,
  GET_ACTIVITY_LIST,
  GET_ATTENDENCE_LIST,
  GET_POINTS_LIST,
  CHANGE_LOCAL,
  MEDIA_IMAGE_UPLOAD,
  GET_INDUSTRY_LIST,
  GET_JOB_LIST,
  GET_REGISTRATION_MATERIAL,
  SET_REGISTER,
  SET_CURRENT_STEP_BACK,
  GET_EA_LIST,
  GET_EA_VERSION_LIST,
  GET_CLASS_EXPERT_ADVISORS_LIST,
  GET_UTILITY_LIST,
  GET_SCRIPT_LIST,
  GET_LIBRARY_LIST,
  GET_INDICATORS_LIST,
  GET_INDICATOR_VERSION_LIST,
  GET_SCRIPT_VERSION_LIST,
  GET_UTILITY_VERSION_LIST,
  GET_LIBRARY_VERSION_LIST,
  GET_LIVE_ACCOUNT_LIST,
  GET_DEMO_ACCOUNT_LIST,
  GET_ALL_ACCOUNT_LIST,
  GET_TEMPOARARY_ACCOUNT_LIST,
  GET_MY_SIGNAL_LIST,
  GET_CLASS_SIGNAL_LIST,
  GET_USER_REGISTERD_CLASS,
  GET_TICKET_TOOL_VERSION_LIST,
  GET_TICKET_TOOL_LIST,
  GET_QR_CODE,
  ADD_CLASS_SIGNAL,
  EDIT_CLASS_SIGNAL,
  REDEEM_ACCOUNT,
  GET_EA_UNAUTHORIZED_LIST,
  GET_UNAUTHORIZED_INDICATORS_LIST,
  ADD_ACCOUNT_LIST,
  DELETE_ACCOUNT_LIST,
  DELETE_CLASS_SIGNAL,
  REGISTER_ACTIVITY_LIST,
  VPS_MACHINE_CONTACT_LIST,
  EMAIL_CHECK,
  MOBILE_CHECK,
  EMAIL_VERIFICATION,
  RESEND_OTP,
  FP_EMAIL_VERIFIED,
  CHANGE_PASSWORD,
  SET_THEME,
  GET_UNAUTHORIZED_SCRIPT_LIST,
  GET_UNAUTHORIZED_UTILITY_LIST,
  UPLOAD_CLASS_SLIP,
  SET_MEDIA_UPLOAD_PROGRESS,
  DELETE_MEDIA_AMAZONS3,
  DELETE_MEDIA_LOCAL,
  UPALOD_MEDIA_LIST,
  GET_ALTERNATIVE_NAME_LIST,
  ADD_ALTERNATIVE_NAME_LIST,
  GET_UTILITY_UNAUTHORIZED_LIST,
  SET_MEDIA_UPLOAD_EMPTY,
  GET_TICKET_ASSIGNEE_LIST,
  UPDATE_TICKET_ASSIGNEE_LIST,
  ALLOW_UNCHANGED_PASSWORD,
  GET_UNREAD_ANNOUNCEMENT_LIST,
  MARKED_ANNOUNCEMENT_READ,
  UPLOAD_SIGNATURE_IN_CLASS,
  DIRECT_EMAIL_VERIFICATION,
  GET_TRADING_ACCOUNT_LIST,
  GET_BROKER_LIST,
  ADD_TRADING_ACCOUNT,
  DELETE_TRADING_ACCOUNT,
  DOWNLOAD_MEDIA_LIST,
  SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS,
  COMPLETE_DOWNLOAD_MEDIA,
  READ_ANNOUNCEMENT,
  GET_FAQ_LIST,
  VPS,
  GET_GIFT_VPS_LIST,
};
