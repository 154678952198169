import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getGiftList, redeemGiftList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import NoImage from "../../assets/img/image.png";
import { Link } from "react-router-dom";
import RedeemModal from "../Gift/RedeemModal";
import DeletePopup from "../common/Popups/DeletePopup";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDate } from "../../util/utils";
import PermanantEARedeemModal from "../Gift/PermanantEARedeemModal";

const NewGift = () => {
  useEffect(() => {
    dispatch(getGiftList(false));
  }, []);

  const giftList =
    useSelector((state: Store) => state.giftReducer.giftLst) || [];
  const [modal, setModal] = useState(false);
  const [gifts, setGifts] = useState([]);
  const [selGift, setSelGift] = useState<any>({});
  const [buttonClick, setButtonClick] = useState<any>(false);
  const [show, setShow] = useState({ id: "", modal: false, point: 0 });

  const isLoading = useSelector(
    (state: Store) => state.giftReducer.isLoading,
  ) as any;
  const onSaveLoading = useSelector(
    (state: Store) => state.giftReducer.onSaveLoading,
  ) as any;

  const success = useSelector(
    (state: Store) => state.giftReducer.success,
  ) as any;

  const intl = useIntl();

  useEffect(() => {
    dispatch(getGiftList());
    setModal(false);
  }, []);

  // useEffect(() => {
  //     const g: any = [];
  //     if (giftList.gift.length > 0) {
  //         console.log("giftList.gift");
  //         giftList.gift.map((i: any, index: any) => {
  //             const exp = moment(moment(i.exipryDate).format("LL")).toDate().getTime();
  //             const today = moment(moment(new Date()).format("LL")).toDate().getTime();
  //             if (!i.redeemed && (exp >= today)) {
  //                 g.push(i)
  //             }
  //         })
  //     }
  //     setGifts(g)
  // }, [giftList]);

  useEffect(() => {
    const id = selGift?.id || 0; // moment(moment(selectedGift.exipryDate).format("LL")).toDate().getTime();
    if (id > 0) {
      giftList.gift.map((i: any) => {
        if (id == i.id) {
          const exp = moment(moment(i.exipryDate).format("LL"))
            .toDate()
            .getTime();
          const today = moment(moment(new Date()).format("LL"))
            .toDate()
            .getTime();
          setSelGift({
            ...i,
            exp: i.redeemed ? false : !(exp >= today),
          });
          console.log("i sel Gift", i);
        }
      });
    }
    const g: any = [];
    if (giftList?.gift?.length > 0) {
      console.log("giftList.gift");
      giftList.gift.map((i: any, index: any) => {
        const exp = moment(moment(i.exipryDate).format("LL"))
          .toDate()
          .getTime();
        const today = moment(moment(new Date()).format("LL"))
          .toDate()
          .getTime();
        if (!i.redeemed && exp >= today) {
          g.push(i);
        }
      });
    }
    setGifts(g);
    // setModal(true);
    // setButtonClick(false);
    // }
    // }
  }, [giftList]);

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      setShow({
        id: "",
        modal: false,
        point: 0,
      });
    }
    if (success && buttonClick) {
      setModal(true);
      setButtonClick(false);
    }
  }, [onSaveLoading, success, isLoading, buttonClick, giftList]);

  const onCardClick = (selectedGift: any) => {
    if (selectedGift.exipryDate) {
      const exp = moment(moment(selectedGift.exipryDate).format("LL"))
        .toDate()
        .getTime();
      const today = moment(moment(new Date()).format("LL")).toDate().getTime();
      setSelGift({
        ...selectedGift,
        exp: selectedGift.redeemed ? false : !(exp >= today),
        giftCategoryId: selectedGift?.giftCategory?.id,
      });
      if (!selectedGift.redeemed && exp >= today) {
        setShow({
          id: "",
          modal: true,
          point: selectedGift.pointsRequired,
        });
      } else {
        setModal(true);
      }
    }
  };

  const onRedeem = (ea = {}) => {
    if (selGift.giftCategoryId == 9) {
      dispatch(redeemGiftList(true, selGift?.id, ea));
    } else {
      dispatch(redeemGiftList(true, selGift?.id));
    }
    setShow((prv: any) => ({
      ...prv,
      modal: false,
      point: "",
    }));
    setButtonClick(true);
  };

  const getImage = (isRedeemed: boolean, category: any) => {
    const exp = moment(moment(category.exipryDate).format("LL"))
      .toDate()
      .getTime();
    const today = moment(moment(new Date()).format("LL")).toDate().getTime();

    if (!isRedeemed && today <= exp) {
      return category?.giftCategory?.beforeImage || NoImage;
    } else {
      if (isRedeemed) {
        return category?.giftCategory?.afterImage || NoImage;
      } else {
        return category?.giftCategory?.expiredImage || NoImage;
      }
    }
    return NoImage;
  };

  return (
    <div className="card dashboard-gifts-card mb-0">
      <div className="card-body">
        <h5 className="card-title border-none">
          <FormattedMessage id="menu.gifts" defaultMessage="Gifts" />
        </h5>
        <div className="card-box">
          {gifts && gifts.length ? (
            gifts.map((i: any, index: any) => (
              <span
                onClick={() => onCardClick(i)}
                title={intl.formatMessage(
                  {
                    id: "redeemOnBefore",
                    defaultMessage: "Redeem on or before",
                  },
                  { date: formatDate(i?.exipryDate, "D MMM YYYY") },
                )}
                className="badges c-p"
                style={{ flex: "0 0 auto" }}
              >
                {i?.giftCategory?.beforeImage ? (
                  <img src={i?.giftCategory?.beforeImage} alt="Class" />
                ) : (
                  <img src={NoImage} alt="Class" />
                )}
                {/* <img src={i?.giftCategory?.beforeImage} alt="Class" /> */}
                <span className="badge-class">{i?.giftCategory?.name}</span>
              </span>
            ))
          ) : (
            <p>
              <FormattedMessage
                id="dashboard.NoGiftFound"
                defaultMessage="No Gift Found"
              />
            </p>
          )}

          {/* // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF192</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">Test class</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">ww</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF191</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF192</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">Test class</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">ww</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF191</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF192</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">Test class</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">ww</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF191</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">FF192</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">Test class</span>
                    // </span>
                    // <span className="badges">
                        //     <img src={ClassImage} alt="Class" />
                    //     <span className="badge-class">ww</span>
                    // </span>
                */}
        </div>
        <div className="mt-3 text-right">
          <Link to="/gifts" className="read-more">
            <FormattedMessage
              id="dashboard.readMore"
              defaultMessage="Read More"
            />{" "}
            <i className="zmdi zmdi-long-arrow-right ml-1"></i>
          </Link>
        </div>
      </div>
      {modal && (
        <RedeemModal
          details={selGift}
          onClose={() => setModal(false)}
          open={modal}
          onRedeem={() => onRedeem()}
          isLoading={false}
        />
      )}
      {show.modal && [9].includes(selGift.giftCategoryId) && (
        <PermanantEARedeemModal
          // onClose={() => setModal(false)}
          onClose={() =>
            setShow((prv: any) => ({
              ...prv,
              modal: false,
              point: "",
            }))
          }
          open={show.modal}
          isLoading={false}
          details={selGift}
          onRedeem={(ac: any) => onRedeem(ac)}
        />
      )}
      {show.modal && ![9].includes(selGift.giftCategoryId) && (
        <DeletePopup
          message={
            <FormattedMessage
              id="Areyousurewanttoredeemgift?"
              defaultMessage="Are you sure want to redeem gift ?"
            />
          }
          onClose={() =>
            setShow((prv: any) => ({
              ...prv,
              modal: false,
              point: "",
            }))
          }
          open={show.modal}
          onConfirm={() => onRedeem()}
          isLoading={onSaveLoading}
          isSubText={true}
          subText={
            <FormattedMessage
              id="msg.DeductPoints"
              values={{ point: show.point }}
              defaultMessage="Points Required"
            />
          }
        />
      )}
    </div>
  );
};

export default NewGift;
