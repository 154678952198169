/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_EA_LIST,
  GET_EA_VERSION_LIST,
  GET_EA_UNAUTHORIZED_LIST,
  GET_CLASS_EXPERT_ADVISORS_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_EA_LIST_URL,
  GET_EA_VERSION_LIST_URL,
  GET_EA_UNAUTHORIZED_LIST_URL,
  GET_CLASS_EXPERT_ADVISORS_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getEAList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getEAListInit(showToast, sort));
  };

export const getEAListInit =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_LIST.GET_EA_LIST_INITIALIZATION,
    });
    apiCall(
      GET_EA_LIST_URL,
      (res: Object) => dispatch(getEAListSuccess(res)),
      (err: any) => dispatch(getEAListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getEAListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_LIST.GET_EA_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getEAListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EA_LIST.GET_EA_LIST_ERROR,
    });
  };

export const getEAVersionList =
  (
    showToast = true,
    eaId: string,
    isGranted = false,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getEAVersionListInit(showToast, eaId, isGranted));
  };

export const getEAVersionListInit =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      GET_EA_VERSION_LIST_URL + eaId,
      (res: Object) => dispatch(getEAVersionListSuccess(res)),
      (err: any) => dispatch(getEAVersionListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getEAVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getEAVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getEAUnauthorizedList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getEAUnauthorizedListInit(showToast));
  };

export const getEAUnauthorizedListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_UNAUTHORIZED_LIST.GET_EA_UNAUTHORIZED_LIST_INITIALIZATION,
    });
    apiCall(
      GET_EA_UNAUTHORIZED_LIST_URL,
      (res: Object) => dispatch(getEAUnauthorizedListSuccess(res)),
      (err: any) => dispatch(getEAUnauthorizedListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getEAUnauthorizedListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_UNAUTHORIZED_LIST.GET_EA_UNAUTHORIZED_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getEAUnauthorizedListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EA_UNAUTHORIZED_LIST.GET_EA_UNAUTHORIZED_LIST_ERROR,
    });
  };

export const getClassExpertAdvisorsList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getClassExpertAdvisorsInit(showToast, sort));
  };

export const getClassExpertAdvisorsInit =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_EXPERT_ADVISORS_LIST.GET_CLASS_EXPERT_ADVISORS_LIST_INITIALIZATION,
    });
    apiCall(
      GET_CLASS_EXPERT_ADVISORS_LIST_URL,
      (res: Object) => dispatch(getClassExpertAdvisorsSuccess(res)),
      (err: any) => dispatch(getClassExpertAdvisorsError()),
      {
        params: {
          excludingGranted: "true",
          allowExpired: "true",
          excludingFFA: "true",
        },
      },
      METHOD.GET,
      { showToast },
    );
  };

export const getClassExpertAdvisorsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_EXPERT_ADVISORS_LIST.GET_CLASS_EXPERT_ADVISORS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getClassExpertAdvisorsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASS_EXPERT_ADVISORS_LIST.GET_CLASS_EXPERT_ADVISORS_LIST_ERROR,
    });
  };
