/* eslint-disable no-case-declarations */
import {
  GET_GIFT_LIST,
  GET_GIFT_DETAILS,
  ADD_GIFT_LIST,
  REDEEM_GIFT_LIST,
  GET_GIFT_VPS_LIST,
} from "../constants/action-types";
import { GiftData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  giftLst: [],
  success: false,
  errors: false,
  giftDetails: [],
  giftRedeemSuccess: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: GiftData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_GIFT_LIST.GET_GIFT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_GIFT_LIST.GET_GIFT_LIST_SUCCESS:
        return {
          ...state,
          giftLst: action.payload,
          isLoading: false,
        };

      case GET_GIFT_LIST.GET_GIFT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_GIFT_LIST.ADD_GIFT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_GIFT_LIST.ADD_GIFT_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_GIFT_LIST.ADD_GIFT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case REDEEM_GIFT_LIST.REDEEM_GIFT_LIST_INITIALIZATION:
        return {
          ...state,
          success: false,
          errors: false,
          onSaveLoading: true,
        };

      case REDEEM_GIFT_LIST.REDEEM_GIFT_LIST_SUCCESS:
        return {
          ...state,
          success: true,
          errors: false,
          onSaveLoading: false,
          giftRedeemSuccess: true,
        };

      case REDEEM_GIFT_LIST.REDEEM_GIFT_LIST_ERROR:
        return {
          ...state,
          success: false,
          errors: true,
          onSaveLoading: false,
          giftRedeemSuccess: false,
        };

      case GET_GIFT_DETAILS.GET_GIFT_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_GIFT_DETAILS.GET_GIFT_DETAILS_SUCCESS:
        return {
          ...state,
          gfitDetails: action.payload,
          isLoading: false,
        };

      case GET_GIFT_DETAILS.GET_GIFT_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_GIFT_VPS_LIST.GET_GIFT_VPS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_GIFT_VPS_LIST.GET_GIFT_VPS_LIST_SUCCESS:
        return {
          ...state,
          giftDetails: action.payload,
          isLoading: false,
        };

      case GET_GIFT_VPS_LIST.GET_GIFT_VPS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
