import React from "react";
import Dropzone from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { appIntl } from "../../../util/intl";

const MAX_SIZE = 2 * 1024 * 1024; // 2 MB

const FileUploadImage = ({
  handleDrop,
  accept = "image/*",
  optional = false,
}: any) => {
  return (
    <div>
      <div style={{ height: "100px", border: "2px lightgrey solid" }}>
        <Dropzone
          onDrop={handleDrop}
          accept={(accept ?? "") === "" ? null : accept}
          maxSize={MAX_SIZE}
          onDropRejected={(fileRejections) => {
            for (const fileRejection of fileRejections) {
              const {
                file: { name },
                errors,
              } = fileRejection;

              for (const error of errors) {
                if (error.code === "file-too-large") {
                  const maxSizeInMB = (MAX_SIZE / 1024 / 1024).toString();
                  toast.error(
                    appIntl().formatMessage(
                      { id: "errors.uploadImageFileSizeError" },
                      { name: name, maxSizeInMB: maxSizeInMB },
                    ),
                  );
                } else if (error.code === "file-invalid-type") {
                  toast.error("Only image files are allowed");
                }
              }
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              style={{ textAlign: "center" }}
              {...getRootProps({ className: "dropzone mt-2" })}
            >
              <input {...getInputProps()} />
              <b>
                <BsUpload size={25} />
                <p>
                  <FormattedMessage
                    id="file.upload"
                    defaultMessage="Drag'n'drop images, or click to select files "
                  />
                  {optional && (
                    <FormattedMessage
                      id="file.upload.optional"
                      defaultMessage="(optional)"
                    />
                  )}
                </p>
              </b>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

export default FileUploadImage;
