import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/img/gemsFX_normal.svg";
import { resetPasswordSchema } from "../util/validationSchema";
import { forgotPassword } from "../redux/action";
import { Store } from "../redux/Actions";
import { FormattedMessage, useIntl } from "react-intl";
import { Translation } from "../Components/common/Translation";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [buttonClick, setButtonClick] = useState(false);
  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );

  const isForgotpasswordSuccess = useSelector(
    (state: Store) => state.userDataReducer.isForgotpasswordSuccess,
  );

  useEffect(() => {
    if (isForgotpasswordSuccess && buttonClick) {
      navigate("/login");
    }
  }, [isForgotpasswordSuccess]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      const a = new URLSearchParams(location.search);
      alert(JSON.stringify(values, null, 2));
      dispatch(
        forgotPassword({
          email: a.get("email"),
          token: a.get("token"),
          newPassword: values.password,
        }),
      );
      setButtonClick(true);
    },
  });

  useEffect(() => {
    if (isLoggedin) {
      navigate("/home");
    }
  }, [isLoggedin]);

  const { touched, errors } = formik;
  return (
    <React.Fragment>
      <div className="main-container d-flex align-items-center justify-content-center bg-login-image">
        <div className="login-box">
          <Translation />
          <div className="login-logo">
            <img style={{ height: "50px" }} src={Logo} alt="Logo" />
          </div>
          <div className="login-box-body">
            <h1 className="text-center mb-3 font-weight-500">
              <FormattedMessage
                id="head.RecoverPassword"
                defaultMessage="Recover Password"
              />
            </h1>
            <p className="text-center mb-4">
              <FormattedMessage
                id="title.newPassword"
                defaultMessage="Enter your new password"
              />
            </p>
            <form>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder={useIntl().formatMessage({
                    id: "newPassword",
                    defaultMessage: "New Password",
                  })}
                />
                {errors.password && touched.password && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.password.toString()}
                      defaultMessage="This field is required"
                    />
                    {/* {errors.password} */}
                  </div>
                )}
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder={useIntl().formatMessage({
                    id: "profile.confirmPassword",
                    defaultMessage: "Confirm Password",
                  })}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.confirmPassword.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-block btn-lg"
                onClick={() => formik.handleSubmit()}
              >
                <FormattedMessage
                  id="set.Password"
                  defaultMessage="Set Password"
                />
              </button>

              <div className="d-flex align-items-center justify-content-between m-t-20">
                {/* <div className="form-group m-0">
                  <label
                    className="control control-outline control-primary control--checkbox m-0"
                    htmlFor="as"
                  >
                    Remember Me
                    <input type="checkbox" id="as" />
                    <div className="control__indicator"></div>
                  </label>
                </div> */}
                <div className="password-reset-link">
                  <Link className="text-accent-custom font-size-14" to="/login">
                    {" "}
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="login-box-footer border-top">
            <div className="password-reset-link text-center">
              {/* <Link className="text-accent-custom" to="/forgot-password"> Login</Link> */}
              <FormattedMessage
                id="dont.Account"
                defaultMessage="Don't have an account"
              />{" "}
              ?
              <Link className="text-accent-custom" to="/register">
                {" "}
                <FormattedMessage
                  id="login.Register"
                  defaultMessage="Register here"
                />
              </Link>
            </div>
          </div>
          {/* <button type="button" className="btn btn-primary btn-block btn-lg" style={{ borderRadius: '0px' }} onClick={() => formik.handleSubmit()} >Register</button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
