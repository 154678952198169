import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { getAttendenceList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { customFormatDatetimeWithLocale, formatDate } from "../../util/utils";
import { FormattedDate, FormattedMessage } from "react-intl";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { Card, Col, Row } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const Attendence = () => {
  const dispatch = useDispatch();
  const attendenceLst = useSelector(
    (state: Store) => state.attendenceReducer.attendenceLst,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.attendenceReducer.isLoading,
  ) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const totalRecord = useSelector(
    (state: Store) => state.attendenceReducer.totalRecord,
  ) as any;

  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  useEffect(() => {
    dispatch(
      getAttendenceList(
        false,
        pageNumber,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const getAttendenceString = (attendence) => {
    switch (attendence) {
      case "ATTENDED":
        return <FormattedMessage id="attended" defaultMessage="attended" />;
      case "UNATTENDED":
        return <FormattedMessage id="unatteded" defaultMessage="unatteded" />;
      case "DENIED":
        return <FormattedMessage id="rejected" defaultMessage="denied" />;
      default:
        return "";
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="attendence" defaultMessage="Attandence" />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>

              {attendenceLst && attendenceLst.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-striped m-0">
                      <thead>
                        <tr>
                          <th
                            key="head_name"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_name")}
                          >
                            {" "}
                            <FormattedMessage
                              id="attendence.Name"
                              defaultMessage="Activity"
                            />
                            {sortBy === "head_name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_class"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_class")}
                          >
                            {" "}
                            <FormattedMessage
                              id="attendence.Class"
                              defaultMessage="Class"
                            />
                            {sortBy === "head_class" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_type"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_type")}
                          >
                            {" "}
                            <FormattedMessage
                              id="table.head.Type"
                              defaultMessage="Type"
                            />
                            {sortBy === "head_type" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_status"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_status")}
                          >
                            {" "}
                            <FormattedMessage
                              id="Atticket.Status"
                              defaultMessage="Attendence Status"
                            />
                            {sortBy === "head_status" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_time"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_time")}
                          >
                            {" "}
                            <FormattedMessage
                              id="ponts.Time"
                              defaultMessage="Time"
                            />
                            {sortBy === "head_time" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendenceLst && attendenceLst.length > 0 ? (
                          attendenceLst.map((act: any) => (
                            <tr>
                              <td>{act?.activity?.name}</td>
                              <td>{act?.activity?.class?.name}</td>
                              <td>
                                <FormattedMessage
                                  id={act?.type}
                                  defaultMessage={act?.type}
                                />
                              </td>
                              <td>{getAttendenceString(act?.Attended)}</td>
                              <td>
                                {act?.activity?.startDate
                                  ? customFormatDatetimeWithLocale(
                                      act?.activity?.startDate,
                                      locale,
                                      true,
                                    )
                                  : "-"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h4>{!isLoading && <NoDatFound />}</h4>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {totalRecord > 10 && (
                    <>
                      <FormControl className="pagelimit">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <FormattedMessage
                            id="page_size"
                            defaultMessage="Page Size"
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={pageLimit}
                          onChange={(e: any) => {
                            setPageLimit(e.target.value);
                            setPageNumber(1);
                          }}
                        >
                          <FormControlLabel
                            value={10}
                            control={<Radio />}
                            label="10"
                          />
                          <FormControlLabel
                            value={20}
                            control={<Radio />}
                            label="20"
                          />
                          <FormControlLabel
                            value={50}
                            control={<Radio />}
                            label="50"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <p>{!isLoading && <NoDatFound />}</p>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Attendence;
