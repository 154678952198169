import { FaInfoCircle } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface ManagementRowInfoTooltipProps {
  instanceTypeName: string;
  instanceId: string;
  vpsId: number;
}

const ManagementRowInfoTooltip = (props: ManagementRowInfoTooltipProps) => {
  const { instanceTypeName, instanceId, vpsId } = props;

  return (
    <td>
      <Tooltip
        arrow
        placement="left"
        title={
          <>
            <Typography color="inherit">{`Type Name: ${instanceTypeName}`}</Typography>
            <Typography color="inherit">{`Instance Id: ${instanceId}`}</Typography>
            <Typography color="inherit">{`VPS Id: ${vpsId}`}</Typography>
          </>
        }
      >
        <i>
          <FaInfoCircle size={20} />
        </i>
      </Tooltip>
    </td>
  );
};

export default ManagementRowInfoTooltip;
