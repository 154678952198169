import { useEffect, useState } from "react";
import { Avatar, Switch } from "@mui/material";
import Logo from "../../assets/img/gemsFX_white.svg";
import NoImg from "../../assets/img/no-image.png";
import Moon from "../../assets/img/moon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getQR,
  logout,
  setPasswordAlert,
  setTheme,
  translate,
} from "../../redux/action";
import { TranslationIcon } from "../../util/iconStyle";
import { Store, VpsData } from "../../redux/Actions";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";
import PasswordAlert from "./Popups/PasswordAlert";
import GemsfxChinese from "../../assets/img/Gemsfx-Version4-Logo - Chinese.png";
import GemsfxEnglish from "../../assets/img/Gemsfx-Version4-Logo - English.png";
import { Dropdown, Navbar, Modal } from "react-bootstrap";
import { FaAngleDown, FaTimesCircle } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { customFormatDatetimeWithLocale } from "../../util/utils";
import { getVPSExpire } from "../../redux/action/vpsActions";
import moment from "moment";
import engJson from "../../translations/en.json";
import chineseJson from "../../translations/zh.json";
import simplifiedChineseJson from "../../translations/cn.json";
import NotificationAlert from "../Notification/NotificationAlert";
import { toast } from "react-toastify";
import { appIntl } from "../../util/intl";
import { getToken } from "../../util/utils";

export const Header = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [changePassClick, setChangePassClick] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    localStorage.setItem("isOpenReminderModal", "0");
    setShowModal(false);
  };

  const isLogout = useSelector(
    (state: Store) => state.userDataReducer.isLoggedout,
  );

  const vpsExpire = useSelector((state: Store) => state.vpsReducer.vpsLst);
  const tempExpiryDate = new Date(vpsExpire?.[0]?.expiryDate);
  const currentTime = new Date();
  const Difference_In_Time = tempExpiryDate.getTime() - currentTime.getTime();
  const remainingDays = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );

  const myInfo = useSelector((state: Store) => state.userDataReducer.myInfo);

  const darkTheme = useSelector(
    (state: Store) => state.userDataReducer.darkTheme,
  );

  const isThreatAccepted: boolean = useSelector(
    (state: Store) => state.userDataReducer.isThreatAccepted,
  );

  const isUpdatePasswordRequire: boolean = useSelector(
    (state: Store) => state.userDataReducer.isUpdatePasswordRequire,
  );

  const [OpenProfileActionMenu, setOpenProfileActionMenu] = useState(false);
  const [sidebarToggle, setsidebarToggle] = useState(false);
  const [MobileToggle, setMobileToggle] = useState(false);
  const local = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    if (localStorage.getItem("isOpenReminderModal") == "1") {
      dispatch(getVPSExpire());
    }
  }, []);

  useEffect(() => {
    if (
      vpsExpire?.length > 0 &&
      localStorage.getItem("isOpenReminderModal") == "1"
    ) {
      setShowModal(true);
    }
  }, [vpsExpire]);

  useEffect(() => {
    if (!isLoggedin && isLogout) {
      navigate("/login");
    }
  }, [isLogout, isLoggedin]);

  useEffect(() => {
    if (isLoggedin) {
      dispatch(getQR());
    }
  }, [isLoggedin]);

  useEffect(() => {
    setsidebarToggle(false);
    setOpenProfileActionMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    const el = document.querySelector("body");
    if (sidebarToggle) {
      setOpenProfileActionMenu(false);
      setMobileToggle(false);
      if (el) {
        el.classList.add("aside-left-open");
      }
    } else {
      if (el) {
        el.classList.remove("aside-left-open");
      }
    }
  }, [sidebarToggle]);

  useEffect(() => {
    const body = document.getElementById("body");
    if (darkTheme) {
      body?.classList.add("dark-theme");
    } else {
      body?.classList.remove("dark-theme");
    }
  }, [darkTheme]);

  const translateLocal = (language: string) => {
    dispatch(translate(language));
  };

  const handleSidebarToggleChange = () => {
    setsidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    if (
      !isThreatAccepted &&
      isUpdatePasswordRequire &&
      location.pathname !== "/change-password"
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isThreatAccepted, isUpdatePasswordRequire, location.pathname]);

  useEffect(() => {
    const el = document.getElementById("body");

    if (MobileToggle) {
      if (el) {
        el.classList.add("mobile-topbar-toggle");
      }
    } else {
      if (el) {
        el.classList.remove("mobile-topbar-toggle");
      }
    }
  }, [MobileToggle]);

  const handleMobileToggleChange = () => {
    setMobileToggle(!MobileToggle);
  };

  let popUpMessageEng = engJson["reminder.content"];
  popUpMessageEng = popUpMessageEng.replace(
    "<countdown days>",
    `${remainingDays}`,
  );

  let popUpMessageChinese = chineseJson["reminder.content"];
  popUpMessageChinese = popUpMessageChinese.replace(
    "<countdown days>",
    `${remainingDays}`,
  );

  let popUpMessageSimplifiedChinese = simplifiedChineseJson["reminder.content"];
  popUpMessageSimplifiedChinese = popUpMessageSimplifiedChinese.replace(
    "<countdown days>",
    `${remainingDays}`,
  );

  const handleChange = () => {
    const localUnreadAnnouncement = JSON.parse(getToken("unreadAnnouncement"));
    if (localUnreadAnnouncement?.length == 0) {
      toast.success(
        appIntl().formatMessage({ id: "announcement.no.notifications" }),
      );
    } else {
      localStorage.setItem("announcement", "1");
      setOpenNotification(true);
    }
  };

  return (
    <>
      <div>
        {show && (
          <PasswordAlert
            message={
              <FormattedMessage
                id="passwordAlert.msg"
                defaultMessage="You should change your password every 90 days for security reasons"
              />
            }
            onClose={() => {
              dispatch(setPasswordAlert(true, true));
              setShow(false);
              setChangePassClick(true);
            }}
            open={true}
            onConfirm={() => {
              setShow(false);
              navigate("/change-password");
            }}
            isLoading={false}
          />
        )}
        <nav className="top-toolbar navbar navbar-mobile navbar-tablet flex-nowrap">
          <ul className="navbar-nav nav-left">
            <li className="nav-item">
              <a
                href="#"
                onClick={() => handleSidebarToggleChange()}
                data-toggle-state="aside-left-open"
              >
                <i className="icon dripicons-align-left"></i>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav nav-center site-logo">
            <li>
              <Link to="/home">
                <div className="logo-custom">
                  {local == "en" ? (
                    <img
                      src={GemsfxEnglish}
                      style={{ width: "140px", maxWidth: "unset" }}
                    />
                  ) : (
                    <img
                      src={GemsfxChinese}
                      style={{ width: "140px", maxWidth: "unset" }}
                    />
                  )}
                </div>
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav nav-right">
            <li className="nav-item">
              <a
                onClick={() => {
                  handleMobileToggleChange();
                  setsidebarToggle(false);
                }}
                data-toggle-state="mobile-topbar-toggle"
              >
                <i className="icon dripicons-dots-3 rotate-90"></i>
              </a>
            </li>
          </ul>
        </nav>
        <nav className="top-toolbar navbar navbar-desktop flex-nowrap">
          <ul className="navbar-nav nav-right">
            <li className="nav-item d-flex align-items-center justify-content-center">
              <IoNotificationsOutline
                className="notification-icon"
                onClick={handleChange}
              />
            </li>
            <li className="nav-item d-flex align-items-center justify-content-center">
              <img src={Moon} alt="" width={22} className="mr-1" />
              <Switch
                color="default"
                checked={darkTheme}
                onChange={() => {
                  dispatch(setTheme(!darkTheme));
                }}
              />
            </li>
            <li className="nav-item dropdown">
              <Dropdown className="profile-item">
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  className="nav-item dropdown"
                >
                  <span className="text-white font-size-16">
                    <i
                      className="MuiListItemIcon-root"
                      style={{ color: "white" }}
                    >
                      {" "}
                      <TranslationIcon />
                    </i>{" "}
                    <i className=" text-white" aria-hidden="true">
                      <FaAngleDown />
                    </i>{" "}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ margin: 0 }}>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => translateLocal("zh")}
                  >
                    <i className="sl-flag flag-de">
                      <div id="germany"></div>
                    </i>{" "}
                    <span className={local === "zh" ? "active" : ""}>
                      中文（繁體）
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => translateLocal("cn")}
                  >
                    <i className="sl-flag flag-de">
                      <div id="germany"></div>
                    </i>{" "}
                    <span className={local === "cn" ? "active" : ""}>
                      中文（简体）
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => translateLocal("en")}
                  >
                    <i className="sl-flag flag-usa">
                      <div id="germany"></div>
                    </i>{" "}
                    <span className={local === "en" ? "active" : ""}>
                      English
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <Dropdown className="profile-item">
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  className="nav-item dropdown profile-item-container"
                >
                  {myInfo.avatar ? (
                    <img
                      src={myInfo.avatar}
                      className="w-35 h-35 o-cover rounded-circle profile-avatar"
                    />
                  ) : (
                    <Avatar className="w-35 h-35 o-cover rounded-circle profile-avatar">
                      {myInfo?.englishFirstName?.charAt(0)?.toUpperCase()}
                    </Avatar>
                  )}
                  <div className="profile-item-name">
                    <div>
                      {myInfo.englishFirstName + " " + myInfo.englishLastName}
                    </div>
                    <div>
                      {customFormatDatetimeWithLocale(myInfo.expiryDate, local)}
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ margin: 0 }}>
                  <Dropdown.Item eventKey="1">
                    <div className="0dropdown-header p-0">
                      <div className="media d-user">
                        {myInfo.avatar ? (
                          <img
                            className="align-self-center mr-3 w-40 h-40 o-cover rounded-circle"
                            src={myInfo.avatar}
                            alt="John Smith here"
                          />
                        ) : (
                          <Avatar className="align-self-center mr-3 w-40 h-40 o-cover rounded-circle">
                            {myInfo?.englishFirstName?.charAt(0)?.toUpperCase()}
                          </Avatar>
                        )}
                        <div className="media-body">
                          <h5 className="mt-0 mb-0">
                            {myInfo.englishFirstName +
                              " " +
                              myInfo.englishLastName}
                          </h5>
                          <span>{myInfo?.email || ""}</span>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Link className="dropdown-item" to="/profile">
                    <i className="icon dripicons-user"></i>{" "}
                    <FormattedMessage
                      id="myProfile"
                      defaultMessage="My Profile"
                    />
                  </Link>
                  <Link className="dropdown-item" to="/change-password">
                    <i className="icon dripicons-gear"></i>{" "}
                    <FormattedMessage
                      id="changePassword"
                      defaultMessage="Change Password"
                    />
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => dispatch(logout())}
                  >
                    <i className="icon dripicons-lock"></i>{" "}
                    <FormattedMessage id="logout" defaultMessage="Logout" />
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            {/* <li className="nav-item dropdown mr-3">
      <a
        onClick={() => {
          setOpenProfileActionMenu(!OpenProfileActionMenu);
        }}
        className="nav-link nav-pill user-avatar d-flex align-items-center"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {myInfo.avatar ? (
          <img
            src={myInfo.avatar}
            className="w-35 h-35 o-cover rounded-circle"
          />
        ) : (
          <Avatar className="w-35 h-35 o-cover rounded-circle">
            {myInfo?.englishFirstName?.charAt(0)?.toUpperCase()}
          </Avatar>
        )}
      </a>
      {OpenProfileActionMenu ? (
        <div className="dropdown-menu dropdown-menu-right dropdown-menu-accout d-inline-block">
          <div className="dropdown-header pb-3">
            <div className="media d-user">
              {myInfo.avatar ? (
                <img
                  className="align-self-center mr-3 w-40 h-40 o-cover rounded-circle"
                  src={myInfo.avatar}
                  alt="John Smith here"
                />
              ) : (
                <Avatar className="align-self-center mr-3 w-40 h-40 o-cover rounded-circle">
                  {myInfo?.englishFirstName?.charAt(0)?.toUpperCase()}
                </Avatar>
              )}
              <div className="media-body">
                <h5 className="mt-0 mb-0">
                  {myInfo.englishFirstName + " " + myInfo.englishLastName}
                </h5>
                <span>{myInfo?.email || ""}</span>
              </div>
            </div>
          </div>
          <Link className="dropdown-item" to="/profile">
            <i className="icon dripicons-user"></i>{" "}
            <FormattedMessage
              id="myProfile"
              defaultMessage="My Profile"
            />
          </Link>
          <Link className="dropdown-item" to="/change-password">
            <i className="icon dripicons-gear"></i>{" "}
            <FormattedMessage
              id="changePassword"
              defaultMessage="Change Password"
            />
          </Link>
          <Link
            className="dropdown-item"
            to="#"
            onClick={() => dispatch(logout())}
          >
            <i className="icon dripicons-lock"></i>{" "}
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </Link>
        </div>
      ) : null}
    </li> */}
          </ul>
        </nav>
        <a
          href="#"
          onClick={() => handleSidebarToggleChange()}
          data-toggle-state="aside-left-open"
          className="nav-mobile-fixed"
        ></a>
      </div>
      {openNotification === true && (
        <NotificationAlert setOpenNotification={setOpenNotification} />
      )}
      <Modal
        show={showModal}
        centered
        size={"sm"}
        className="modal-content-md login-modal"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <p>
              <FormattedMessage id="reminder.header" />
            </p>
          </Modal.Title>
          <button className="close" onClick={handleClose}>
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>
            {local == "en"
              ? popUpMessageEng
              : local === "zh"
                ? popUpMessageChinese
                : popUpMessageSimplifiedChinese}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};
