import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { directEmailVerification } from "../redux/action";
import { Store } from "../redux/Actions";
import { toast } from "react-toastify";
import CenterLoader from "../Components/common/CenterLoader/CenterLoader";

const DirectEmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isEmailAdded, setIsEmailAdded] = useState(false);

  const isEmail: any = useSelector(
    (state: Store) => state.userDataReducer.isEmailVerified,
  );
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const sFunction = () => {
    setIsEmailAdded(false);

    const a = new URLSearchParams(location.search);

    const email =
      a.get("email") != null
        ? a.get("email").toString().trim().replace(" ", "+")
        : "";
    const token = a.get("token") != null ? a.get("token") : "";
    if (
      a.get("email") != null &&
      a.get("token") != null &&
      email.length > 0 &&
      token.length > 0
    ) {
      dispatch(
        directEmailVerification({
          email,
          token,
        }),
      );
      setIsEmailAdded(true);
    } else {
      setIsEmailAdded(false);
      toast.error("email or token missing");
    }
  };

  useEffect(() => {
    sFunction();
  }, []);

  useEffect(() => {
    if (isEmailAdded && isEmail) {
      navigate("/");
    }
  }, [isEmailAdded, isEmail]);

  return (
    <React.Fragment>
      <div className="main-container d-flex align-items-center justify-content-center">
        {isEmailAdded && isEmail && (
          <>
            <h1>Sucess</h1>
            <Link to="/">Home</Link>
          </>
        )}
        {!isEmail && (
          <>
            <h1>Failure</h1>
          </>
        )}
        {isEmail != false && !isEmailAdded && <> Missing Email or Token </>}
        {isLoading && (
          <>
            Please wait...
            <CenterLoader />{" "}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default DirectEmailVerification;
