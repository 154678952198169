import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/img/logo-big-new.png";
import Go from "../assets/img/btn-go.png";
import { loginSchema } from "../util/validationSchema";
import { login, setRegisterSteps, set_email } from "../redux/action";
import { Store } from "../redux/Actions";
import { FormattedMessage, useIntl } from "react-intl";
import { Translation } from "../Components/common/Translation";
import backGroundImage from "../assets/img/login-bg-img.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { isEdge } from "react-device-detect";
import MyPasswordInputWrapper from "../Components/MyPasswordInput";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );

  const isEmailVerified = useSelector(
    (state: Store) => state.userDataReducer.isEmailVerified,
  );

  const errorMessage = useSelector(
    (state: Store) => state.userDataReducer.errorMessage,
  );

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  const [err, setError] = useState("");
  const [usedSrc, setUsedSrc] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(login(values.email, values.password));
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const { touched, errors, isSubmitting, setSubmitting } = formik;

  useEffect(() => {
    setError(errorMessage);
    if (errorMessage === undefined || errorMessage) {
      setSubmitting(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    dispatch(setRegisterSteps(-1, {}));
    dispatch({
      type: "SET_USERINFO_EMPTY",
      payload: {},
    });
  }, []);

  useEffect(() => {
    if (isLoggedin) {
      localStorage.setItem("isOpenReminderModal", "1");
      navigate("/home");
    }
  }, [isLoggedin, isSubmitting]);

  useEffect(() => {
    if (!isEmailVerified) {
      navigate("/email-verification");
    }
    dispatch(set_email(true));
  }, [isEmailVerified]);

  useEffect(() => {
    const img = new Image();
    img.src = backGroundImage;
    img.onload = () => {
      setUsedSrc(true);
    };
  }, []);

  function HandleLoginError(prop) {
    switch (prop.err) {
      case "Incorrect credentials.":
        return (
          <FormattedMessage
            id="login.Invalid.Credentials"
            defaultMessage="Invalid Email address / password."
          />
        );
      case "User is deactivated":
        return (
          <FormattedMessage
            id="login.User.is.deactivated"
            defaultMessage="User is deactivated."
          />
        );
      case "Incorrect password.":
        return (
          <FormattedMessage
            id="login.Incorrect.password"
            defaultMessage="Invalid Email address / password."
          />
        );
      case "User is not Approved":
        return (
          <FormattedMessage
            id="login.User.is.not.Approved"
            defaultMessage="User is not Approved"
          />
        );
      case "Please Verify Your email":
        return (
          <FormattedMessage
            id="login.Please.Verify.Your.email"
            defaultMessage="Please Verify Your email"
          />
        );
      default:
        return <div></div>;
    }
  }

  return (
    <React.Fragment>
      <div
        className={`main-container d-flex flex-column align-items-center justify-content-center ${
          usedSrc ? "bg-login-image" : "bg-login-image-low"
        } client-app login-page`}
      >
        <div className="login-logo text-center">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="login-box">
          <Translation />
          <div
            style={{
              background: "yellow",
              padding: "2rem",
              borderRadius: "1rem",
              color: "red",
              fontSize: "1.5rem",
            }}
          >
            這是已失效的舊3.0系統 <br />
            請用新的4.0系統：
            <a href="https://gemsai.com">https://gemsai.com</a>
          </div>
          <div className="login-box-body">
            <form
              onSubmit={(e) => {
                setError("");
                formik.handleSubmit(e);
              }}
            >
              <div className="form-group w-80p mb-3">
                <input
                  disabled={isSubmitting}
                  type="text"
                  className="form-control"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    setError("");
                  }}
                  placeholder={useIntl().formatMessage({
                    id: "login.Email",
                    defaultMessage: "Email",
                  })}
                />
                {errors.email && touched.email && (
                  <div className="text-error-login">
                    <FormattedMessage id={errors.email.toString()} />{" "}
                  </div>
                )}
              </div>
              <MyPasswordInputWrapper
                value={formik.values.password}
                className="form-group w-80p mb-3"
                name="password"
                onBlur={formik.handleBlur}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  setError("");
                }}
                errors={errors}
                touched={touched}
                inputDefaultMessage={"Password"}
                inputDefaultMessageId={"login.Password"}
                disabled={isSubmitting}
              />
              <div
                className="form-group w-80p"
                style={{
                  width: "18rem",
                  position: "relative",
                  fontSize: "12px",
                  color: "#ffa7a7",
                  fontWeight: "600",
                }}
              >
                <HandleLoginError err={err} />
              </div>

              <div className="d-flex align-items-center justify-content-between m-b-5 mt-3">
                <Link
                  className="text-white font-size-16 font-weight-500 m-l-15"
                  to="/register"
                >
                  {" "}
                  <FormattedMessage id="signUp" defaultMessage="Sign Up" />
                </Link>

                <div className="password-reset-link">
                  <Link
                    className="text-white font-size-16 font-weight-500"
                    to="/forgot-password"
                  >
                    {" "}
                    <FormattedMessage
                      id="login.Forgot.Pass"
                      defaultMessage="Forgot Password?"
                    />
                  </Link>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between m-b-35">
                <div className="text-white font-size-16 font-weight-500 m-l-15">
                  {" "}
                </div>

                <div className="password-reset-link">
                  <Link
                    className="text-white font-size-16 font-weight-500"
                    to="/email-verification"
                  >
                    {" "}
                    <FormattedMessage
                      id="login.Forgot.verifyEmail"
                      defaultMessage="Resend Confirmation Email"
                    />
                  </Link>
                </div>
              </div>

              <div className="text-center">
                <button
                  disabled={isSubmitting}
                  className="login-button"
                  type="submit"
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  {!isSubmitting ? (
                    <img src={Go} alt="Go" className="btn-go" />
                  ) : (
                    <div>
                      <span
                        className="login-button--loading"
                        style={{ position: "relative", left: -10 }}
                      ></span>
                      <p
                        style={{
                          position: "absolute",
                          color: "white",
                          top: 30,
                          left: -20,
                          fontSize: 16,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {locale !== "zh" ? "Loading..." : "載入中..."}
                      </p>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
