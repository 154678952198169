import React from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

interface ManagementHeaderProps {
  sortBy: string;
  sortOrder: string;
  setSorting: React.Dispatch<React.SetStateAction<string>>;
}

const ManagementHeader = (props: ManagementHeaderProps) => {
  const { sortBy, sortOrder, setSorting } = props;

  return (
    <thead>
      <tr>
        <th>
          <FormattedMessage id="vps.management.vps" defaultMessage="VPS" />
        </th>
        <th style={{ cursor: "pointer" }} onClick={() => setSorting("name")}>
          <FormattedMessage id="vps.management.name" defaultMessage="Name" />
          {sortBy === "name" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th style={{ cursor: "pointer" }} onClick={() => setSorting("summary")}>
          <FormattedMessage
            id="vps.management.summary"
            defaultMessage="Remark"
          />
          {sortBy === "summary" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th
          style={{ cursor: "pointer" }}
          onClick={() => setSorting("public_ip")}
        >
          <FormattedMessage
            id="vps.management.publicIp"
            defaultMessage="Public Ip"
          />
          {sortBy === "public_ip" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th
          style={{ cursor: "pointer" }}
          onClick={() => setSorting("VPS_status")}
        >
          <FormattedMessage
            id="vps.management.vpsStatus"
            defaultMessage="Status"
          />
          {sortBy === "VPS_status" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th
          style={{ cursor: "pointer", maxWidth: "64px", whiteSpace: "normal" }}
          onClick={() => setSorting("mt4Count")}
        >
          <FormattedMessage
            id="vps.management.mt4Count"
            defaultMessage="MT4 Count"
          />
          {sortBy === "mt4Count" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th
          style={{ cursor: "pointer" }}
          onClick={() => setSorting("createdAt")}
        >
          <FormattedMessage
            id="vps.management.createdAt"
            defaultMessage="Created At"
          />
          {sortBy === "createdAt" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th
          style={{ cursor: "pointer" }}
          onClick={() => setSorting("expiryDate")}
        >
          <FormattedMessage
            id="vps.management.expiryDate"
            defaultMessage="Expiry Date"
          />
          {sortBy === "expiryDate" ? (
            sortOrder === "ASC" ? (
              <BsSortDown />
            ) : (
              <BsSortUp />
            )
          ) : null}
        </th>
        <th>
          <FormattedMessage
            id="vps.management.actions"
            defaultMessage="Actions"
          />
        </th>
        <th>
          <FormattedMessage
            id="vps.management.subscribe"
            defaultMessage="Subscribe"
          />
        </th>
        <th>
          <FormattedMessage
            id="vps.management.vpsInfo"
            defaultMessage="VPS Info"
          />
        </th>
      </tr>
    </thead>
  );
};

export default ManagementHeader;
