import { MdMoreTime } from "react-icons/md";

interface ManagementRowSubscriptionButtonProps {
  email: string;
  englishFullName: string;
  vpsId: number;
  vpsType: {
    id: number;
    typeName: string;
  };
}

const urls = [
  "https://forexforest.com/hk/enroll/gems-panel-with-type-a-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-b-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-c-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-d-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-e-vps-package-renewal/",
];

const ManagementRowSubscriptionButton = (
  props: ManagementRowSubscriptionButtonProps,
) => {
  const { email, englishFullName, vpsId, vpsType } = props;
  const vpsTypeId = vpsType?.id || 5;

  let renew_url = `${urls[vpsTypeId - 1] || urls[4]}?`;
  renew_url += `billing_first_name=${englishFullName}`;
  renew_url += `&billing_email=${email}`;
  renew_url += `&billing_vps_id=${vpsId}`;

  return (
    <td>
      <a href={renew_url} target="_blank" rel="noopener noreferrer">
        <i>
          <MdMoreTime size={20} />
        </i>
      </a>
    </td>
  );
};

export default ManagementRowSubscriptionButton;
