export const USER_SIGNIN = "auth/signin";
export const EMAIL_CHECK_URL = "auth/email-check";
export const MOBILE_CHECK_URL = "auth/mobile-check";
export const USER_SIGNUP = "users/registration";
export const EMAIL_VERIFICATION_URL = "users/email-verification";
export const RESEND_OTP_URL = "/users/resend-otp";

export const GET_GIFT_LIST_URL = "gift/user/data";
export const ADD_GIFT_LIST_URL = "gift";
export const EIDT_GIFT_LIST_URL = "gift/redeem/";
export const VPS_GIFT_LIST_URL = "gift/user/vps";

export const GET_ANNOUNCEMENT_LIST_URL = "announcement/user/list";
export const GET_ANNOUNCEMENT_DETAIL_LIST_URL = "announcement/";
export const ADD_ANNOUNCEMENT_COMMENT_LIST_URL = "announcement/comment";
export const GET_ANNOUNCEMENT_COMMENT_LIST_URL = "announcement/comments/";
export const CHANGE_PASSWORD_URL = "users/change-password";

export const GET_TICKET_LIST_URL = "tickets";
export const ADD_TICKET_LIST_URL = "tickets";
export const EIDT_TICKET_LIST_URL = "tickets/";

export const GET_TICKET_ISSUE_TYPE_LIST_URL = "tickets/types/list";

export const GET_TICKET_COMMENT_LIST_URL = "tickets/comments/";
export const ADD_TICKET_COMMENT_LIST_URL = "tickets/comment/";

export const GET_TICKET_DETAIL_URL = "tickets/";

export const GET_VPS_MACHINE_LIST_URL = "vps";
export const GET_VPS_MACHINE_TYPE_LIST_URL = "vps/type";
export const CREATE_VPS_MACHINE_URL = "vps/create";

export const GET_CLASS_CATEGORY_URL = "class/category";
export const EDIT_CLASS_URL = "class/";
export const GET_ACTIVITY_LIST_URL = "students/activity";
export const GET_ATTENDENCE_LIST_URL = "students/activity/attendence-list";

export const UPLOAD_CLASS_SLIP_URL = "students/slip/";

export const GET_POINTS_LIST_URL = "log/point/";
export const MEDIA_IMAGE_UPLOAD_URL = "";

export const GET_ALL_ACCOUNT_LIST_URL = "/accounts/A";
export const GET_LIVE_ACCOUNT_LIST_URL = "/accounts/L";
export const GET_DEMO_ACCOUNT_LIST_URL = "/accounts/D";
export const GET_TEMPOARARY_ACCOUNT_LIST_URL = "accounts/temporary/list";
export const GET_ALTERNATIVE_NAME_LIST_URL = "accounts/altname/list";
export const ADD_ALTERNATIVE_NAME_LIST_URL = "accounts/altname/create";
export const GET_TRADING_ACCOUNT_LIST_URL = "/broker/user-trading-account";
export const GET_BROKER_LIST_URL = "/broker";
export const DELETE_TRADING_ACCOUNT_LIST_URL = "/broker/user-trading-account";

export const GET_INDUSTRY_LIST_URL = "industry/";
export const GET_JOB_LIST_URL = "industry/jobTitle/";

export const GET_REGISTRATION_MATERIAL_URL = "users/register/details/";

export const GET_EA_LIST_URL = "EA/user/list";
export const GET_SCRIPT_LIST_URL = "scripts/user/list";
export const GET_UTILITY_LIST_URL = "utility/user/list";
export const GET_INDICATOR_LIST_URL = "indicator/user/list";
export const GET_LIBRARY_LIST_URL = "library";
export const GET_CLASS_EXPERT_ADVISORS_LIST_URL = "EA/user/list";

export const GET_EA_VERSION_LIST_URL = "EA/version/user/";
export const GET_LIBRARY_VERSION_LIST_URL = "library/version/list/";
export const GET_INDICATOR_VERSION_LIST_URL = "indicator/version/user/";
export const GET_SCRIPT_VERSION_LIST_URL = "scripts/version/user/";
export const GET_UTILITY_VERSION_LIST_URL = "/utility/version/user/";

export const GET_MY_SIGNAL_LIST_URL = "signals/mySignal";
export const ADD_CLASS_SIGNAL_URL = "signals";
export const EDIT_CLASS_SIGNAL_URL = "signals/";
export const DELETE_CLASS_SIGNAL_URL = "signals/";
export const GET_CLASS_SIGNAL_LIST_URL = "signals/class";

export const GET_USER_REGISTERD_CLASS_URL = "signals/user/class";
export const GET_EA_UNAUTHORIZED_LIST_URL = "EA/unauthorized/list";
export const GET_UNAUTHORIZED_INDICATORS_LIST_URL =
  "indicator/unauthorized/list";

export const GET_UNAUTHORIZED_SCRIPT_LIST_URL = "scripts/unauthorized/list";
export const GET_UNAUTHORIZED_UTILITY_LIST_URL = "utility/unauthorized/list";

export const GET_TICKET_TOOL_VERSION_LIST_URL = "tickets/tools/versionList/";
export const GET_TICKET_TOOL_LIST_URL = "tickets/tools/list";

export const GET_QR_CODE_URL = "users/me";
export const REDEEM_ACCOUNT_URL = "points/redeem/";

export const ADD_ACCOUNT_LIST_URL = "accounts";
export const DELETE_ACCOUNT_LIST_URL = "accounts/delete";

export const REGISTER_ACTIVITY_LIST_URL = "students/activity/register";
export const VPS_MACHINE_CONTACT_LIST_URL = "vps/contact-us";
export const GET_UTILITY_UNAUTHORIZED_LIST_URL = "utility/unauthorized/list";

export const UPDATE_TICKET_ASSIGNEE_LIST_URL = "/tickets/group/list";
export const GET_USER_PRINT_DETAIL_URL = "users/print/";
export const GET_TICKET_ASSIGNEE_LIST_URL = "/tickets/group/list";
export const ALLOW_UNCHANGED_PASSWORD_URL = "/users/accept/threat";
export const GET_UNREAD_ANNOUNCEMENT_LIST_URL =
  "/announcement/user/unread/list";
export const MARKED_ANNOUNCEMENT_AS_READ_URL = "/announcement/mark/read";
export const DIRECT_EMAIL_VERIFICATION_URL = "/users/admin/email-verify";

export const GET_ALL_FAQ_LIST_URL = "faq/";
export const GET_REMINDER_VPS_EXPIRE = "/vps/getReminderVpsExpire";
export const GET_ALL_VPS_MACHINES_LIST = "/vps/vps-machines";
export const UPDATE_VPS_MACHINE_STATUS = "/vps/vps-machine-status";
export const UPDATE_VPS_MACHINE_PASSWORD = "/vps/vps-machine-password";
export const UPDATE_VPS_MACHINE_DETAIL = "/vps/vps-machines";
