/* eslint-disable no-case-declarations */
import {
  GET_CLASSES,
  REGISTER_STUDENT_IN_CLASS,
  GET_CLASSES_DETAIL,
  GET_ALL_CLASSES,
  GET_USER_REGISTERD_CLASS,
  UPLOAD_CLASS_SLIP,
} from "../constants/action-types";
import { ClassData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getToken } from "../../util/utils";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  isLoggedin: JSON.parse(getToken("isLoggedIn") || "false"),
  isLoggedout: false,
  isKencorLoggedin: false,
  isRegisterd: false,
  isForgotpasswordSuccess: false,
  authToken: "",
  myClasses: {},
  classDetail: {},
  posts: [],
  classCategories: [],
  allClass: [],
  registerdClass: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ClassData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_CLASSES.GET_CLASSES_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_CLASSES.GET_CLASSES_SUCCESS:
        const allPost: any = action.payload;
        return {
          ...state,
          myClasses: allPost.data,
          isLoading: false,
          totalRecord: allPost.count,
        };

      case GET_CLASSES.GET_CLASSES_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };
      case REGISTER_STUDENT_IN_CLASS.REGISTER_STUDENT_IN_CLASS_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case REGISTER_STUDENT_IN_CLASS.REGISTER_STUDENT_IN_CLASS_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case REGISTER_STUDENT_IN_CLASS.REGISTER_STUDENT_IN_CLASS_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };
      case GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_INITIALIZATION:
        return {
          ...state,
          classDetail: {},
          isLoading: true,
        };

      case GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_SUCCESS:
        const classDetail = action.payload;
        return {
          ...state,
          classDetail: classDetail,
          isLoading: false,
        };

      case GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ALL_CLASSES.GET_ALL_CLASSES_INITIALIZATION:
        return {
          ...state,
          allClass: [],
          isLoading: true,
        };

      case GET_ALL_CLASSES.GET_ALL_CLASSES_SUCCESS:
        return {
          ...state,
          allClass: action.payload,
          isLoading: false,
        };

      case GET_ALL_CLASSES.GET_ALL_CLASSES_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_USER_REGISTERD_CLASS.GET_USER_REGISTERD_CLASS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_USER_REGISTERD_CLASS.GET_USER_REGISTERD_CLASS_SUCCESS:
        return {
          ...state,
          registerdClass: action.payload,
          isLoading: false,
        };

      case GET_USER_REGISTERD_CLASS.GET_USER_REGISTERD_CLASS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case UPLOAD_CLASS_SLIP.UPLOAD_CLASS_SLIP_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case UPLOAD_CLASS_SLIP.UPLOAD_CLASS_SLIP_SUCCESS:
        return {
          ...state,
          // registerdClass: action.payload,
          isLoading: false,
        };

      case UPLOAD_CLASS_SLIP.UPLOAD_CLASS_SLIP_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
