import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { formatDate, saveFile } from "../../util/utils";
import Chip from "@mui/material/Chip";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { getHtml } from "../ExpertAvisors/CurrentVersion";

const CurrentVersion = ({ versionLst }: any) => {
  return (
    <div>
      {versionLst && versionLst.length ? (
        versionLst.map((v: any) => (
          <Card
            style={{
              width: "100%",
              boxShadow: "none",
              border: "1px solid #f3f3f3",
            }}
          >
            <Card.Body style={{ lineHeight: "1.5" }}>
              <h4 style={{ fontWeight: "500" }}>
                {v.version}
                {"   "}({formatDate(v.createdAt, "DD/MM/YYYY")})
              </h4>
              <Row className="mt-1 mb-1">
                <Col>
                  {/* <div dangerouslySetInnerHTML={{ __html: v.info }} /> */}
                  <Typography component="div">{getHtml(v.info)}</Typography>
                </Col>
              </Row>
              {/* {v?.libraryVersion.map((l: any) => ( */}
              <Chip
                size={"small"}
                variant="outlined"
                label={
                  <>
                    <FormattedMessage
                      id="subHead.LibararyVersion"
                      defaultMessage="Libarary Version"
                    />
                    <>{`: ${v?.library?.name || "GEMS.Dll"} - ${v
                      ?.libraryVersion?.version}`}</>
                  </>
                }
              />
              {/* ))} */}
              <Row className="mt-3">
                <Col>
                  {v.download_path ? (
                    <Button
                      style={{ width: "100%" }}
                      size="sm"
                      onClick={() => saveFile(v.download_path)}
                    >
                      <FormattedMessage
                        id="expert.Current.DownloadTool"
                        defaultMessage="Download Tool"
                      />
                    </Button>
                  ) : (
                    <Button style={{ width: "100%" }} size="sm" disabled>
                      <FormattedMessage
                        id="expert.Current.DownloadTool"
                        defaultMessage="Download Tool"
                      />
                    </Button>
                  )}
                </Col>
                <Col>
                  {v.manual_path ? (
                    <Button
                      color="danger"
                      onClick={() => saveFile(v.manual_path)}
                      style={{ width: "100%", backgroundColor: "" }}
                      size="sm"
                    >
                      <FormattedMessage
                        id="expert.Current.DownloadManual"
                        defaultMessage="Download Manual"
                      />
                    </Button>
                  ) : (
                    <Button
                      color="danger"
                      style={{
                        width: "100%",
                        backgroundColor: "",
                        visibility: "hidden",
                      }}
                      size="sm"
                      disabled
                    >
                      <FormattedMessage
                        id="expert.Current.DownloadManual"
                        defaultMessage="Download Manual"
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))
      ) : (
        <NoDatFound />
      )}
    </div>
  );
};

export default CurrentVersion;
