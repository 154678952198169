import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllClasses, registerStudentInClass } from "../../redux/action";
import { Store } from "../../redux/Actions";
import noImage from "../../assets/img/noImage.jpeg";
import RegisterClass from "../../Components/Class/RegisterClass";
import { FormattedMessage } from "react-intl";

export const NewClass = () => {
  const dispatch = useDispatch();

  const lstClass =
    useSelector((state: Store) => state.classReducer.allClass) || [];
  const isLoading = useSelector((state: Store) => state.classReducer.isLoading);

  const [lstClasses, setLstClasses] = useState<any>([]);
  const [modal, setModal] = useState(false);
  const [classId, setClassId] = useState(false);

  useEffect(() => {
    dispatch(getAllClasses());
  }, []);

  useEffect(() => {
    setLstClasses(lstClass);
  }, [lstClass]);

  const registerStudent = (classVal: string) => {
    dispatch(registerStudentInClass(classVal, true));
  };

  return (
    <div className="card dashboard-gifts-card mb-0 no-shadow">
      <div className="card-body" style={{ paddingLeft: "0" }}>
        <div className="card-box">
          {lstClasses.length ? (
            lstClasses.map((i: any, index: any) => (
              <span
                onClick={() => {
                  setModal(true);
                  setClassId(i);
                }}
                title={i?.name}
                className="badges c-p"
                style={{ flex: "0 0 auto" }}
              >
                {i?.image_path ? (
                  <img src={i?.image_path} alt="Class" />
                ) : (
                  <img src={noImage} alt="Class" />
                )}
                {/* <img src={i?.giftCategory?.beforeImage} alt="Class" /> */}
                <span className="badge-class">{i?.name}</span>
              </span>
            ))
          ) : (
            <p>
              <FormattedMessage
                id="dashboard.NoNewClassFound"
                defaultMessage="No New Class Found"
              />
            </p>
          )}

          {/* <span className="badges">
            <img src={noImage} alt="Class" />
            <span className="badge-class">FF192</span>
          </span>

          <span className="badges">
            <img src={noImage} alt="Class" />
            <span className="badge-class">Test class</span>
          </span>

          <span className="badges">
            <img src={noImage} alt="Class" />
            <span className="badge-class">ww</span>
          </span>

          <span className="badges">
            <img src={noImage} alt="Class" />
            <span className="badge-class">FF191</span>
          </span> */}
          {modal && (
            <RegisterClass
              classId={classId}
              onClassRegister={(values: any) => registerStudent(values)}
              onCancle={() => setModal(false)}
              showModal={modal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewClass;
