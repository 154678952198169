import { useState, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { dispatch } from "../../redux";
import {
  getallVpsMachinesList,
  updateVpsStatus,
  vpsResetPassword,
} from "../../redux/action/vpsActions";
import { useFormik } from "formik";
import { setNewVPSPasswordSchema } from "../../util/validationSchema";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Pagination from "../../Components/common/Paginate/Pagination";
import { FaTimesCircle } from "react-icons/fa";
import ChangeVpsStatusModal from "../../Components/common/Popups/ChangeVpsStatusModal";
import ManagementTable from "../../Components/VpsMachine/VpsManagement/ManagementTable";
import { toast } from "react-toastify";
import ManagementRowUpdateDetailsModal from "../../Components/VpsMachine/VpsManagement/ManagementRowUpdateDetailsModal";
import { UpdateDetailsPayload } from "../../Components/VpsMachine/VpsManagement";

const initialUpdateDetailsPayload = {
  modal: false,
  instanceId: "",
  name: "",
  summary: "",
  text: "",
};

const VpsManagement = () => {
  const isAllVpsMachinesListLoading = useSelector(
    (state: Store) => state.vpsReducer.isAllVpsMachinesListLoading,
  );
  const totalVpsMachinesCount = useSelector(
    (state: Store) => state.vpsReducer.totalVpsMachinesCount,
  );
  const allVpsMachinesList = useSelector(
    (state: Store) => state.vpsReducer.allVpsMachinesList,
  );

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");

  const [changeVpsStatus, setChangeVpsStatus] = useState({
    modal: false,
    instanceId: "",
    vpsStatus: "",
    type: 0,
    text: "start",
  });

  const [updateDetailsPayload, setUpdateDetailsPayload] =
    useState<UpdateDetailsPayload>(initialUpdateDetailsPayload);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: setNewVPSPasswordSchema,
    onSubmit: (values: any) => {
      dispatch(
        vpsResetPassword(true, {
          instanceid: changeVpsStatus?.instanceId,
          password: values.password,
        }),
      );
      resetForm();
      setChangeVpsStatus({
        modal: false,
        instanceId: "",
        vpsStatus: "",
        type: 0,
        text: "start",
      });
    },
  });

  const { errors, touched, values, resetForm } = formik;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const onChangeStatus = async (key: any) => {
    dispatch(updateVpsStatus(true, key));
    setChangeVpsStatus({
      modal: false,
      instanceId: "",
      vpsStatus: "",
      type: 0,
      text: "start",
    });
  };

  const onUpdateDetails = async (key: any) => {
    // dispatch(updateDetails(true, key));
    toast.success("Feature coming soon");
    setUpdateDetailsPayload(initialUpdateDetailsPayload);
  };

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getallVpsMachinesList(
          false,
          pageNumber,
          { by: sortBy, order: sortOrder },
          parseInt(pageLimit),
        ),
      );
    }
  }, [sortOrder, sortBy, pageNumber, pageLimit]);

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-right">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="menu.vpsMachine.management"
                  defaultMessage="VPS Management"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <div className="table-responsive">
                {isAllVpsMachinesListLoading && <CenterLoader />}
                {allVpsMachinesList && allVpsMachinesList.length ? (
                  <ManagementTable
                    pageNumber={pageNumber}
                    pageLimit={pageLimit}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSorting={setSorting}
                    allVpsMachinesList={allVpsMachinesList}
                    setChangeVpsStatus={setChangeVpsStatus}
                    setUpdateDetailsPayload={setUpdateDetailsPayload}
                  />
                ) : (
                  <p>{!isAllVpsMachinesListLoading && <NoDatFound />}</p>
                )}
              </div>
              {allVpsMachinesList.length && totalVpsMachinesCount > 10 ? (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalVpsMachinesCount}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              ) : null}
            </Card.Body>
          </Card>
        </section>
      </div>
      {changeVpsStatus.modal && changeVpsStatus.text !== "resetPassword" ? (
        <ChangeVpsStatusModal
          onClose={() => {
            setChangeVpsStatus({
              modal: false,
              instanceId: "",
              vpsStatus: "",
              type: 0,
              text: "start",
            });
          }}
          onDeleteClick={() =>
            onChangeStatus({
              instance_id: changeVpsStatus?.instanceId,
              status: changeVpsStatus?.vpsStatus,
              type: changeVpsStatus?.type,
            })
          }
          open={changeVpsStatus.modal}
          text={changeVpsStatus.text}
        />
      ) : null}
      {changeVpsStatus.modal && changeVpsStatus.text === "resetPassword" ? (
        <Modal
          centered
          size={"lg"}
          show={changeVpsStatus.modal}
          className="modal-content-md"
        >
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <Modal.Header>
              <h4 className="modal-title">
                <FormattedMessage
                  id="vps.management.message.resetPassword"
                  defaultMessage="Reset Password"
                />
              </h4>
              <button
                className="close"
                onClick={() => {
                  setChangeVpsStatus({
                    modal: false,
                    instanceId: "",
                    vpsStatus: "",
                    type: 0,
                    text: "start",
                  });
                  resetForm();
                }}
              >
                <FaTimesCircle />
              </button>
            </Modal.Header>
            <Modal.Body style={{ height: "auto" }}>
              <div className="form-group">
                <FormattedMessage
                  id="select.newPassword"
                  defaultMessage="Password"
                />
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {errors.password && touched.password && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.password.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>

              <div className="form-group">
                <FormattedMessage
                  id="head.RetypePassword"
                  defaultMessage="Retype New Password"
                />
                <input
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className="form-control"
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.confirmPassword.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  setChangeVpsStatus({
                    modal: false,
                    instanceId: "",
                    vpsStatus: "",
                    type: 0,
                    text: "start",
                  });
                  resetForm();
                }}
              >
                <FormattedMessage
                  id="vps.management.message.cancel"
                  defaultMessage="Cancel"
                />
              </button>
              <button type="submit" className="btn btn-primary">
                <FormattedMessage id="page.submit" defaultMessage="Submit" />
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      ) : null}
      {updateDetailsPayload.modal ? (
        <ManagementRowUpdateDetailsModal
          onClose={() => setUpdateDetailsPayload(initialUpdateDetailsPayload)}
          onConfirmClick={() => onUpdateDetails({})}
          open={updateDetailsPayload.modal}
          instanceId={updateDetailsPayload.instanceId}
          name={updateDetailsPayload.name}
          summary={updateDetailsPayload.summary}
          text={updateDetailsPayload.text}
        />
      ) : null}
    </div>
  );
};
export default VpsManagement;
