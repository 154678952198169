/* eslint-disable no-case-declarations */
import {
  GET_ACTIVITY_LIST,
  REGISTER_ACTIVITY_LIST,
} from "../constants/action-types";
import { ActivityData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  activityLst: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ActivityData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_SUCCESS:
        const allPost: any = action.payload;

        return {
          ...state,
          activityLst: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };

      case REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
