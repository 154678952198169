import moment from "moment";
import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("register.step2.email")
    .required("register.step2.email.require"),
  password: Yup.string().required("register.step2.pass.require"),
});

export const forgotPasswordSchema1 = Yup.object().shape({
  email: Yup.string()
    .email("register.step2.email")
    .required("register.step2.email.require"),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("register.step2.email")
    .required("register.step2.email.require"),
  OTP: Yup.string().length(4, "otp.required").required("otp.required"),
});

export const softwareUpdateSchema = Yup.object().shape({
  toolType: Yup.number().integer().moreThan(-1, "ad.tool.required"),
  type: Yup.number().integer().moreThan(0, "ad.type.required"),
  version: Yup.number().integer().moreThan(0, "version.required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, "password.invalid")
    .required("changePassword.newPassword")
    .test("isValidPass", "password.invalid", (value: any, context: any) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "changePassword.confirmNewPassword")
    .required("changePassword.confirmPassword"),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("changePassword.oldPassword"),
  password: Yup.string()
    .min(9, "password.invalid")
    .required("changePassword.newPassword")
    .test("isValidPass", "password.invalid", (value: any, context: any) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "changePassword.confirmNewPassword")
    .required("changePassword.confirmNewPassword.req"),
});

export const RegisterStep2Schema = Yup.object().shape({
  email: Yup.string()
    .email("register.step2.email")
    .required("register.step2.email.require"),
  password: Yup.string()
    .min(9, "password.invalid")
    .required("register.step2.pass.require")
    .test("isValidPass", "password.invalid", (value: any, context: any) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "register.confirm.passwordMatch")
    .required("register.confirm.password"),
});

const mobileRegx = /^[0-9]{8}$/;
const nameRegx = /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/;

export const getRegisterStep3Schema = (mobileNumDigit: any) => {
  const RegisterStep3Schema = Yup.object().shape({
    title: Yup.string().required("register.step3.title.required"),
    chineseLastName: Yup.string(),
    chineseFirstName: Yup.string(),
    englishLastName: Yup.string()
      .required("register.step3.EnglishLname.required")
      .matches(nameRegx, "register.step3.EnglishLname.invalid"),
    englishFirstName: Yup.string()
      .required("register.step3.EnglishFname.required")
      .matches(nameRegx, "register.step3.EnglishFname.invalid"),

    preferredNickName: Yup.string().required(
      "register.step3.Prefferedname.required",
    ),
    industry: Yup.string().required("register.step3.Industry.required"),
    jobTitle: Yup.string().required("register.step3.Job.required"),
    DOB: Yup.string()
      .required("register.step3.Dob.required")
      .test("DOB", "register.step3.Dob.valid", (value) => {
        return (
          moment().diff(moment(value), "years") > 16 &&
          moment().diff(moment(value), "years") < 90
        );
      }),
    phoneNumber: Yup.string().required("register.step3.Contact.required"),
    district: Yup.number()
      .min(0, "register.step3.District.required")
      .required("register.step3.District.required"),
  });

  return RegisterStep3Schema;
};

// const altName = /^[0-9]{1,2}[A-Z,a-z]{0,500}[A-Z,a-z]$/;
const altName = /^[0-9][^\s]+$/;
export const AddAlternativeNameSchema = Yup.object().shape({
  altName: Yup.string()
    .required("altName.name.required")
    .max(255, "head.maxLength")
    .matches(altName, "altName.name.valid"),
});

const hkId = /^[A-Z]{1,2}[0-9]{2}[0-9]$/;
const hkId1 = /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/;
const passportId = /^[0-9,A-Z,a-z]{6,6}$/;

export const RegisterStep4Schema = Yup.object().shape({
  identityType: Yup.string().required("register.step4.Indentity.required"),
  identityId: Yup.string()
    .required("register.step4.IdNumber.required")
    .when("identityType", {
      is: "Hong kong Id Card",
      then: Yup.string()
        .trim()
        .required("register.step4.HKIdNumber.required")
        .matches(hkId, "register.step4.HKIdNumber.valid"),
    })
    .when("identityType", {
      is: "Passport",
      then: Yup.string()
        .required("register.step4.IdNumber.required")
        .matches(passportId, "register.step4.IdNumber.valid"),
    }),
  idImage: Yup.mixed().required("register.step4.IdDoc.required"),
});

export const RegisterStep5Schema = Yup.object().shape({
  avatar: Yup.mixed().required("register.step5.avatar.required"),
  signature: Yup.string().required("register.step5.Signature.required"),
});

export const RegisterStep6Schema = Yup.object().shape({
  classChoice: Yup.number()
    .integer()
    .min(1, "register.step6.classChoice")
    .required("register.step6.classChoice"),
  repeaterClass: Yup.number()
    .integer()
    .min(1, "register.step6.repeateChoice")
    .required("register.step6.repeateChoice"),
});

export const RegisterStep7Schema = Yup.object().shape({
  paymentType: Yup.number()
    .integer()
    .min(1, "register.step7.Course.required")
    .required("register.step7.Course.required"),
  slipPath: Yup.string().required("register.step7.Course1.required"),
});

export const AddTicketSchema = Yup.object().shape({
  content: Yup.string().trim().required("ticketmodal.content.required"),
  title: Yup.string().trim().required("ticketmodal.title.required"),
  software: Yup.number()
    .integer()
    .min(1, "ticketmodal.soft.required")
    .required("ticketmodal.soft.required"),
  version: Yup.number()
    .integer()
    .min(1, "ticketmodal.version.required")
    .required("ticketmodal.version.required"),
  type: Yup.number()
    .integer()
    .min(1, "ticketmodal.Issue.required")
    .required("ticketmodal.Issue.required"),
  ticketgroup: Yup.number()
    .integer()
    .min(1, "ticketmodal.ticketgroup.required")
    .required("ticketmodal.ticketgroup.required"),
});

export const ClassRegisterSchema = Yup.object().shape({
  paymentType: Yup.number()
    .integer()
    .min(1, "register.step7.Course.required")
    .nullable(false)
    .label("register.step7.Course.required"),
  signature: Yup.mixed().required("register.step5.Signature.required"),
});

export const AddAccountSchema = Yup.object().shape({
  accountNumber: Yup.string()
    // .integer("accountmodal.account.required")
    // .min(1, "accountmodal.account.required")
    // .max(15, "accountmodal.account.invalid")
    .nullable(true)
    .required("accountmodal.account.required")
    .matches(
      // /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      /^[0-9]*$/,
      "accountmodal.account.invalid",
    )
    .test("len", "accountmodal.account.invalid", (val: any, context: any) => {
      // val !==null ?
      // const isnum = /^[0-9]*$/.test(val);
      console.log("val", val);
      if (val !== null && val !== undefined && val.toString().length <= 15) {
        return true;
      }

      return false;
    }),
  EA_version: Yup.array()
    .min(1, "accountmodal.EAVersion.required")
    .required("accountmodal.EAVersion.required"),
  // broker: Yup.number()
  //   .min(1, "accountmodal.Broker.required")
  //   .required("accountmodal.Broker.required"),
  EA_type: Yup.number()
    .integer()
    .min(1, "accountmodal.EA.required")
    .required("accountmodal.EA.required"),
});

export const AddTypedAccountSchema = Yup.object().shape({
  accountNumber: Yup.string()
    // .integer("accountmodal.account.required")
    // .min(1, "accountmodal.account.required")
    // .max(15, "accountmodal.account.invalid")
    .nullable(true)
    .required("accountmodal.account.required")
    .matches(
      // /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      /^[0-9]*$/,
      "accountmodal.account.invalid",
    )
    .test("len", "accountmodal.account.invalid", (val: any, context: any) => {
      // val !==null ?
      // const isnum = /^[0-9]*$/.test(val);
      console.log("val", val);
      if (val !== null && val !== undefined && val.toString().length <= 15) {
        return true;
      }

      return false;
    }),
  EA_version: Yup.array()
    .min(1, "accountmodal.EAVersion.required")
    .required("accountmodal.EAVersion.required"),
  // broker: Yup.number()
  //   .min(1, "accountmodal.Broker.required")
  //   .required("accountmodal.Broker.required"),
  EA_type: Yup.number()
    .integer()
    .min(1, "accountmodal.EA.required")
    .required("accountmodal.EA.required"),
  type: Yup.string().matches(/(L|D)/).required("accountmodal.type.required"),
});

export const RedeemPermanantEASchema = Yup.object().shape({
  EA_version: Yup.number()
    .min(1, "accountmodal.EAVersion.required")
    .required("accountmodal.EAVersion.required"),
  EA: Yup.number()
    .integer()
    .min(1, "accountmodal.EA.required")
    .required("accountmodal.EA.required"),
});

export const AddSignalSchema = Yup.object().shape({
  mql5_link: Yup.string().required("signalmodal.link.required"),
  classId: Yup.number()
    .integer()
    .min(1, "signalmodal.class.required")
    .required("signalmodal.class.required"),
  name: Yup.string().required("signalmodal.name.equired"),
  access_rights: Yup.number()
    .integer()
    .min(0, "signalmodal.ar.equired")
    .required("signalmodal.ar.equired"),
});

export const AddSAlternativeSchema = Yup.object().shape({
  mql5_link: Yup.string().required("signalmodal.link.required"),
  classId: Yup.string().required("signalmodal.class.required"),
  name: Yup.string().required("signalmodal.name.equired"),
});

export const UploadSignatureSchema = Yup.object().shape({
  signature: Yup.mixed().required("Signature is required"),
});

export const setNewVPSPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, "password.invalid")
    .required("changePassword.newPassword")
    .test("isValidPass", "password.invalid", (value: any, context: any) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  // Yup.string().required("changePassword.newPassword"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "changePassword.confirmNewPassword")
    .required("changePassword.confirmNewPassword.req"),
});
