import axios from "axios";
import { toast } from "react-toastify";
import { getToken, valiadteToken } from "../util/utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((req: any) => {
  if (valiadteToken()) {
    const token = getToken("login-auth-token");
    const auth = token ? `Bearer ${token}` : "";
    req.headers.common.Authorization = auth;
    req.headers.common["access-control-allow-origin"] = "*";
  }
  return req;
});

instance.interceptors.response.use((res) => {
  try {
    if (res.status === 200 || res.status === 201 || res.status === 204) {
      return res;
    } else if (res.data !== "") {
      toast.error("Somthing went wrong.");
    } else {
      toast.error("Somthing went wrong.");
    }
  } catch (err) {
    toast.error(res.data.data.message);
  }
  return res;
});

export default instance;
