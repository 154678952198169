/* eslint-disable no-case-declarations */
import {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  SET_CURRENT_STEP_BACK,
  SET_REGISTER,
  LOGOUT,
  SET_REGISTER_STEPS,
  CHANGE_LOCAL,
  GET_QR_CODE,
  EMAIL_CHECK,
  MOBILE_CHECK,
  EMAIL_VERIFICATION,
  RESEND_OTP,
  FP_EMAIL_VERIFIED,
  CHANGE_PASSWORD,
  SET_THEME,
  ALLOW_UNCHANGED_PASSWORD,
  DIRECT_EMAIL_VERIFICATION,
} from "../constants/action-types";
import { UserData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { valiadteToken } from "../../util/utils";
import { ActionTypes } from "../action";
import secureLocalStorage from "react-secure-storage";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  isLoggedin: valiadteToken(),
  isLoggedout: false,
  isKencorLoggedin: false,
  isRegisterd: false,
  isForgotpasswordSuccess: false,
  isFPEmail: false,
  authToken: "",
  isResendOtpLoading: false,
  user: {},
  posts: [],
  completedStep: -1,
  totalStep: 6,
  userInfo: [],
  userObject: {},
  myInfo: {},
  isEmailVerified: true,
  otpVerified: false,
  isResended: false,
  uniqueEmail: false,
  uniqueMobile: false,
  errorMessage: "",
  darkTheme: JSON.parse(localStorage.getItem("darkTheme")),
  email_send_success: false,
  isThreatAccepted: false,
  isUpdatePasswordRequire: false,
  local: localStorage.getItem("local") || "en",
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: UserData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case CHANGE_LOCAL:
        localStorage.setItem("local", action.payload.toString());
        return {
          ...state,
          local: action.payload.toString(),
        };

      case "EMAIL_VERIFIED":
        return {
          ...state,
          isResended: false,
          errorMessage: "",
          otpVerified: false,
          isEmailVerified: action.payload,
        };

      case "SET_USERINFO_EMPTY":
        return {
          ...state,
          userInfo: [],
          userObject: {},
        };
      case SET_CURRENT_STEP_BACK:
        return {
          ...state,
          completedStep: action.payload,
        };

      case SET_REGISTER:
        return {
          ...state,
          isRegisterd: action.payload === true ? true : false,
        };

      case SET_THEME:
        const a: any = action.payload === true ? "true" : "false";
        localStorage.setItem("darkTheme", a);
        return {
          ...state,
          darkTheme: action.payload === true ? true : false,
        };

      case SET_REGISTER_STEPS:
        const { completedStep, totalStep, userInfo }: any = action.payload;
        const tmpUserInfo = state.userInfo || [];
        tmpUserInfo[completedStep] = userInfo;
        localStorage.setItem(
          "register_completion",
          JSON.stringify(tmpUserInfo),
        );
        const usr = {
          ...state.userObject,
          ...userInfo,
        };
        return {
          ...state,
          completedStep: completedStep,
          totalStep: totalStep,
          userInfo: tmpUserInfo,
          userObject: usr,
        };

      case LOGIN.LOGIN_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
          errorMessage: "",
          otpVerified: false,
          isLoggedout: false,
        };
      case LOGIN.LOGIN_SUCCESS:
        const user: any = action.payload;
        return {
          ...state,
          user: user,
          ...action.payload,
          errorMessage: "",
          isLoggedin: true,
          otpVerified: false,
          authToken: user.response.data.access_token,
          isLoading: false,
          isLoggedout: false,
          progress: true,
          isEmailVerified: true,
        };

      case LOGIN.LOGIN_ERORR:
        const loginEr: any = action.payload;
        return {
          ...state,
          ...action.payload,
          otpVerified: false,
          errorMessage: loginEr.msg,
          isLoggedin: false,
          isLoading: false,
          progress: false,
          isEmailVerified: loginEr.email,
        };
      case REGISTER.REGISTER_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          otpVerified: false,
          isLoading: true,
        };
      case REGISTER.REGISTER_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isRegisterd: true,
          otpVerified: false,
          isLoading: false,
          completedStep: -1,
          userInfo: {},
          userObject: {},
        };
      case REGISTER.REGISTER_ERORR:
        return {
          ...state,
          ...action.payload,
          isRegisterd: false,
          otpVerified: false,
          isLoading: false,
        };

      case EMAIL_VERIFICATION.EMAIL_VERIFICATION_INITIALIZATION:
        return {
          ...state,
          ...action.payload,
          otpVerified: false,
          isLoading: true,
        };
      case EMAIL_VERIFICATION.EMAIL_VERIFICATION_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isEmailVerified: true,
          otpVerified: true,
          isLoading: false,
        };
      case EMAIL_VERIFICATION.EMAIL_VERIFICATION_ERROR:
        return {
          ...state,
          ...action.payload,
          isRegisterd: false,
          otpVerified: false,
          isLoading: false,
        };

      case DIRECT_EMAIL_VERIFICATION.DIRECT_EMAIL_VERIFICATION_INITIALIZATION:
        return {
          ...state,
          isEmailVerified: -1,
          isLoading: true,
        };
      case DIRECT_EMAIL_VERIFICATION.DIRECT_EMAIL_VERIFICATION_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isEmailVerified: true,
          isLoading: false,
        };
      case DIRECT_EMAIL_VERIFICATION.DIRECT_EMAIL_VERIFICATION_ERROR:
        return {
          ...state,
          isEmailVerified: false,
          isLoading: false,
        };

      case LOGOUT.LOGOUT_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
        };
      case LOGOUT.LOGOUT_SUCCESS:
        sessionStorage.clear();
        localStorage.clear();
        secureLocalStorage.clear();
        return {
          ...state,
          ...action.payload,
          isLoggedout: true,
          isLoggedin: false,
          isLoading: false,
        };
      case LOGOUT.LOGOUT_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoggedout: false,
          isLoading: false,
        };

      case FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
        };
      case FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isForgotpasswordSuccess: true,
          isLoading: false,
        };
      case FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR:
        return {
          ...state,
          ...action.payload,
          isForgotpasswordSuccess: false,
          isLoading: false,
        };

      case GET_QR_CODE.GET_QR_CODE_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };
      case GET_QR_CODE.GET_QR_CODE_SUCCESS:
        const data: any = action.payload;
        return {
          ...state,
          myInfo: action.payload,
          isThreatAccepted: data.isThreatAccepted,
          isUpdatePasswordRequire: data.isUpdatePasswordRequire,
          isLoading: false,
        };
      case GET_QR_CODE.GET_QR_CODE_ERROR:
        return {
          ...state,
          myInfo: {},
          isLoading: false,
        };

      case EMAIL_CHECK.EMAIL_CHECK_INITLIZATION:
        return {
          ...state,
          uniqueEmail: null,
          isLoading: true,
        };
      case EMAIL_CHECK.EMAIL_CHECK_SUCCESS:
        return {
          ...state,
          uniqueEmail: true,
          isLoading: false,
        };
      case EMAIL_CHECK.EMAIL_CHECK_ERORR:
        return {
          ...state,
          uniqueEmail: false,
          isLoading: false,
        };

      case MOBILE_CHECK.MOBILE_CHECK_INITLIZATION:
        return {
          ...state,
          uniqueMobile: false,
          isLoading: true,
        };
      case MOBILE_CHECK.MOBILE_CHECK_SUCCESS:
        return {
          ...state,
          uniqueMobile: true,
          isLoading: false,
        };
      case MOBILE_CHECK.MOBILE_CHECK_ERORR:
        return {
          ...state,
          uniqueMobile: false,
          isLoading: false,
        };
      case RESEND_OTP.RESEND_OTP_INITIALIZATION:
        return {
          ...state,
          isResended: false,
          email_send_success: false,
          isResendOtpLoading: false,
        };
      case RESEND_OTP.RESEND_OTP_SUCCESS:
        return {
          ...state,
          isResended: true,
          email_send_success: action.payload,
          isResendOtpLoading: false,
        };
      case RESEND_OTP.RESEND_OTP_ERROR:
        return {
          ...state,
          isResended: false,
          email_send_success: action.payload,
          isResendOtpLoading: false,
        };
      case FP_EMAIL_VERIFIED.FP_EMAIL_VERIFIED_INITLIZATION:
        return {
          ...state,
          isLoading: true,
          isFPEmail: false,
          email_send_success: false,
        };

      case FP_EMAIL_VERIFIED.FP_EMAIL_VERIFIED_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isFPEmail: true,
          email_send_success: true,
        };

      case FP_EMAIL_VERIFIED.FP_EMAIL_VERIFIED_ERORR:
        return {
          ...state,
          isLoading: false,
          isFPEmail: false,
          email_send_success: false,
        };
      case CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION:
        return {
          ...state,
          errorMessage: "",
          onSaveLoading: true,
        };
      case CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          errorMessage: "",
          onSaveLoading: false,
        };
      case CHANGE_PASSWORD.CHANGE_PASSWORD_ERORR:
        return {
          ...state,
          errorMessage: action.payload,
          onSaveLoading: false,
        };

      case ALLOW_UNCHANGED_PASSWORD.ALLOW_UNCHANGED_PASSWORD_INITIALIZATION:
        return {
          ...state,
          errorMessage: "",
          onSaveLoading: true,
        };
      case ALLOW_UNCHANGED_PASSWORD.ALLOW_UNCHANGED_PASSWORD_SUCCESS:
        return {
          ...state,
          errorMessage: "",
          onSaveLoading: false,
        };
      case ALLOW_UNCHANGED_PASSWORD.ALLOW_UNCHANGED_PASSWORD_ERROR:
        return {
          ...state,
          errorMessage: action.payload,
          onSaveLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
