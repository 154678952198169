/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import { GET_POINTS_LIST, REDEEM_ACCOUNT } from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  GET_POINTS_LIST_URL,
  REDEEM_ACCOUNT_URL,
} from "../../service/apiEndPoints";
import { getQR } from "./userActions";

export const getPointList =
  (
    showToast = true,
    pointType: any,
    pageNumber: number,
    sort = { by: "", order: "" },
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getPointListInit(
        showToast,
        pointType,
        pageNumber,
        sort,
        filter,
        pageLimit,
      ),
    );
  };

export const getPointListInit =
  (
    showToast = true,
    pointType: any,
    pageNumber: number,
    sort: any,
    filter,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_POINTS_LIST.GET_POINTS_LIST_INITIALIZATION,
    });
    let sortStr = `&sort=${sort.order}&sortBy=${sort.by}`;
    if (sort.order === "" || sort.by === "") {
      sortStr = "";
    }
    apiCall(
      `${GET_POINTS_LIST_URL}${pointType}?page=${pageNumber}${sortStr}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getPointListSuccess(res)),
      (err: any) => dispatch(getPointListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getPointListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_POINTS_LIST.GET_POINTS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getPointListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_POINTS_LIST.GET_POINTS_LIST_ERROR,
    });
  };

export const redeemAccount =
  (
    showToast = true,
    type: any,
    page: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(redeemAccountInit(showToast, type, page));
  };

export const redeemAccountInit =
  (
    showToast = true,
    type: any,
    page: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REDEEM_ACCOUNT.REDEEM_ACCOUNT_INITIALIZATION,
    });
    apiCall(
      REDEEM_ACCOUNT_URL + type,
      (res: Object) => dispatch(redeemAccountSuccess(res, type, page)),
      (err: any) => dispatch(redeemAccountError()),
      {},
      METHOD.POST,
      { showToast },
    );
  };

export const redeemAccountSuccess =
  (
    res: any,
    type,
    page: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REDEEM_ACCOUNT.REDEEM_ACCOUNT_SUCCESS,
      payload: res.data,
    });
    const point_cat =
      parseInt(localStorage.getItem("point_ActiveTab") || "5") || 5;
    dispatch(getPointList(false, point_cat, page));
    dispatch(getQR());
  };

export const redeemAccountError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REDEEM_ACCOUNT.REDEEM_ACCOUNT_ERROR,
    });
  };
