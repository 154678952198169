import React from "react";
import { useSelector } from "react-redux";
import { customFormatDatetimeWithLocale } from "../../../util/utils";
import { Store } from "../../../redux/Actions";
import ManagementRowActionsDropdown from "./ManagementRowActionsDropdown";
import ManagementRowInfoTooltip from "./ManagementRowInfoTooltip";
import ManagementRowSubscriptionButton from "./ManagementRowSubscriptionButton";
import ManagementRowRemarksTooltip from "./ManagementRowRemarksTooltip";
import ManagementRowNameTooltip from "./ManagementRowNameTooltip";
import ManagementRowCopyOnClick from "./ManagementRowCopyOnClick";
import { toast } from "react-toastify";
import moment from "moment-timezone";

export interface ChangeVpsStatus {
  modal: boolean;
  instanceId: string;
  vpsStatus: string;
  type: number;
  text: string;
}

export interface AddSubscriptionPayload {
  modal: boolean;
  instanceId: string;
  period: string;
  text: string;
}

export interface UpdateDetailsPayload {
  modal: boolean;
  instanceId: string;
  name: string;
  summary: string;
  text: string;
}

export interface VpsDetail {
  name: string;
  summary: string;
  public_ip: string;
  VPS_status: string;
  mt4Count: number;
  createdAt: string;
  expiryDate: string;

  id: number;
  instance: string;
  vpsType: {
    id: number;
    typeName: string;
  };
}

interface ManagementRowProps {
  rowNumber: number;
  vpsDetail: VpsDetail;
  setChangeVpsStatus: React.Dispatch<React.SetStateAction<ChangeVpsStatus>>;
  setUpdateDetailsPayload: React.Dispatch<
    React.SetStateAction<UpdateDetailsPayload>
  >;
}

const ManagementRow = (props: ManagementRowProps) => {
  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";

  const myInfo = useSelector((state: Store) => state.userDataReducer.myInfo);
  const email = myInfo.email;
  const englishFirstName = myInfo.englishFirstName;
  const englishLastName = myInfo.englishLastName;
  const englishFullName = `${englishFirstName} ${englishLastName}`;

  const { rowNumber, setChangeVpsStatus, setUpdateDetailsPayload } = props;
  const {
    name,
    summary,
    public_ip,
    VPS_status,
    mt4Count,
    createdAt,
    expiryDate,
    id,
    instance,
    vpsType,
  } = props.vpsDetail;

  const updateUpdateDetailPayLoad = () => {
    toast.success("Feature coming soon");
    setUpdateDetailsPayload({
      modal: true,
      instanceId: instance,
      name,
      summary,
      text: "updateDetails",
    });
  };

  const checkExpiryDate = (date) => {
    let outputDate = date;

    if (locale !== "en") {
      outputDate = date.replace(/年|月/g, "-").replace(/日/, " ");
    }

    const twoWeeks: any = new Date(
      moment.tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm"),
    );
    const oneWeeks: any = new Date(
      moment.tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm"),
    );

    twoWeeks.setDate(twoWeeks.getDate() + 14);
    oneWeeks.setDate(oneWeeks.getDate() + 7);

    if (
      moment(outputDate).format("YYYY-MM-DD HH:mm") >
      moment(twoWeeks).format("YYYY-MM-DD HH:mm")
    ) {
      return "";
    } else if (
      moment(twoWeeks).format("YYYY-MM-DD HH:mm") >=
        moment(outputDate).format("YYYY-MM-DD HH:mm") &&
      moment(outputDate).format("YYYY-MM-DD HH:mm") >
        moment(oneWeeks).format("YYYY-MM-DD HH:mm")
    ) {
      return "cell-color-two-weeks";
    } else if (
      moment(outputDate).format("YYYY-MM-DD HH:mm") <=
      moment(oneWeeks).format("YYYY-MM-DD HH:mm")
    ) {
      return "cell-color-one-weeks";
    }
  };

  return (
    <tr key={id}>
      <td>{rowNumber}</td>
      <ManagementRowNameTooltip name={name} />
      <ManagementRowRemarksTooltip summary={summary} />
      <ManagementRowCopyOnClick publicIp={public_ip || "unknown"} />
      <td>{VPS_status || "unknown"}</td>
      <td>{mt4Count || 0}</td>
      <td>{customFormatDatetimeWithLocale(createdAt, locale, true)}</td>
      <td
        className={`${checkExpiryDate(
          customFormatDatetimeWithLocale(expiryDate, locale, true),
        )}`}
      >
        {customFormatDatetimeWithLocale(expiryDate, locale, true)}
      </td>
      <ManagementRowActionsDropdown
        vpsType={vpsType}
        instanceId={instance}
        setChangeVpsStatus={setChangeVpsStatus}
        updateUpdateDetailPayLoad={updateUpdateDetailPayLoad}
      />
      <ManagementRowSubscriptionButton
        email={email}
        englishFullName={englishFullName}
        vpsId={id}
        vpsType={vpsType}
      />
      <ManagementRowInfoTooltip
        instanceTypeName={vpsType?.typeName || "unknown"}
        instanceId={instance || "unknown"}
        vpsId={id}
      />
    </tr>
  );
};

export default ManagementRow;
