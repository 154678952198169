import React from "react";
import "../../../assets/css/loader.css";

const CenterLoader = () => {
  return (
    <>
      {/* {props.button ? ( */}
      <div className="loader-container">
        <div className="loader-logo">
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        </div>
      </div>
      {/* ): (
                <div className="spinner-border spinner-border-sm ml-1 text-white">
                <span className="sr-only"></span>
            </div>
            )} */}
    </>
  );
};

export default CenterLoader;
