import { combineReducers } from "redux";
import userDataReducer from "./userDataReducer";
import ticketReducer from "./ticketReducer";
import VPSmachineReducer from "./VPSmachineReducer";
import announcementsReducer from "./announcementsReducer";
import classReducer from "./classReducer";
import attendenceReducer from "./attendenceReducer";
import activityReducer from "./activityReducer";
import pointReducer from "./pointReducer";
import giftReducer from "./giftReducer";
import faqReducer from "./faqReducer";
import registerReducer from "./registerReducer";
import expertAdvisorReducer from "./expertAdvisorReducer";
import indicatorsReducer from "./indicatorsReducer";
import libararyReducers from "./libararyReducers";
import scriptReducers from "./scriptReducers";
import utilityReducers from "./utilityReducers";
import accountReducer from "./accountReducer";
import signalReducer from "./signalReducer";
import mediaUploadReducer from "./mediaUploadReducer";
import vpsReducer from "./vpsReducer";

export default combineReducers({
  userDataReducer,
  ticketReducer,
  VPSmachineReducer,
  announcementsReducer,
  classReducer,
  attendenceReducer,
  activityReducer,
  pointReducer,
  giftReducer,
  faqReducer,
  registerReducer,
  expertAdvisorReducer,
  indicatorsReducer,
  libararyReducers,
  scriptReducers,
  utilityReducers,
  accountReducer,
  signalReducer,
  mediaUploadReducer,
  vpsReducer,
});
