import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { dispatch } from "../redux";
import { changePassword } from "../redux/action";
import { Store } from "../redux/Actions";
import { ChangePasswordSchema } from "../util/validationSchema";
import MyPasswordInputWrapper from "../Components/MyPasswordInput";

const ChangePassword = () => {
  const onSaveLoading = useSelector(
    (state: Store) => state.userDataReducer.onSaveLoading,
  );
  const errMsg = useSelector(
    (state: Store) => state.userDataReducer.errorMessage,
  );
  const [buttonClick, setButtonClick] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values) => {
      const userData = {
        oldPassword: values.oldPassword,
        newPassword: values.password,
      };
      dispatch(changePassword(true, userData));
      setButtonClick(true);
    },
  });
  const { errors, touched, values, resetForm } = formik;

  useEffect(() => {
    if (
      errMsg &&
      errMsg !== "Incorrect old password" &&
      !onSaveLoading &&
      buttonClick
    ) {
      resetForm({ values: { ...values, password: "", confirmPassword: "" } });
      setButtonClick(false);
      return;
    }

    if (!onSaveLoading && buttonClick) {
      resetForm({
        values: { oldPassword: "", password: "", confirmPassword: "" },
      });
      setButtonClick(false);
    }
  }, [onSaveLoading, errMsg, buttonClick]);

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="changePassword"
                  defaultMessage="Change Password"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div className="card">
            <form
              className="form-horizontal"
              onSubmit={(e: any) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="card-body">
                <div className="mt-3">
                  <div className="form-group row">
                    <label className="control-label text-md-right col-md-3">
                      <FormattedMessage
                        id="oldPassword"
                        defaultMessage="Old Password"
                      />
                    </label>
                    <MyPasswordInputWrapper
                      value={values.oldPassword}
                      className="mb-3"
                      wrapperStyle={{
                        width: "16rem",
                      }}
                      name="oldPassword"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errors={errors}
                      touched={touched}
                      inputDefaultMessage={"Old Password"}
                      inputDefaultMessageId={"oldPassword"}
                    />
                  </div>
                  <div className="form-group row">
                    <label className="control-label text-md-right col-md-3">
                      <FormattedMessage
                        id="newPassword"
                        defaultMessage="New Password"
                      />
                    </label>
                    <MyPasswordInputWrapper
                      value={values.password}
                      className="mb-3"
                      wrapperStyle={{
                        width: "16rem",
                      }}
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errors={errors}
                      touched={touched}
                      inputDefaultMessage={"New Password"}
                      inputDefaultMessageId={"newPassword"}
                    />
                  </div>
                  <div className="form-group row">
                    <label className="control-label text-md-right col-md-3">
                      <FormattedMessage
                        id="reTypePassword"
                        defaultMessage="Retype New Password"
                      />
                    </label>
                    <MyPasswordInputWrapper
                      value={values.confirmPassword}
                      className="mb-3"
                      wrapperStyle={{
                        width: "16rem",
                      }}
                      name="confirmPassword"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errors={errors}
                      touched={touched}
                      inputDefaultMessage={"Retype New Password"}
                      inputDefaultMessageId={"reTypePassword"}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer bg-light text-right">
                <button
                  type="button"
                  className="btn btn-secondary clear-form mr-2"
                  onClick={() => {
                    resetForm();
                    navigate("/home");
                  }}
                  disabled={onSaveLoading}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => formik.handleSubmit()}
                  disabled={onSaveLoading}
                  style={{ width: "80px" }}
                >
                  {!onSaveLoading ? (
                    <FormattedMessage
                      id="page.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <span
                      className="login-button--loading"
                      style={{ position: "relative", left: -13 }}
                    ></span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ChangePassword;
